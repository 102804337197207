import React, { useEffect, useState } from "react";
import CustomTabs from "../../common/Tabs";
import {
  Box,
  Button,
  CardHeader,
  Stack,
  Typography,
  Card,
} from "@mui/material";
import { useStyles } from "./RequirementModal.theme";
import { useDispatch, useSelector } from "react-redux";
import { getProjectThunk } from "../../../store/slices/project/project.slice";
import {
  acceptLeadThunk,
  rejectLeadThunk,
  tabChange,
} from "../../../store/slices/lead/lead.slice";
import ConfirmationModal from "../ConfirmationModal";
import useModal from "../../../hooks/useModal";
import Element from "../../Form/Element";
import { inputType } from "../../../utils/enum";
const labels = ["Requirements", "Extra Information"];

const RequirementModal = ({ projectId, leadId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isModalOpen, showModal, hideModal } = useModal();
  const [rejectionReason, setRejectionReason] = useState(null);

  useEffect(() => {
    dispatch(getProjectThunk({ id: projectId }));
  }, []);

  const projectData = useSelector(({ project }) => project.selectedProject);

  function handleSubmit() {
    if (!rejectionReason) return;
    dispatch(
      rejectLeadThunk({
        leadId,
        action: "rejected",
        rejectionReason,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(tabChange(2));
      });
    hideModal();
  }

  const handleAccept = () => {
    dispatch(acceptLeadThunk({ leadId }))
      .unwrap()
      .then(() => {
        dispatch(tabChange(1));
      });
  };

  return (
    <div>
      <Stack direction="row" spacing={4} p={3}>
        <Box sx={{ width: "50%" }}>
          <Box>
            <Typography variant="h6" sx={{ color: "#00ab55" }} mb={1}>
              {projectData?.project_name}
            </Typography>
            <Typography>{projectData?.description}</Typography>
            <Typography variant="h6" mt={2} mb={1}>
              Service
            </Typography>
            <Stack direction="row" spacing={2} mt={2}>
              <Card sx={{ width: "50%" }}>
                <CardHeader title="Timeline" pt={2} />
                <Typography variant="body1" sx={{ p: 2, pl: 3 }}>
                  {projectData?.expected_timeline}
                </Typography>
              </Card>
              <Card sx={{ width: "50%" }}>
                <CardHeader title="Budget" />
                <Typography variant="body1" sx={{ p: 2 }}>
                  {projectData?.expected_budget}
                </Typography>
              </Card>
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <Card sx={{ width: "50%" }}>
                <CardHeader title="Industry" pt={2} />
                <Typography variant="body1" sx={{ p: 2, pl: 3 }}>
                  {projectData?.industry}
                </Typography>
              </Card>
              <Card sx={{ width: "50%" }}>
                <CardHeader title="SPOC" />
                <Typography variant="body1" sx={{ p: 2 }}>
                  {projectData?.client_spoc?.first_name +
                    " " +
                    projectData?.client_spoc?.last_name}
                </Typography>
              </Card>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box
              className={classes.scrollbar}
              sx={{ maxHeight: "380px", overflowY: "auto" }}
            >
              <CustomTabs
                labels={labels}
                components={[
                  <Typography>{projectData?.reference}</Typography>,
                  <Typography>{projectData?.extra_information}</Typography>,
                ]}
              />
            </Box>
            <Box
              mt={2}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
              }}
            >
              <Stack direction="row" spacing={3}>
                <Button onClick={() => showModal()}>Decline</Button>
                <Button variant="contained" onClick={() => handleAccept()}>
                  Accept
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Stack>
      <ConfirmationModal
        isOpen={isModalOpen}
        successCallback={handleSubmit}
        closeModal={hideModal}
        title="Are you sure?"
        content={
          <div>
            <p>Do you really want to Reject the proposal?</p>{" "}
            <p>This process cannot be undone.</p>
            <Element
              eletype={inputType.textarea}
              inputProps={{
                name: "rejection_reason",
                placeholder: "Enter Rejection Reason",
                onChange: (e) => setRejectionReason(e.target.value),
              }}
              value={rejectionReason}
            />
          </div>
        }
        buttonSaveText={"Reject"}
      />
    </div>
  );
};

export default RequirementModal;
