import userReducer from "./slices/user/user.slice";
import projectReducer from "./slices/project/project.slice";
import initReducer from "./slices/init/init.slice";
import authReducer from "./slices/auth/auth.slice";
import toastReducer from "./slices/toast/toast.slice";
import { combineReducers } from "@reduxjs/toolkit";
import portfolioReducer from "./slices/portfolio/portfolio.slice";
import leadsReducer from "./slices/lead/lead.slice";
import dashboardReducer from "./slices/dashboard/dashboard.slice";
import supportReducer from "./slices/support/support.slice";
import transactionsReducer from "./slices/wallet/wallet.slice";
import vendorServicesReducer from "./slices/vendorServices/vendorservice.slice";
import vendorReducer from "./slices/vendor/vendor.slice";
import projectleadsReducer from "./slices/leadsProject/projectleads.slice";
import raReducer from "./slices/ra/ra.slice";
import uploadportfolioReducer from './slices/uploadportfolio/uploadportfolio.slice'
import expertiseReducer from './slices/expertisebreakup/expertisebreakup.slice'
import onboardReducer from './slices/onboard/onboard.slice'

const rootReducer = combineReducers({
  user: userReducer,
  projectleads: projectleadsReducer,
  project: projectReducer,
  init: initReducer,
  auth: authReducer,
  toast: toastReducer,
  portfolio: portfolioReducer,
  leads: leadsReducer,
  dashboard: dashboardReducer,
  support: supportReducer,
  wallet: transactionsReducer,
  vendorservices: vendorServicesReducer,
  vendor: vendorReducer,
  ra: raReducer,
  uploadportfolio: uploadportfolioReducer,
  expertise: expertiseReducer,
  onBoard: onboardReducer,
});
export default rootReducer;
