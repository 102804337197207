import { Box } from "@mui/material";
import YouTube from "react-youtube";

const ProfileVideoIntro = () => {
  const videoId = "48kNVYs2CB4"; // Replace with your desired YouTube video ID

  const opts = {
    height: "340",
    width: "500",
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <>
      <Box
        border={1}
        borderRadius={2}
        width={"100%"}
        zIndex={35}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"black"}
        mt={1}
      >
        {/* <h1>My YouTube Video</h1> */}
        <YouTube videoId={videoId} opts={opts} onReady={onReady} />
      </Box>
    </>
  );
};
export default ProfileVideoIntro;
