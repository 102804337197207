export const ACCESS_TOKEN = "562hskcnsh1";
export const ORG_ID = "8374myusn23";

export const DASHBOARD_URL = "/dashboard/app";

export const routesForBreadcrumbs = [
  {
    path: "/dashboard/app",
    breadcrumb: "Dashboard",
  },
  {
    path: "/dashboard/projects",
    breadcrumb: "All Projects",
  },
  {
    path: "/dashboard/projects/:id",
    breadcrumb: "Project Detail",
  },
  {
    path: "/dashboard/user",
    breadcrumb: "Users",
  },
  {
    path: "/dashboard/user/createUser",
    breadcrumb: "Create User",
  },
  {
    path: "/dashboard/clients",
    breadcrumb: "Clients",
  },
  {
    path: "/dashboard/clients/:id",
    breadcrumb: "Project Details",
  },
  {
    path: "/dashboard/projects/:id/tickets/:id",
    breadcrumb: "Chat Thread",
  },
  {
    path: "/dashboard/contact_us",
    breadcrumb: "Support ",
  },
  {
    path: "/dashboard/contact_us/tickets/:id",
    breadcrumb: " Chat Thread",
  },
];

export const routesMapper = [
  "/dashboard/app",
  "/dashboard/projects",
  "/dashboard/projects/:id",
  "/dashboard/user/createUser",
  "/dashboard/user",
  "/dashboard/clients/",
  "/dashboard/clients/:id",
  "/dashboard/projects/:id/tickets/:id",
  "/dashboard/contact_us",
  "/dashboard/contact_us/tickets/:id",
];

export const overviewPath = "overview";
export const proposalPath = "proposal";
export const tablePath = "table";
export const scopePath = "scope";
export const supportPath = "tickets";
export const milestonePath = "milestone";
export const basicInfoPath = "basic";
export const cvPath = "cv";

export const ratingLabels = {
  0.5: "0.5",
  1: "1",
  1.5: "1.5",
  2: "2",
  2.5: "2.5",
  3: "3",
  3.5: "3.5",
  4: "4",
  4.5: "4.5",
  5: "5",
};

// export const overviewPath = "overview";
// export const proposalPath = "proposal";
// export const tablePath = "table";
// export const scopePath = "scope";
export const clientSupport = "client-support";
export const vendorSupport = "vendor-support";
export const feedbackPath = "feedback";
export const commercialsPath = "commercials";

//routes link
// export const basicInfoPath = "basic";
export const addressesPath = "address";
export const bankDetailsPath = "bank-details";
export const verificationPath = "verification";
export const servicePath = "service";
export const services = "vendor-selection";
export const portfolioPath = "portfolio";
export const uploadPortfolioPath = "upload-profile";
export const poc = "poc";
export const expertiseBreakup = "expertise-breakup";
export const uploadCOQPath = "upload-coq";
export const ra = "ra";
// export const cvPath = "cv";
export const saasChannelPartner = "saas-channel-partner";
export const certification = "certification";
export const testimonials = "testimonials";
export const socialMediaRating = "social-media-rating";
export const founderInfo = "founderInfo";
export const techStackForte = "tech-stack-forte";
export const benchStrength = "bench-strength";
export const platformReview = "platform-review";
