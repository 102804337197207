import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { projectDetailsPage } from "../../../utils/fakeData/tabs.data";
import { getSectionMatrix } from "../../../utils/timeline.utils";
import {
  addCommentHandler,
  addFilesHandler,
  fetchAllCommentsForTimelineHandler,
  fetchAllFilesForTimelineHandler,
  fetchProjectCharterByProjectIdHandler,
  fetchProjectTimelineHandler,
  getProjectHandler,
  updateProjectCharterHandler,
  updateProjectTimelineHandler,
  uploadProjectAttachmentHandler,
  getReportedIssue,
  createReportedIssue,
  fetchAllProjectsHandler,
  getAllProposalsHandler,
  fetchAllSupportDataByTicketIdHanldler,
  createSupportTicketCommentHandler,
  fetchAllCollectionsByProjectHandler,
} from "./project.thunk";

var timeline = {
  sections: [],
  columnsIds: [],
};

const INIT_STATE = {
  selectedTab: 0,
  tabs: projectDetailsPage,
  projects: [],
  selectedProject: {
    overview: {},
    proposals: [],
    tickets: [],
  },
  tickets: [],
  leads: [],
  selectedCharter: null,
  selectedTimeline: {
    timeline: timeline,
    files: [],
    comments: [],
  },
  gantt: [],
  isLoading: false,
  comments: {
    comments_data: [],
  },
  collections: [],
  isProjectTablePublished: false,
};

export const fetchAllProjectsThunk = createAsyncThunk(
  "fetchAllProjects",
  fetchAllProjectsHandler
);

export const getAllProposalsThunk = createAsyncThunk(
  "getAllProposals",
  getAllProposalsHandler
);
export const fetchAllCollectionsByProjectThunk = createAsyncThunk(
  "fetchAllCollectionsByProject",
  fetchAllCollectionsByProjectHandler
);

export const getProjectThunk = createAsyncThunk(
  "getProject",
  getProjectHandler
);

export const uploadProjectAttachmentThunk = createAsyncThunk(
  "uploadProjectAttachment",
  uploadProjectAttachmentHandler
);

export const fetchProjectCharterByProjectIdThunk = createAsyncThunk(
  "fetchProjectCharterByProjectId",
  fetchProjectCharterByProjectIdHandler
);

export const fetchProjectTimelineThunk = createAsyncThunk(
  "fetchProjectTimeline",
  fetchProjectTimelineHandler
);

export const fetchAllCommentsForTimelineThunk = createAsyncThunk(
  "fetchAllCommentsForTimeline",
  fetchAllCommentsForTimelineHandler
);

export const fetchAllFilesForTimelineThunk = createAsyncThunk(
  "fetchAllFilesForTimeline",
  fetchAllFilesForTimelineHandler
);

export const createSupportTicketCommentThunk = createAsyncThunk(
  "createSupportTicketComment",
  createSupportTicketCommentHandler
);

export const addCommentThunk = createAsyncThunk(
  "addComment",
  addCommentHandler
);

export const addFilesThunk = createAsyncThunk("addFiles", addFilesHandler);

export const updateProjectTimelineThunk = createAsyncThunk(
  "updateProjectTimeline",
  updateProjectTimelineHandler
);

export const updateProjectCharterThunk = createAsyncThunk(
  "updateProjectCharter",
  updateProjectCharterHandler
);
export const getReportedIssueThunk = createAsyncThunk(
  "getReportedIssue",
  getReportedIssue
);

export const addReportIssueThunk = createAsyncThunk(
  "createReportIssue",
  createReportedIssue
);

export const fetchAllTicketsDataByTicketIdThunk = createAsyncThunk(
  "fetchAllTicketsDataByTicketId",
  fetchAllSupportDataByTicketIdHanldler
);

const projectSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    tabChange: (state, action) => {
      state.selectedTab = action.payload;
      return state;
    },
    deleteProject: (state, action) => {
      var id = action.payload;
      var newProjects = state.projects.filter((project) => project.id !== id);
      state.projects = newProjects;
      return state;
    },
    addMessageToProjectTimeline: (state, action) => {
      state.selectedTimeline.comments.unshift({
        comment: action.payload.comment,
        user_name: action.payload.fullName,
        created_at: DateTime.now().toUTC().toString(),
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProjectsThunk.fulfilled, (state, action) => {
         
        state.projects = action.payload;
        return state;
      })
      .addCase(fetchAllProjectsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllProjectsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getProjectThunk.fulfilled, (state, action) => {
        state.selectedProject = action.payload;

        return state;
      })
      .addCase(getProjectThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(getProjectThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(uploadProjectAttachmentThunk.fulfilled, (state, action) => {
        state.selectedProject.attachments = [
          ...state.selectedProject.attachments,
          ...action.payload,
        ];
        return state;
      })
      .addCase(uploadProjectAttachmentThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllCollectionsByProjectThunk.fulfilled, (state, action) => {
        state.collections = action.payload;
        return state;
      })
      .addCase(uploadProjectAttachmentThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        fetchProjectCharterByProjectIdThunk.fulfilled,
        (state, action) => {
          state.selectedCharter = action.payload;
          return state;
        }
      )
      .addCase(fetchProjectCharterByProjectIdThunk.rejected, (state) => {
        state.selectedCharter = null;
      })
      .addCase(fetchProjectCharterByProjectIdThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })

      .addCase(fetchAllCommentsForTimelineThunk.fulfilled, (state, action) => {
        state.selectedTimeline.comments = action.payload;
        return state;
      })
      .addCase(createSupportTicketCommentThunk.fulfilled, (state, action) => {
        state.comments.comments_data.push(action.payload.comments);
        return state;
      })
      .addCase(fetchAllCommentsForTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllCommentsForTimelineThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getAllProposalsThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedProject.proposals = action.payload;
        }
        return state;
      })
      .addCase(getAllProposalsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProposalsThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllFilesForTimelineThunk.fulfilled, (state, action) => {
        state.selectedTimeline.files = action.payload;
        return state;
      })
      .addCase(fetchAllFilesForTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllFilesForTimelineThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(addCommentThunk.fulfilled, (state, action) => {
        state.selectedTimeline.comments.unshift(action.payload);
        return state;
      })
      .addCase(addCommentThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(addCommentThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(addFilesThunk.fulfilled, (state, action) => {
        state.selectedTimeline.files = [
          ...state.selectedTimeline.files,
          ...action.payload,
        ];
        return state;
      })
      .addCase(addFilesThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(addFilesThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchProjectTimelineThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedTimeline.timeline = getSectionMatrix(action.payload);
        state.timelineRef = action.payload;
        state.isProjectTablePublished = true;
        return state;
      })
      .addCase(fetchProjectTimelineThunk.rejected, (state) => {
        state.selectedTimeline.timeline = null;
        state.isProjectTablePublished = false;
        return state;
      })
      .addCase(updateProjectTimelineThunk.fulfilled, (state, action) => {
        state.selectedTimeline.timeline = action.payload;
        return state;
      })
      .addCase(updateProjectTimelineThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectTimelineThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(updateProjectCharterThunk.fulfilled, (state, action) => {
        state.selectedCharter = action.payload;
        return state;
      })
      .addCase(getReportedIssueThunk.fulfilled, (state, action) => {
        state.selectedProject.tickets = action.payload;
        return state;
      })
      .addCase(getReportedIssueThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(getReportedIssueThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(addReportIssueThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReportIssueThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedProject.tickets.push(action.payload);
        return state;
      })
      .addCase(addReportIssueThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateProjectCharterThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updateProjectCharterThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(
        fetchAllTicketsDataByTicketIdThunk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.comments = action.payload;
          return state;
        }
      )
      .addCase(fetchAllTicketsDataByTicketIdThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllTicketsDataByTicketIdThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      });
  },
});

export const { tabChange, deleteProject, addMessageToProjectTimeline } =
  projectSlice.actions;

export default projectSlice.reducer;
