import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { Box, Card, Typography, Stack, Avatar } from "@mui/material";
import { css } from "@emotion/css";
import { capitalCase } from "../../../utils/change-case";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function getInitials(client_name) {
  return capitalCase(client_name?.substring(0, 2));
}

const ClientCard = ({ data }) => {
  const [isreadmoreToggle, setisreadmoreToggle] = useState(false);
  const navigate = useNavigate();

  function fncReadData(e, isRead) {
    e.stopPropagation();
    setisreadmoreToggle(isRead);
  }
  return (
    <Card
      className={css`
        padding: 24px;
        border-radius: 25px;
        cursor: pointer;
      `}
      onClick={() =>
        navigate("/dashboard/projects/" + data.project_id + "/overview")
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              backgroundColor:
                data?.project_status === "active" ? "#CFECD9" : "#f3f6f8",

              color: data?.project_status === "active" ? "#343434" : "#637281",
              fontSize: ".8rem",
              fontWeight: "700",
            }}
          >
            {getInitials(data?.project_name)}
          </Avatar>
          <Box
            className={css`
              margin-left: 12px;
            `}
          >
            <Typography
              className={css`
                font-weight: 600;
                font-size: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 215px;
              `}
              variant="body2"
              noWrap
            >
              {capitalCase(data?.project_name)}
            </Typography>
            <Typography
              className={css`
                font-size: 14px;
              `}
              sx={{ color: "text.secondary", maxWidth: 160 }}
              noWrap
            >
              {capitalCase(data?.client_name)}
            </Typography>
          </Box>
        </Box>
      </Box>
      {isreadmoreToggle ? (
        <Box sx={{ minHeight: "130px" }}>
          <Typography
            sx={{
              color: "#343434",
              marginTop: "20px",
              fontWeight: "500",
              opacity: 0.75,
            }}
          >
            Description
          </Typography>
          <Typography
            className={css`
              margin: 5px 0px;
              min-height: 70px;
              font-size: 15px;
            `}
            variant="body1"
            sx={{ maxWidth: 550 }}
          >
            {capitalCase(data?.project_description)}
          </Typography>
          <Typography
            sx={{
              color: "#2073F2",
              fontWeight: "500",
            }}
            onClick={(e) => fncReadData(e, false)}
          >
            Read less...
          </Typography>
        </Box>
      ) : (
        <Box sx={{ minHeight: "130px" }}>
          <Typography
            sx={{
              color: "#343434",
              marginTop: "20px",
              fontWeight: "500",
              opacity: 0.75,
            }}
          >
            Description
          </Typography>
          <Typography
            className={css`
              margin: 5px 0px;
              min-height: 70px;
              font-size: 15px;
            `}
            variant="body1"
            sx={{ maxWidth: 550 }}
          >
            {data?.project_description?.length > 50
              ? capitalCase(data?.project_description.substring(0, 120))
              : capitalCase(data?.project_description)}
          </Typography>

          {data?.project_description.length > 120 ? (
            <Typography
              sx={{
                color: "#2073F2",
                fontWeight: "500",
              }}
              onClick={(e) => fncReadData(e, true)}
            >
              Read more...
            </Typography>
          ) : (
            ""
          )}
        </Box>
      )}
      <Stack
        justifyContent="space-between"
        direction="row"
        sx={{
          marginTop: "1.25rem",
          paddingTop: "0.5rem",
          borderTop: "1px solid #e7eced",
        }}
      >
        <Typography variant="body2">
          <Box
            component="div"
            sx={{
              fontSize: "15px",
              color: "text.secondary",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AccessTimeIcon sx={{ fontSize: "15px", marginRight: "4px" }} />
            <Box>Created Date</Box>
          </Box>
          <Box component="div" sx={{ textAlign: "center" }}>
            {DateTime.fromISO(data?.project_created_at).toFormat("DD")}
          </Box>
        </Typography>
        <Typography variant="body2" noWrap>
          <Box
            component="div"
            sx={{
              fontSize: "15px",

              color: "text.secondary",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CalendarMonthIcon sx={{ fontSize: "1rem" }} />
            <Box>Due Date</Box>
          </Box>
          <Box component="div" sx={{ textAlign: "center" }}>
            {data.project_expected_closing_date
              ? DateTime.fromISO(data.project_expected_closing_date).toFormat(
                  "DD"
                )
              : "-"}
          </Box>
        </Typography>
      </Stack>
    </Card>
  );
};

export default ClientCard;
