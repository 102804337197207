import React, { useEffect, useMemo, useState } from "react";

import Table from "./Table";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import Drawer from "./Drawer";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../../hooks/useUser";
import { useParams } from "react-router-dom";
import { Badge } from "@mui/material";
// import {
//   newProjectTableClientMessageEvent,
//   newProjectTableServerMessageEvent,
//   newUsersEvent,
//   socket,
// } from "../../../utils/socket";
// import { addMessageToProjectTimeline } from "../../../store/slices/project/project.slice";

function ProjectTable() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [timelineState, setTimelineState] = useState(null);

  // const dispatch = useDispatch();

  // const { selectedProject } = useSelector(({ project }) => project);
  // const user = useUser();
  // const params = useParams();

  // useEffect(() => {
  //   if (selectedProject?.slug) {
  //     socket.emit(newUsersEvent, {
  //       projectId: params.id,
  //       roomName: selectedProject?.slug,
  //       first_name: user.fullName,
  //     });
  //   }
  // }, [selectedProject]);

  // useEffect(() => {
  //   socket.on(newProjectTableServerMessageEvent, (data) => {
  //     dispatch(addMessageToProjectTimeline(data));
  //   });
  // }, []);

  // function emitNewMessage(data) {
  //   socket.emit(newProjectTableClientMessageEvent, {
  //     ...data,
  //     roomName: selectedProject?.slug,
  //     fullName: user.fullName,
  //   });
  // }

  const { timeline } = useSelector(({ project }) => project.selectedTimeline);

  const mainSectionData = useMemo(() => {
    const newState = _.cloneDeep(timeline.sections);
    const columnId = timeline.columnsIds[0];
    newState.forEach((currentSection) => {
      const rows = currentSection.rows;
      Object.keys(rows).forEach((rowId) => {
        const row = rows[rowId];
        const count = row[timeline.columnsIds[0]].comments_count;
        row[columnId].addRowExtra = {
          icon: (
            <>
              <Badge
                badgeContent={count}
                color="primary"
                sx={{ marginRight: "1rem" }}
              >
                <AddCommentOutlinedIcon
                  sx={{ color: "rgb(157 167 177 / 47%)" }}
                />
              </Badge>
            </>
          ),
          onClick: (data) => {
            setTimelineState(data);
            setShowDrawer(true);
          },
        };
      });
    });
    return newState;
  }, [timeline]);

  return (
    <div>
      <Table {...timeline} sections={mainSectionData} />
      {timelineState && (
        <Drawer
          showDrawer={showDrawer}
          setShowDrawer={() => {
            setShowDrawer(false);
            setTimelineState(null);
          }}
          sectionIdx={timelineState?.sectionIdx}
          rowIdx={timelineState?.rowIdx}
          // emitNewMessage={emitNewMessage}
        />
      )}
    </div>
  );
}

export default ProjectTable;
