import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import usersData from "../../../utils/fakeData/userTable.data";
import usersRole from "../../../utils/fakeData/userRole.data";
import {
  createBusinessProfile,
  getAddresses,
  getBusinessProfile,
  updateBusinessProfile,
  updateUserProfileHandler,
  uploadProfileHandler,
} from "./user.thunk";
import { storeOrgId, removeOrgId } from "../../../utils/global/auth.global";
import { formatUserResponse } from "../../../utils/global/user.global";

const INIT_STATE = {
  userData: {
    profile: {},
    business: {},
    addresses: [],
  },
  error: null,
  clientUsers: usersData,
  userRoles: usersRole,
  selectedTab: 0,
  isLoading: false,
  isBusinessProfileExist: false,
};

export const getBusinessProfileAsyncThunk = createAsyncThunk(
  "getBusinessProfile",
  getBusinessProfile
);

export const getAddressesAsyncThunk = createAsyncThunk(
  "getAddresses",
  getAddresses
);

export const uploadProfileThunk = createAsyncThunk(
  "uploadProfile",
  uploadProfileHandler
);

export const uploadProfileInfoThunk = createAsyncThunk(
  "uploadProfileInfo",
  updateUserProfileHandler
);

export const createBusinessProfileThunk = createAsyncThunk(
  "createBusinessProfile",
  createBusinessProfile
);

export const updateBusinessProfileThunk = createAsyncThunk(
  "updateBusinessProfile",
  updateBusinessProfile
);

const userSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    deleteUser: (state, action) => {
      var id = action.payload;
      var users = state.clientUsers.filter((project) => project.id !== id);
      state.clientUsers = users;
      return state;
    },
    setUserProfileDetails: (state, action) => {
      const { profiles, ...userData } = action.payload;
      state.userData.profile = userData;
      if (profiles?.length > 0) {
        state.isBusinessProfileExist = true;
        const profile = profiles[profiles.length - 1];
        storeOrgId(profile.org_id);
      } else {
        removeOrgId();
      }
      return state;
    },
    tabChange: (state, action) => {
      state.selectedTab = action.payload;
    },
    changeUserLoginCount: (state) => {
      state.userData.profile.new_user = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessProfileAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getBusinessProfileAsyncThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData.business = action.payload;
        return state;
      })
      .addCase(getBusinessProfileAsyncThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(getAddressesAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(getAddressesAsyncThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData.addresses = action.payload;
        return state;
      })
      .addCase(getAddressesAsyncThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(uploadProfileThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(uploadProfileThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData.profile.imageUrl = action.payload?.url;
        return state;
      })
      .addCase(uploadProfileThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadProfileInfoThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadProfileInfoThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData.profile = formatUserResponse(action.payload);
      })
      .addCase(uploadProfileInfoThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createBusinessProfileThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBusinessProfileThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData.business = action.payload;
        state.isBusinessProfileExist = true;
        return state;
      })
      .addCase(createBusinessProfileThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateBusinessProfileThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBusinessProfileThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData.business[0] = action.payload;
        return state;
      })
      .addCase(updateBusinessProfileThunk.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  deleteUser,
  setUserProfileDetails,
  tabChange,
  setApplicationForProject,
  changeUserLoginCount,
} = userSlice.actions;

export default userSlice.reducer;










