import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPersonalInfoHandler, getServicesListHandler, uploadPersonalInfoHandler, postServicesHandler, getSelectedServicesListHandler, updateServicesHandler } from "./onboard.thunk";


const initialState = {
  data: {
    personalInfo: {
      bussinessName: null,
      website: null,
      totalEmployees: null,
      foundingYear: null,
      linkedinUrl: null,
      contact: null,
      description: null,
      logo: null,
      salesEmail: null,
      salesNumber: null,
      projectSize: null,
      hourlyRate: null,
    },
    addressInfo: {
      headquarter: {
        country: null,
        state: null,
        city: null,
        zip: null,
        address: null,
        phoneNumber: null,
        noOfEmployees: null,
      },
      additional: [],
    },
    servicesList: [],
    selectedServicesList: []
  },
  loading: false,
  error: {
    personalInfo: null,
    addressInfo: null,
    servicesList: null,
    selectedServicesList: null,
  },
}

// export const uploadPersonalInfo = createAsyncThunk(
//     'postPersonalInfo',
//     uploadPersonalInfoHandler
// )

// export const getPersonalInfo = createAsyncThunk(
//     'getPersonalInfo',
//     getPersonalInfoHandler
// )


export const getServicesListThunk = createAsyncThunk(
  'getServicesList',
  getServicesListHandler
)
export const postServicesThunk = createAsyncThunk(
  'postServices',
  postServicesHandler
)
export const updateServicesThunk = createAsyncThunk(
  'updateServices',
  updateServicesHandler
)

export const getSelectedServicesListThunk = createAsyncThunk(
  'getSelectedServicesList',
  getSelectedServicesListHandler
)

//SLICE
const onBoardSlice = createSlice({
  name: "onBoard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Business Entity List
    // builder.addCase(getPersonalInfo.pending, (state) => {
    //   state.loading = true;
    //   state.error = {
    //     ...state.error,
    //     personalInfo: null,
    //   };
    // });
    // builder.addCase(getPersonalInfo.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.data.personalInfo = action.payload;
    // });
    // builder.addCase(getPersonalInfo.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = {
    //     ...state.error,
    //     personalInfo: action.payload,
    //   };
    // });
    // builder.addCase(uploadPersonalInfo.fulfilled, (state, action) => {
    //     state.data.personalInfo = action.payload
    // })

    // ServiceList data
    builder.addCase(getServicesListThunk.pending, (state, action) => {
      state.loading = true;
      state.error = {
        ...state.error,
        servicesList: null,
      }
    })
    builder.addCase(getServicesListThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.servicesList = action.payload ;
    })
    builder.addCase(getServicesListThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = {
        ...state.error,
        servicesList: action.payload ,
      }
    })

    // selectedServiceList data
    builder.addCase(getSelectedServicesListThunk.pending, (state, action) => {
      state.loading = true;
      state.error = {
        ...state.error,
        selectedServicesList: null,
      }
    })
    builder.addCase(getSelectedServicesListThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.selectedServicesList = action.payload ;
    })
    builder.addCase(getSelectedServicesListThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = {
        ...state.error,
        selectedServicesList: action.payload ,
      }
    })

    builder.addCase(postServicesThunk.fulfilled, (state, action) => {
      // state.data.servicesList = action.payload as any;
    })
  },
});

export default onBoardSlice.reducer;
