import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  acceptLeadHandler,
  getAcceptedLeadHandler,
  getProposalsByLeadIdHandler,
  getRejectedLeadHandler,
  getVendorLeadsHandler,
  rejectLeadHandler,
  uploadProposalHandler,
} from "./lead.thunk";

const INIT_STATE = {
  leads: [],
  acceptedLeads: [],
  rejectedLeads: [],
  selectedLead: {
    proposals: [],
  },
  selectedTab: 0,
  tabs: [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "denied", label: "Rejected" },
  ],
};

export const getVendorLeadThunk = createAsyncThunk(
  "getVendorLeads",
  getVendorLeadsHandler
);

export const acceptLeadThunk = createAsyncThunk(
  "acceptLead",
  acceptLeadHandler
);

export const getAcceptedLeadThunk = createAsyncThunk(
  "getAcceptedLead",
  getAcceptedLeadHandler
);

export const uploadProposalThunk = createAsyncThunk(
  "uploadProposal",
  uploadProposalHandler
);

export const getRejectedLeadThunk = createAsyncThunk(
  "getRejectedLead",
  getRejectedLeadHandler
);

export const rejectLeadThunk = createAsyncThunk(
  "rejectLead",
  rejectLeadHandler
);

export const getProposalsByLeadIdThunk = createAsyncThunk(
  "getProposalsByLeadId",
  getProposalsByLeadIdHandler
);

const leadSlice = createSlice({
  name: "lead",
  initialState: INIT_STATE,
  reducers: {
    tabChange: (state, action) => {
      state.selectedTab = action.payload;
      return state;
    },
    selectLead: (state, action) => {
      state.selectedLead = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVendorLeadThunk.fulfilled, (state, action) => {
        state.leads = action.payload;
        return state;
      })
      .addCase(getAcceptedLeadThunk.fulfilled, (state, action) => {
        state.acceptedLeads = action.payload;
        return state;
      })
      .addCase(getRejectedLeadThunk.fulfilled, (state, action) => {
        state.rejectedLeads = action.payload;
        return state;
      })
      .addCase(getProposalsByLeadIdThunk.fulfilled, (state, action) => {
        state.selectedLead = action.payload;
        return state;
      });
  },
});

export const { tabChange, selectLead } = leadSlice.actions;

export default leadSlice.reducer;
