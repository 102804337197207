import { useNavigate, useParams } from "react-router-dom";
import { storeAccessToken } from "../../utils/global/auth.global";
import Loader from "../../components/Loader/Loader";
import { useEffect } from "react";

function CheckOnboardingToken() {


  const { token } = useParams();
  // console.log(token);
  const navigate = useNavigate()

  useEffect(()=>{
    if(token){
      storeAccessToken(token)
    }
    navigate('/dashboard')
  },[token])

  return (
    <div>
      <Loader bg="rgba(255,255,255)" />
    </div>
  );
}
export default CheckOnboardingToken;
