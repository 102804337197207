import React, { useRef, useState } from "react";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Avatar,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
// components
import { green } from "@mui/material/colors";
import Element from "./../../../Form/Element";
import { inputType, otpType } from "../../../../utils/enum";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Address from "../../../Form/Address";
import MobileInput from "../../../MobileInput";
import { uploadProfileInfoThunk, uploadProfileThunk } from "../../../../store/slices/user/user.slice";
import Modal from "./../../../Modal/Modal";
import {
  closeOTPModal,
  resendEmailOTPAsyncThunk,
  sendEmailVerificationOtpThunk,
  validateOTPVerifyOnlyThunk,
} from "../../../../store/slices/auth/auth.slice";
import OTPModal from "../../../Modal/OtpModal";
import { sendMobileVerificationOtpThunk } from "./../../../../store/slices/auth/auth.slice";
import useUser from "../../../../hooks/useUser";

export default function EditPersonalDetails({ handleprofileEditMode }) {
  const dispatch = useDispatch();
  const { showOTPModal, digitsInOTP, mobileSession } = useSelector(
    ({ auth }) => auth
  );

  const { profile } = useSelector(({ user }) => user.userData);
  const [ref, setRef] = useState(profile);
  const [values, setValues] = useState({});

  const handleSubmit = async (values) => {
    if (ref.email !== values.email) {
      setValues(values);
      dispatch(sendEmailVerificationOtpThunk(values.email));
      return;
    }

    if (ref.mobile?.mobile_number !== values.mobile?.mobile_number) {
      setValues(values);
      dispatch(sendMobileVerificationOtpThunk(values.mobile));
      return;
    }

    dispatch(uploadProfileInfoThunk(values))
      .unwrap()
      .then(() => {
        handleprofileEditMode(false);
      });
  };

  function closeModal() {
    dispatch(closeOTPModal());
  }


  const validateOTP = (otp) => {
    let type = otpType.email;
    if (digitsInOTP === 6) type = otpType.mobile;
    dispatch(
      validateOTPVerifyOnlyThunk({
        otp,
        type,
        verifyOnly: true,
        email: values.email,
        country_code: values.mobile?.countryCode,
        mobile_number: values.mobile?.mobile_number,
        ...mobileSession,
      })
    )
      .unwrap()
      .then(() => {
        setRef((ref) => ({
          ...ref,
          email: digitsInOTP === 4 ? values.email : ref.email,
          mobile:
            digitsInOTP === 6
              ? {
                country_code: values.mobile?.countryCode,
                mobile_number: values.mobile?.mobile_number,
              }
              : ref.mobile,
        }));
      });
  };

  const inputRef = useRef();
  const user = useUser();

  function resetOTP() {
    dispatch(resendEmailOTPAsyncThunk());
  }

  function handleFileChange(e) {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_type", "profile");
    dispatch(uploadProfileThunk(formData));
  }


  return (
    <Container>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleFileChange}
        accept="image/png, image/gif, image/jpeg"
      />
      <Box>
        <Grid container spacing={4} mt={5}>
          <Grid item md={4}>
            <Card sx={{ minHeight: "420px" }}>
              <Box sx={{ padding: "30px 0px" }}>

                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{ color: "rgb(99, 115, 129)" }}
                  my={3}
                >
                  <label htmlFor="icon-button-file">
                    <IconButton
                      aria-label="upload picture"
                      component="span"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        my: "1rem",
                      }}
                    >
                    </IconButton>
                  </label>
                </Stack>
                <Avatar
                  alt="Profile-image"
                  src={
                    user.profileUrl
                      ? user.profileUrl
                      : "https://cdn.worldvectorlogo.com/logos/israel-aircraft-unit-2.svg"
                  }
                  sx={{
                    marginTop: "10px",
                    width: 144,
                    height: 144,
                    mx: "auto",
                    borderRadius: "50%",
                    border: "1px dashed rgba(145, 158, 171, 0.32)",
                    backgroundColor: "rgb(244, 246, 248)",
                    cursor: "pointer",
                  }}
                  onClick={() => inputRef.current.click()}
                />
                <Box
                  sx={{
                    width: "60%",
                    textAlign: "center",
                    mx: "auto",
                    pt: "1rem",
                    color: "rgb(99, 115, 129)",
                  }}
                >
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item md={8}>
            <Card>
              <Container>
                <Typography gutterBottom variant="h5" component="div" mt={2}>
                  Personal Details
                </Typography>
                <Formik
                  initialValues={{
                    first_name: profile?.first_name,
                    last_name: profile?.last_name,
                    email: profile?.email,
                    mobile: profile?.mobile,
                    address: {
                      address_line1: profile?.address_line1,
                      address_line2: profile?.address_line2,
                      zip_code: profile?.zip_code,
                      state: profile?.state,
                      country: profile?.country,
                      city: profile?.city,
                    },
                  }}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    touched,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ margin: "20px 0" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-evenly"
                          spacing={2}
                        >
                          <Element
                            value={values.first_name}
                            eletype={inputType.input}
                            label="First Name"
                            inputProps={{
                              name: "first_name",
                              onChange: handleChange,
                              onBlur: handleBlur,
                            }}
                          />
                          <Element
                            value={values.last_name}
                            eletype={inputType.input}
                            label="Last Name"
                            inputProps={{
                              name: "last_name",
                              onChange: handleChange,
                              onBlur: handleBlur,
                            }}
                          />
                        </Stack>
                      </Box>

                      <Box sx={{ margin: "20px 0 10px 0" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-evenly"
                          spacing={2}
                        >
                          <Element
                            value={values.email}
                            eletype={inputType.input}
                            label="Email Address"
                            inputProps={{
                              name: "email",
                              onChange: handleChange,
                              onBlur: handleBlur,
                            }}
                          />

                          <MobileInput
                            name="mobile"
                            label="Mobile Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile}
                          />
                        </Stack>
                      </Box>

                      <Address
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        values={values.address}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          margin: "20px 0",
                        }}
                      >
                        <Stack direction="row" spacing={1}>
                          <Button onClick={() => handleprofileEditMode(false)}>
                            Back
                          </Button>
                          <Button
                            type="submit"
                            color="secondary"
                            startIcon={<SendIcon />}
                            variant="contained"
                            sx={{
                              background: green[500],
                              ":hover": { background: green[300] },
                            }}
                          >
                            Save
                          </Button>
                        </Stack>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Container>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Modal
        isOpen={showOTPModal}
        closeModal={closeModal}
        content={
          <OTPModal
            validateOTP={validateOTP}
            resetOTP={resetOTP}
            digitsInOTP={digitsInOTP}
          />
        }
      />
    </Container>
  );
}
