/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState, useMemo } from "react";
import { Box, Grid, Button, Stack } from "@mui/material";
import { inputType } from "../../utils/enum";
import Element from "../../components/Form/Element";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Typography from "@mui/material/Typography";
import {
  createExpertisebreakupThunk,
  deleteExpertisebreakupThunk,
  fetchExpertisebreakupThunk,
} from "../../store/slices/vendor/vendor.slice";
import { changeStringToNum } from "../../hooks/changeStringToNum";
import { getResouceBifercationList } from "../../utils/server.util";
import { capitalCase } from "change-case";

export default function ExpertiseModal({ props, closeModal, page }) {
  const dispatch = useDispatch();
  const { expertisebreakupList } = useSelector(({ vendor }) => vendor);
  const [resourceBifurcation, setResourceBifurcation] = useState([]);

  function getResouceObjectById(id) {
    return resourceBifurcation.find((item) => item.value === id);
  }

  const handleClick = (data) => {
    const { maximum_value, minimum_value } = data;
    const respWithNumberCount = changeStringToNum(data?.infos, "count");
    const payload = {
      minimum_value,
      maximum_value,
      infos: respWithNumberCount.map((obj) => {
        const resourceObj = getResouceObjectById(obj.resource_bifurcation);
        return {
          id: obj.id || null,
          resource_bifurcation: {
            id: resourceObj.value,
            name: resourceObj.label,
          },
          count: obj.count,
        };
      }),
    };
    dispatch(createExpertisebreakupThunk({ data: payload }));
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      infos: expertisebreakupList.infos,
      minimum_value: expertisebreakupList?.minimum_value,
      maximum_value: expertisebreakupList?.maximum_value,
    },
    onSubmit: handleClick,
  });

  useEffect(() => {
    dispatch(fetchExpertisebreakupThunk());
  }, [dispatch]);

  useEffect(() => {
    (async function () {
      const data = await getResouceBifercationList();
      if (Array.isArray(data)) {
        setResourceBifurcation(
          data.map((obj) => ({
            label: capitalCase(obj.name),
            value: obj._id,
          }))
        );
      }
    })();
  }, []);

  useEffect(() => {
    setFieldValue(
      "infos",
      expertisebreakupList.infos.map((obj) => {
        return {
          resource_bifurcation: obj.resource_bifurcation.id,
          count: obj.count,
          id: obj.id,
        };
      })
    );
    setFieldValue("minimum_value", expertisebreakupList.minimum_value);
    setFieldValue("maximum_value", expertisebreakupList.maximum_value);
  }, [expertisebreakupList]);

  const addInputRow = () => {
    setFieldValue("infos", [
      ...values.infos,
      {
        resource_bifurcation: values.resource_bifurcation,
        count: values.count,
      },
    ]);
  };

  const deleteInputRow = (item) => {
    const { id } = item ?? {};
    if (!id) {
      const remainValue = values?.infos?.filter(
        (_, i) => _?.resource_bifurcation !== item?.resource_bifurcation
      );
      setFieldValue("infos", remainValue);
    } else {
      dispatch(deleteExpertisebreakupThunk({id, data:{}}));
      setFieldValue("infos", expertisebreakupList?.infos);
    }
  };

  //   const filteredOptions = resourceTypesOption.filter(item => values?.infos?.some(r => r.resource_bifurcation === item.value));
  // const resp = resourceTypesOption.filter(item => !filteredOptions.includes(item));

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={0} p={2} alignItems={"end"}>
            <Grid md={12} px={1}>
              <span
                style={{
                  fontWeight: "bold",
                  marginBottom: "50px",
                  display: "inline-block",
                  marginRight: "10px",
                }}
              >
                Add Your Expertise Breakup Info to help us understand your
                Business.
              </span>
              <Button variant="contained" onClick={addInputRow}>
                Add
              </Button>
            </Grid>
            {values?.infos?.map((item, index) => (
              <>
                <Grid item md={5} px={1}>
                  <Element
                    label="Resource Bifurcation"
                    inputProps={{
                      placeholder: "Select Resource Bifurcation)",
                      type: "text",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `infos.${index}.resource_bifurcation`,
                    }}
                    eletype={inputType.select}
                    options={resourceBifurcation}
                    value={item.resource_bifurcation}
                    errorText={
                      touched.resource_bifurcation &&
                      touched.resource_bifurcation[index] &&
                      errors.resource_bifurcation &&
                      errors.resource_bifurcation[index]?.resource_bifurcation
                    }
                  />
                </Grid>
                <Grid item md={5} px={1}>
                  <Element
                    label="Count"
                    inputProps={{
                      placeholder: "Number of Count",
                      type: "number",
                      onChange: handleChange,
                      onBlur: handleBlur,
                      name: `infos.${index}.count`,
                    }}
                    eletype={inputType.number}
                    value={item.count}
                    errorText={
                      touched.count &&
                      touched.count[index] &&
                      errors.count &&
                      errors.count[index]?.count
                    }
                  />
                </Grid>
                <Grid md={2} px={1}>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: "30px",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => deleteInputRow(item)}
                    >
                      Delete
                    </Button>
                  </Box>
                </Grid>
              </>
            ))}
            <Grid item md={12} px={1} mt={4}>
              <Typography variant="h6" noWrap component="div">
                Average Cost Of Resources
              </Typography>
            </Grid>
            <Grid item md={6} px={1}>
              <Element
                label="Minimum Value"
                inputProps={{
                  placeholder: "Number of Minimum Value",
                  type: "number",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "minimum_value",
                }}
                eletype={inputType.select}
                options={[
                  {
                    label: "$0 - $10",
                    value: "$0 - $10",
                  },
                  {
                    label: "$10 - $20",
                    value: "$10 - $20",
                  },
                  {
                    label: "$20 - $40",
                    value: "$20 - $40",
                  },
                  {
                    label: "$40 - $75",
                    value: "$40 - $75",
                  },
                  {
                    label: "Above than $75",
                    value: "Above than $75",
                  },
                ]}
                value={values.minimum_value}
                errorText={touched.minimum_value && errors.minimum_value}
              />
            </Grid>
            <Grid item md={6} px={1}>
              <Element
                label="Maximum Value"
                inputProps={{
                  placeholder: "Number of Maximum Value",
                  type: "number",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "maximum_value",
                }}
                eletype={inputType.select}
                options={[
                  {
                    label: "$0 - $10",
                    value: "$0 - $10",
                  },
                  {
                    label: "$10 - $20",
                    value: "$10 - $20",
                  },
                  {
                    label: "$20 - $40",
                    value: "$20 - $40",
                  },
                  {
                    label: "$40 - $75",
                    value: "$40 - $75",
                  },
                  {
                    label: "Above than $75",
                    value: "Above than $75",
                  },
                ]}
                value={values.maximum_value}
                errorText={touched.maximum_value && errors.maximum_value}
              />
            </Grid>

            <Grid item md={12} p={0} sx={{ textAlign: "right" }}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
