// material
import { styled } from "@mui/material/styles";
import { Card, Typography, Grid, Box, Button } from "@mui/material";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./AppWeeklySales.theme";
import { capitalCase } from "change-case";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: "#f3f6f8",
}));

export default function AppWeeklySales({ isBusinessProfileExist }) {
  const user = useUser();
  const navigate = useNavigate();
  const classes = useStyles();

  function navigateToProfilePage() {
    navigate("/dashboard/myProfile");
  }

  return (
    <RootStyle align="left" data-tut="reactour__iso">
      <Grid container spacing={5} p={2}>
        <Grid item xs={12} sm={6} md={7} className={classes.hero}>
          <Typography variant="h3" align="left" ml={5} className={classes.head}>
            <span>Welcome back!</span> <br />
            {capitalCase(user.fullName)}
          </Typography>
          <Typography
            variant="subtitle2"
            align="left"
            ml={5}
            className={classes.subTitle}
          >
            Here’s an overview of{" "}
            <span data-tut="reactour__logo">all your projects</span> so far. You
            can easily manage all your work through your{" "}
            <span data-tut="reactour__copy">personalized dashboard.</span>
          </Typography>
          {!isBusinessProfileExist && (
            <Box ml={5} textAlign="left">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                className={classes.Button}
                onClick={navigateToProfilePage}
              >
                Create Business Profile
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={5} pt={3}>
          <Box className={classes.head_img}>
            <img src="/static/welcome_client.svg" width="240" height="240" />
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
