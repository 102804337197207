import { useEffect, useState } from "react";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { steps } from "../../utils/tourSteps";
import { useDispatch } from "react-redux";
import { changeUserLoginCount } from "../../store/slices/user/user.slice";

const ReactTour = () => {
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const dispatch = useDispatch();

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => {
    enableBodyScroll(target);
    dispatch(changeUserLoginCount());
  };

  const toggleShowMore = () => {
    setIsShowingMore((prevState) => !prevState);
  };

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const openTour = () => {
    setIsTourOpen(true);
  };

  // const accentColor = "#5cb7b7";
  const accentColor = "#00AB55";

  return (
    <>
      <Tour
        onRequestClose={closeTour}
        steps={steps}
        isOpen={isTourOpen}
        rounded={5}
        accentColor={accentColor}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        closeWithMask={false}
      />
    </>
  );
};
export default ReactTour;

const CustomHelper = ({ current, totalSteps, gotoStep, close, content }) => (
  <div>
    <div>{content}</div>
    <div>
      {current > 0 && (
        <button onClick={() => gotoStep(current - 1)}>Prev</button>
      )}
      {current < totalSteps - 1 ? (
        <button onClick={() => gotoStep(current + 1)}>Next</button>
      ) : (
        <button onClick={close}>Close</button>
      )}
    </div>
  </div>
);
