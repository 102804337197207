import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useStyles } from "./Proposal.theme";

const ProposalModal = ({ uploadFileClick, uploadFileCancelClick }) => {
  const classes = useStyles();
  // const[isFile,setIsFile] = useState(true)

  // function uploadFileClick() {

  // }
  return (
    <Box p={3}>
      <Typography
        variant="body1"
        sx={{ fontSize: "30px", fontWeight: "bold" }}
        mb={3}
      >
        Upload Newfile.pdf
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="space-start">
        <Button
          onClick={uploadFileClick}
          variant="contained"
          className={classes.propsalBtn}
        >
          Upload proposal
        </Button>

        <Button onClick={uploadFileCancelClick} variant="outlined">
          Change File
        </Button>
      </Stack>
    </Box>
  );
};

export default ProposalModal;
