import React, { useState } from "react";
import {
  Typography,
  Grid,
  Stack,
  Box,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { Formik } from "formik";
// components
import { green } from "@mui/material/colors";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import MyDropzone from "../../components/_dashboard/app/DropZone";
import { useDispatch } from "react-redux";
import { addPortfolioThunk } from "../../store/slices/portfolio/portfolio.slice";
import { uploadPortfolioValidation } from "../../utils/validation";

export default function UploadPortfolio({
  handleBusinessEditMode,
  hidePortfolioModal,
  businessId,
}) {
  const [loading, setLoading] = useState(false);
  function handleClick() {
    setLoading(true);
  }
  const [drop, setDrop] = useState(null);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    var formData = new FormData();
    formData.append("client_name", values.client_name);
    formData.append("description", values.description);
    formData.append("budget", values.budget);

    dispatch(addPortfolioThunk(formData)).unwrap().then(hidePortfolioModal);
  };
  const addAttachment = (val) => {
    setDrop(val.file);
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Typography gutterBottom variant="h5" component="div" mt={2}>
          Upload Portfolio
        </Typography>
        <Formik
          initialValues={{
            client_name: "",
            budget: "",
            year_of_work_done: "",
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, touched, handleSubmit }) => (
            <form>
              <Box sx={{ margin: "20px 0" }}>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  spacing={2}
                >
                  <Element
                    eletype={inputType.input}
                    label="Project Name"
                    value={values.client_name}
                    inputProps={{
                      name: "client_name",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                  />
                </Stack>
              </Box>

              <Box sx={{ margin: "20px 0" }}>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  spacing={2}
                >
                  <Element
                    eletype={inputType.input}
                    label="Budget"
                    value={values.values.budget}
                    inputProps={{
                      name: "values.budget",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                  />
                </Stack>
              </Box>

              {/* <Box sx={{ margin: "20px 0" }}>
          <Stack direction="row" justifyContent="space-evenly" spacing={2}>
            <Element eletype={inputType.input} label="Nature Of Work" />
          </Stack>
        </Box> */}

              <Box sx={{ margin: "20px 0" }}>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  spacing={2}
                >
                  <MyDropzone addAttachment={addAttachment} />
                </Stack>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  margin: "20px 0",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    color="secondary"
                    onClick={handleClick}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SendIcon />}
                    variant="contained"
                    sx={{
                      background: green[500],
                      ":hover": { background: green[300] },
                    }}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
