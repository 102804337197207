/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import "../Profile/upload.css";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import DeleteAction from "../../components/common/DeleteAction";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Modal from "../../components/Modal/Modal";
import PointOfContactModal from "../../components/Modal/PointOfContactModal";
import ListHead from "../../components/common/ListHead";
import ConfirmationsModal from "../../components/Modal/ConfirmationsModal";
import withBusinessProfile from "../../hoc/withBusinessProfile";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import {
  deletePOCThunk,
  fetchAllPOCThunk,
} from "../../store/slices/vendor/vendor.slice";
import {
  assignVendorToProfileHandler,
  fetchAssignedVendorHandler,
} from "../../utils/global/vendor.global";
import { showFailureToast } from "../../store/slices/toast/toast.slice";

//declaring column heading
const columns = [
  // {
  //   id: "id",
  //   label: "Id",
  //   align: "left",
  //   minWidth: 100,
  // },
  {
    id: "name",
    label: "Name",
    align: "left",
    minWidth: 200,
  },
  { id: "email_id", label: "Email ID", align: "left", minWidth: 200 },
  { id: "mobile", label: "Contact Number", align: "left", minWidth: 180 },

  {
    id: "designation",
    label: "Designation",
    align: "left",
    minWidth: 180,
  },
  // {
  //   id: "escalation_level",
  //   label: "Escalation Level",
  //   align: "center",
  //   minWidth: 120,
  // },
  {
    id: "action",
    label: "Action",
    align: "center",
    minWidth: 120,
  },
];

const TABLE_HEAD = [
  { id: "first_name", label: "First Name", alignRight: false },

  { id: "last_name", label: "Last Name", alignRight: false },
];

function POC() {
  const { pocData } = useSelector(({ vendor }) => vendor);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [currentdata, setCurrentData] = useState("");
  const [showUserAddModal, setUserAddModal] = useState(false);
  const [assignedVendor, setAssignedVendor] = useState(null);
  const [selectedPOC, setSelectedPOC] = useState();

  // console.log("poc : ", pocData);

  useEffect(() => {
    fetchAssignedVendorHandler().then((response) => {
      if (!response.data) setAssignedVendor(null);
      else setAssignedVendor(response.data[0]);
    });
  }, []);

  // --new for pagination starts here---

  useEffect(() => {
    if (page != 0) {
      dispatch(fetchAllPOCThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [pocData.length == 0]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchAllPOCThunk(newPage + 1));
    setPage(newPage);
  };

  // --new for pagination ends here---

  function handleEdit(id) {
    var data = pocData.find((d) => d._id === id);
    setCurrentData(data);
    setTimeout(() => {
      setShowModal(true);
    }, 0);
  }

  const handleClose = () => {
    setCurrentData("");
    setShowModal(false);
  };
  function handleDelete(id) {
    dispatch(deletePOCThunk({ id: id, page: page === 0 ? 1 : page + 1 }))
      .unwrap()
      .then((d) => {});
  }

  useEffect(() => {
    dispatch(fetchAllPOCThunk());
  }, [dispatch]);

  function handleAssignVendor() {
    setUserAddModal(false);
    assignVendorToProfileHandler({
      first_name: selectedPOC.first_name,
      last_name: selectedPOC.last_name,
      ...selectedPOC.mobile,
      email: selectedPOC.email,
    })
      .then((response) => {
        setAssignedVendor(response.data[0]);
      })
      .catch((err) => {
        dispatch(
          showFailureToast({
            message: err,
            vertical: "top",
            horizontal: "right",
          })
        );
      });
  }

  return (
    <>
      <Container>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            m={2}
          >
            <Typography variant="h5" gutterBottom>
              {/* Point Of Contact */}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              onClick={() => setShowModal(true)}
              sx={{ float: "right", mb: "1rem" }}
            >
              Add POC
            </Button>
          </Stack>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <Card>
                <Scrollbar>
                  {pocData.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead
                          sx={{ backgroundColor: "rgb(243, 246, 248)" }}
                        >
                          <TableRow>
                            {columns.map((column) =>
                              column.id != "action" ? (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    top: 57,
                                    minWidth: column.minWidth,
                                    backgroundColor: "#f3f6f8",
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    backgroundColor: "#f3f6f8",
                                    position: "sticky",
                                    right: 0,
                                  }}
                                >
                                  {column.label}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pocData.map((data, i) => (
                            <TableRow hover>
                              {/* <TableCell
                                onClick={() => handleEdit(data._id)}
                                component="th"
                                scope="row"
                              >
                                {i + 1}.
                              </TableCell> */}
                              <TableCell
                                onClick={() => handleEdit(data._id)}
                                component="th"
                                scope="row"
                              >
                                {`${data?.name}`}
                                {/* {data?.first_name + ' ' + data?.last_name} */}
                              </TableCell>
                              <TableCell
                                onClick={() => handleEdit(data._id)}
                                component="th"
                                scope="row"
                              >
                                {data.email}
                              </TableCell>
                              <TableCell
                                onClick={() => handleEdit(data._id)}
                                component="th"
                                scope="row"
                              >
                                {data.code + " " + data.mobile_number}
                              </TableCell>
                              <TableCell
                                onClick={() => handleEdit(data._id)}
                                component="th"
                                scope="row"
                              >
                                {data.designation}
                              </TableCell>
                              {/* <TableCell
                                onClick={() => handleEdit(data._id)}
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {data?.escalation_level?.toUpperCase()}
                              </TableCell> */}

                              <TableCell
                                hover
                                style={{
                                  position: "sticky",
                                  right: 0,
                                  background: "white",
                                  boxShadow: "5px 2px 5px grey",
                                }}
                                component="th"
                                scope="row"
                                align="center"
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  spacing={2}
                                  alignItems="center"
                                >
                                  {/* {!assignedVendor && (
                                    <AccountCircleIcon
                                      onClick={() => {
                                        setSelectedPOC(data);
                                        setUserAddModal(true);
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    />
                                  )} */}
                                  <DeleteAction
                                    handleDelete={() => handleDelete(data._id)}
                                  />
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SearchNotFound
                      src="/static/support.svg"
                      subtitle="There is currently no data to show."
                    />
                  )}
                </Scrollbar>
              </Card>
              <Modal
                maxWidth="md"
                isOpen={showModal}
                closeModal={handleClose}
                title="Point of Contact Details"
                content={
                  <PointOfContactModal
                    closeModal={handleClose}
                    props={currentdata}
                  />
                }
                dialogProps={{ fullWidth: true }}
                titleStyle={{ textAlign: "center", marginTop: 2 }}
                actionStyle={{ justifyContent: "center", marginBottom: 2 }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      {assignedVendor && (
        <div style={{ marginTop: "3rem" }}>
          <h3 style={{ paddingLeft: "24px" }}>Root User Details</h3>
          <Container>
            <Card sx={{ boxShadow: "none", mt: 1 }}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ListHead
                      headLabel={TABLE_HEAD}
                      styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                    />
                    <TableBody>
                      <TableRow
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">
                          {assignedVendor.first_name}
                        </TableCell>

                        <TableCell align="left">
                          {assignedVendor.last_name}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </div>
      )}
      <Modal
        isOpen={showUserAddModal}
        closeModal={() => setUserAddModal(false)}
        title="Create Account"
        content={
          <ConfirmationsModal
            closeModal={() => setUserAddModal(false)}
            isOpen={showUserAddModal}
            title="Are you sure you want to assign this vendor?"
            subtitle="This operation cannot be reversed.Click on Proceed to continue."
            type="success"
            btnText="Proceed"
            successCallback={handleAssignVendor}
          />
        }
        titleStyle={{ textAlign: "center", marginTop: 2 }}
      />
    </>
  );
}

export default withBusinessProfile(POC);
