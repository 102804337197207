import { chatterType } from "../../../utils/enum";

export function getAllColumnsWithStatusType(data) {
  const colIds = data.sectionColumnIds.filter(
    (id) => data.columns[id].type === chatterType.status
  );
  return colIds;
}

export const getInitState = (data) => {
  const columnsOfStatusType = getAllColumnsWithStatusType(data);
  const dataWithStatus = {};

  columnsOfStatusType.forEach((columnId) => {
    const currentColumn = data.columns[columnId];
    const columnOrder = [...currentColumn.choices, "Empty"];
    const columns = columnOrder.reduce((acc, choice) => {
      acc[choice] = {
        id: choice,
        title: choice,
        taskIds: [],
      };
      return acc;
    }, {});

    columns.Empty = {
      id: "Empty",
      title: "Empty",
      taskIds: [],
    };

    const tasks = {};
    Object.keys(data.sections).forEach((sectionId) => {
      const currentSection = data.sections[sectionId];
      currentSection.RowIds.forEach((rowId) => {
        const row = currentSection.rows[rowId];
        const value = row.values[columnId].value || "Empty";
        columns[value].taskIds.push(rowId);
        tasks[rowId] = {
          id: rowId,
          sectionId: sectionId,
          content: row.values[data.sectionColumnIds[0]].value,
        };
      });
    });

    dataWithStatus[columnId] = {
      tasks,
      columns,
      columnOrder,
    };
  });
  return dataWithStatus;
};
