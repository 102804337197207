import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import Scrollbar from "../../components/common/Scrollbar";
import { Icon } from "@iconify/react";
import Modal from "../../components/Modal/Modal";

import plusFill from "@iconify/icons-eva/plus-fill";

import EditIcon from "@mui/icons-material/Edit";
import {
  fetchTurnoverForLast3YearsThunk,
  deleteTurnoverForLast3YearsThunk,
} from "../../store/slices/vendor/vendor.slice";
import DeleteAction from "../../components/common/DeleteAction";
import TurnOverModal from "./TurnOverModal";

//declaring column heading
const columns = [
  {
    id: "turnover_year",
    label: "Year",
    align: "left",
    minWidth: 150,
  },
  {
    id: "created_at",
    label: "Project Booked",
    align: "left",
    minWidth: 200,
  },
  {
    id: "created_at",
    label: "Project Booked Amount",
    align: "left",
    minWidth: 250,
  },
  {
    id: "created_at",
    label: "Project Delivered",
    align: "left",
    minWidth: 200,
  },
  {
    id: "created_at",
    label: "Project Delivered Amount",
    align: "left",
    minWidth: 250,
  },
  {
    id: "Action",
    label: "Action",
    align: "left",
    minWidth: 150,
  },
];

export default function Turnover() {
  const { turnover } = useSelector(({ vendor }) => vendor);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [currentdata, setCurrentData] = useState("");
  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setCurrentData("");
    setShowModal(false);
  };

  useEffect(() => {
    dispatch(fetchTurnoverForLast3YearsThunk());
  }, [dispatch]);

  function handleEdit(id) {
    var data = turnover.find((d) => d.id === id);
    setCurrentData(data);
    setTimeout(() => {
      setShowModal(true);
    }, 0);
  }
  function handleDelete(row) {
    dispatch(deleteTurnoverForLast3YearsThunk(row))
      .unwrap()
      .finally((d) => {
        handleClose();
      });
  }

  return (
    <Box mt={5}>
      <Card sx={{ padding: "2rem" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Financials
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpen}
            sx={{ float: "right", mb: "1rem" }}
          >
            Add
          </Button>
        </Stack>
        <Grid container spacing={2} mt={1}>
          <Grid item md={12}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            top: 57,
                            minWidth: column.minWidth,
                            paddingLeft: "40px",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {turnover?.map((data) => (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            paddingLeft: "40px",
                          }}
                        >
                          {data.turnover_year}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: "40px" }}
                        >
                          {data.project_booked}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: "40px" }}
                        >
                          {data?.project_booked_amount}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: "40px" }}
                        >
                          {data.project_delivered}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ paddingLeft: "40px" }}
                        >
                          {data?.project_delivered_amount}
                        </TableCell>
                        <TableCell style={{ paddingLeft: "20px" }}>
                          <IconButton color="success" component="span">
                            <EditIcon
                              color="success"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleEdit(data.id)}
                            />
                          </IconButton>
                          <IconButton color="error" component="span">
                            <DeleteAction
                              handleDelete={() => handleDelete(data)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Modal
              maxWidth="md"
              isOpen={showModal}
              closeModal={handleClose}
              title="Turnover Details"
              content={
                <TurnOverModal closeModal={handleClose} props={currentdata} />
              }
              dialogProps={{ fullWidth: true }}
              titleStyle={{ textAlign: "center", marginTop: 2 }}
              actionStyle={{ justifyContent: "center", marginBottom: 2 }}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}
