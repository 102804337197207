import React from "react";
import Overview from "./Overview/Overview";
import SendProposal from "./SendProposal";
import { Container, Stack, Typography } from "@mui/material";
import CustomTabs from "./../../common/Tabs";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";

const labels = [
    {
        label: "Overview",
        value: "overview",
    },
    {
        label: "Send Proposal",
        value: "send-proposal",
    }
];

const LeadRequirements = () => {

    const data = useSelector(({ project }) => project);


    return (
        <Container sx={{ margin: 0 }}>
            <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="space-between"
                mb={5}
            >
                <Typography variant="h4" gutterBottom>
                    Project Requirements
                </Typography>
                {/* <Breadcrumb /> */}
            </Stack>
            <CustomTabs
                labels={labels}
            />
            <div style={{ marginBottom: "20px" }}></div>
            <Outlet />
        </Container>
    );
};

export default LeadRequirements;