import { Container, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Page from "../../components/Page";
import {
  getProjectThunk,
  tabChange,
} from "../../store/slices/project/project.slice";

import Loader from "../../components/Loader/Loader";

import { capitalCase } from "../../utils/change-case";
import CustomTabs from "../../components/common/Tabs";
import {
  clientSupport,
  commercialsPath,
  feedbackPath,
  overviewPath,
  proposalPath,
  scopePath,
  tablePath,
  vendorSupport,
  // } from "../../utils/constant";
} from "../../utils/constant";
import { createQueryString } from "../../utils/global/global";

const labels = [
  {
    label: "Overview",
    value: overviewPath,
    icon: "/static/icons/overview.svg",
    activeIcon: "/static/icons/overview_active.svg",
  },
  {
    label: "Proposal",
    value: proposalPath,
    icon: "/static/icons/proposal.svg",
    activeIcon: "/static/icons/proposal_active.svg",
  },
  // {
  //   label: "Project Table",
  //   value: tablePath,
  //   icon: "/static/icons/project_table.svg",
  //   activeIcon: "/static/icons/project_table_active.svg",
  // },
  // {
  //   label: "Scope",
  //   value: scopePath,
  //   icon: "/static/icons/scope.svg",
  //   activeIcon: "/static/icons/scope_active.svg",
  // },
  // {
  //   label: "Client Support",
  //   value: clientSupport,
  //   icon: "/static/icons/client_support.svg",
  //   activeIcon: "/static/icons/client_support_active.svg",
  // },
  // {
  //   label: "Vendor Support",
  //   value: vendorSupport,
  //   icon: "/static/icons/vendor_support.svg",
  //   activeIcon: "/static/icons/vendor_support_active.svg",
  // },
  // {
  //   label: "Feedback",
  //   value: feedbackPath,
  //   icon: "/static/icons/feedback.svg",
  //   activeIcon: "/static/icons/feedback_active.svg",
  // },
  // {
  //   label: "Commercials",
  //   value: commercialsPath,
  //   icon: "/static/icons/commercials.svg",
  //   activeIcon: "/static/icons/commercials_active.svg",
  // },
];

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Container sx={{ my: 4, mx: "auto" }}>
      {value === index ? children : null}
    </Container>
  );
}

function ProjectDetails() {
  // const { selectedTab, isLoading } = useSelector(({ project }) => project);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  // const {leadsOverviewAndProposal} = useSelector(
  //   ({ projectleads }) => projectleads
  // );

  // const queryString = window.location.search;

  // function handleItemClick(id) {
  //   navigate(`/dashboard/lead/${id}/overview`);
  // }

  // useEffect(() => {
  //   dispatch(getProjectThunk(params.id));
  // }, [params, dispatch]);
  // const project = useSelector(({ project }) => project.selectedProject);
  // const searchParams = new URLSearchParams(queryString);
  // var query = "";
  // if (searchParams.has("search")) {
  //   query = createQueryString({
  //     search: searchParams.get("search"),
  //     page: searchParams.get("page"),
  //     status: searchParams.get("status"),
  //     searchedUser: searchParams.get("searchedUser"),
  //     isNew: 0,
  //   });
  // }

  function fncGoToProjectSection() {
    navigate({
      pathname: "/dashboard/leads",
      // search: query,
    });
  }

  return (
    <Page title="Project Requirements | EMB">
      <Container sx={{ margin: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                {/* {capitalCase(leadsOverviewAndProposal?.over_view?.project_name)} */}
              </Typography>
              <Typography variant="span" gutterBottom>
                <span
                  style={{ color: "rgb(0, 170, 85)", cursor: "pointer" }}
                  onClick={fncGoToProjectSection}
                >
                  Leads
                </span>
                <span> {"  /  "}</span>
                <span>Project Requirement</span>
              </Typography>
              {/* <Breadcrumb /> */}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {/* <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="space-between"
              mb={5}
            >
              <SearchDropdown handleItemClick={handleItemClick} />
            </Stack> */}
          </Grid>
        </Grid>

        <Grid>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <CustomTabs labels={labels} />
          </Box>
          {/* {isLoading && (
            <TabPanel value={selectedTab} index={0}>
              <div style={{ position: "relative" }}>
                <Loader bg="rgba(255,255,255)" height="60vh" />
              </div>
            </TabPanel>
          )} */}
          <div style={{ marginBottom: "20px" }}></div>
          <Outlet />
        </Grid>
      </Container>
    </Page>
  );
}

export default ProjectDetails;
