import React from "react";

import { useStyles } from "../Section.theme";
function NumberInput({ value, change, styles }) {
  const classes = useStyles();

  function handleKeyDown(event) {
    if (
      !(
        (event.key >= 0 && event.key <= 9) ||
        event.key === "Backspace" ||
        event.key === "Tab"
      )
    )
      event.preventDefault();
  }

  return (
    <input
      className={classes.inputElement}
      value={value}
      // onChange={(e) => change(e.target.value)}
      style={styles}
      onKeyDown={handleKeyDown}
    />
  );
}

export default NumberInput;
