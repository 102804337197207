import { useSelector } from "react-redux";
import BusinessNotFound from "../components/common/BusinessNotFound";

function withBusinessProfile(Component) {

    return function BussinessProfileCheck(props) {

        const { isBusinessProfileExist } = useSelector(({ user }) => user);
        if (isBusinessProfileExist) return <Component {...props} />;

        return <BusinessNotFound />;
    }
}

export default withBusinessProfile;