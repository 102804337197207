import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { defaultAxios } from "../utils/axios/default.axios";
import { userAxios } from "../utils/axios/user.axios";
import { vendorAxios } from "../utils/axios/vendor.axios";
import { protectedAuthAxios } from "../utils/axios/protectedAuthAxios";
import { profileAxios } from "../utils/axios/profile.axios";
import { checkAxios } from "../utils/axios/check.axios";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => {
    let thunkWithParameters = thunk.withExtraArgument({
      apiService: {
        default: defaultAxios,
        user: userAxios,
        profile: profileAxios,
        vendor: vendorAxios,
        protectedAuth: protectedAuthAxios,
        check: checkAxios,
      },
    });
    return [thunkWithParameters];
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
