import React, { useEffect } from "react";
import { DateTime } from "luxon";

import Scrollbar from "../../components/common/Scrollbar";
import SearchNotFound from "../../components/NotFound/SearchNotFound";

import {
  Card,
  Typography,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
} from "@mui/material";
import ListHead from "../../components/common/ListHead";
import { Link, useNavigate } from "react-router-dom";
import { capitalCase } from "../../components/common/change-case";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllRALeadsThunk,
  fetchAllRALeadsWithPaginationThunk,
} from "../../store/slices/ra/ra.slice";

const TABLE_HEAD = [
  { id: "id", label: "Requested Id", alignRight: false },
  { id: "skills", label: "Skills", alignRight: false },
  { id: "budget", label: "Budget (Excluding GST)", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "created_at", label: "Request Date", alignRight: false },
  // { id: "view", label: "View", alignRight: false },
];

function RAList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leads = useSelector(({ ra }) => ra.leads);

  function handleRowClick(id) {
    navigate("/dashboard/ra/" + id + "/basic");
  }

  const [page, setPage] = React.useState(0);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchAllRALeadsWithPaginationThunk(newPage + 1));
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(fetchAllRALeadsThunk());
    // .then((res) =>
    // console.log("asdfasdfasdfadsfasdfadsf", res)
    // );
  }, [dispatch]);

  return (
    <>
      <Stack
        mb={2}
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>
          Leads
        </Typography>
      </Stack>

      <Card>
        <Scrollbar>
          {leads.data.length > 0 ? (
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  rowCount={leads.data?.length}
                  styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {leads?.data?.map((row, idx) => {
                    const {
                      _id,
                      lead_status,
                      emb_lead_id,
                      budget_excluding_gst,
                      skill_name,
                      created_at,
                    } = row;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer", zIndex: "" }}
                        onClick={() => handleRowClick(_id)}
                      >
                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {emb_lead_id}
                        </TableCell>

                        <TableCell
                          sx={{ textTransform: "capitalize" }}
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {skill_name}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {row?.budget_excluding_gst || "-"}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {capitalCase(lead_status)}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() => handleRowClick(_id)}
                        >
                          {DateTime.fromISO(created_at).toLocaleString(
                            DateTime.DATE_MED
                          )}
                        </TableCell>
                        {/* <TableCell align="left">
                          <Button
                            sx={{background:"#e1ffe1",
                          color:"black"}}
                            onClick={() => handleRowClick(_id)}
                            color="success"
                          >
                            View
                          </Button>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={leads.total}
                      rowsPerPage={leads.limit}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={false}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : (
            <SearchNotFound
              src="/static/support.svg"
              subtitle="There is currently no vendors data to show."
            />
          )}
        </Scrollbar>
      </Card>
    </>
  );
}

export default RAList;
