import { DateTime } from "luxon";
import { userAxios } from "../../utils/axios/user.axios";
import {
  getOrgIdByProfileIdApi,
  searchServiceApi,
  getServiceTeckStacksApi,
  getServiceTagsApi,
  searchIndustryApi,
  getAssignedVendorApi,
  searchBenchResourceBifurcationApi,
  getTechStackForteApi,
  searchBenchStrengthLocationApi,
} from "../apis.utils";
import { ORG_ID } from "../constant";
import { createQueryString } from "./global";
import { vendorAxios } from "../axios/vendor.axios";

export const modifyBusinessProfiles = (data) => {
  return data.map((profile) => ({
    ...profile,
    created_at: DateTime.fromISO(profile.created_at).toFormat("DD"),
    updated_at: DateTime.fromISO(profile.updated_at).toFormat("DD"),
    mobile: profile.country_code + "-" + profile.mobile_number,
  }));
};

export const storeOrgId = (token) => localStorage.setItem(ORG_ID, token);

export const getOrgId = () => localStorage.getItem(ORG_ID);

export const removeOrgId = () => {
  localStorage.removeItem(ORG_ID);
};

export const getOrgIdByProfileId = (id) => {
  const term = createQueryString({
    profile_id: id,
    profile_type: "vendor",
  });
  return userAxios.get(getOrgIdByProfileIdApi + term);
};

export const serviceFormatter = (data) => {
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  sortedData.forEach((ele) => {
    if (!ele.parent_id) {
      return arr.push({
        path: [ele.title],
        id: ele._id,
        title: ele.title,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      id: ele._id,
      title: ele.title,
    });
  });

  return arr;
};

export const searchServiceHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(searchServiceApi + term);
};

export const fetchAllTechStackByService = async (id) => {
  const query = createQueryString({
    service_id: id,
  });
  return userAxios.get(getServiceTeckStacksApi + query);
};

export const fetchAllTageByService = async (id) => {
  const query = createQueryString({
    service_id: id,
  });
  return userAxios.get(getServiceTagsApi + query);
};
export const searchIndustryHandler = (query) => {
  const term = createQueryString({
    query,
  });
  return userAxios.get(searchIndustryApi + term);
};


//vendor assigned in poc
export const fetchAssignedVendorHandler = (data) => {
  return vendorAxios.get(getAssignedVendorApi);
};
export const assignVendorToProfileHandler = (data) => {
  return vendorAxios.put(getAssignedVendorApi, data);
};


//benchStrength dropdown options
export const benchResourceBifurcationHandler=(search_term)=>{
  const term = createQueryString({
    search_term,
  });
  return vendorAxios.get(searchBenchResourceBifurcationApi + term);
}

export const benchStrengthTechstackHandler=(query)=>{
  const term = createQueryString({
    query,
  });
  return vendorAxios.get(getTechStackForteApi + term);
}

export const deliveryclassFormatter = (data) => {
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  sortedData.forEach((ele) => {
    if (!ele.parent_id) {
      return arr.push({
        path: [ele.name],
        id: ele._id,
        title: ele.name,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      id: ele._id,
      title: ele.name,
    });
  });

  return arr;
};

export const techstackFormatter=(data)=>{
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  sortedData.forEach((ele) => {
    if (!ele.parent_id) {
      return arr.push({
        path: [ele.tech_stack],
        id: ele.id,
        title: ele.tech_stack,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      id: ele.id,
      title: ele.tech_stack,
    });
  });

  return arr;
}

export const countryFormatter=(data)=>{
  const sortedData = data.sort((a, b) => (a.path > b.path ? 1 : -1));
  var arr = [];

  sortedData.forEach((ele) => {
    if (!ele.parent_id) {
      return arr.push({
        path: [ele],
        title: ele,
      });
    }
    arr.push({
      path: ele.path?.split("/"),
      title: ele,
    });
  });

  return arr;
}

export const benchStrengthLocationHandler=(search_term)=>{
  const term = createQueryString({
    search_term,
  });
  return vendorAxios.get(searchBenchStrengthLocationApi + term);
}