import React, { useState } from "react";
import { Box, Grid, Typography, Card, Stack, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
//----------- NEW
import { servicesValidation } from "../../utils/validations/vendor.validation";
import useForm from "../../hooks/useForm";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SearchService from "./SearchServiceTree";
import Input from "../../components/Form/Input";
//---------- NEW ENDS HERE

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import SortableTree, {
  changeNodeAtPath,
  removeNodeAtPath,
} from "react-sortable-tree-patch-react-17";

import "react-sortable-tree-patch-react-17/style.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const SearchServices = () => {
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const dispatch = useDispatch();
  const params = useParams();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        service_name: "",
        search_service: "",
        minimum_price: "",
        maximum_price: "",
        notes: "",
      },
      onSubmit: onSubmit,
      validationSchema: servicesValidation,
    });

  async function onSubmit(data) {
    var sendData = {
      profile_id: params.id,
      service_name: data.service_name,
      service_type: "custom",
      min_price: data.maximum_price,
      max_price: data.minimum_price,
      notes: data.notes,
    };
  }

  const [treeData, setTreeData] = useState([
    {
      title: "Designing and video creation",
      expanded: true,
      children: [
        {
          title: "Designing and content",
          expanded: true,
          children: [
            {
              title: "Product designing",
            },
            {
              title: "Graphic designing",
            },
            {
              title: "Brochure",
            },
          ],
        },
        {
          title: "Video creation",
        },
        {
          title: "Physical Photography",
        },
        {
          title: "UI/UX",
        },
      ],
    },
    {
      title: "Development",
      expanded: true,
      children: [
        {
          title: "Web development",
        },
        {
          title: "App development",
        },
      ],
    },
  ]);

  const handleClick = () => {
    setOpen(!open);
  };
  const [servicechecked, setServiceChecked] = useState([0]);

  const handleToggle = (value) => () => {
    const currentIndex = servicechecked.indexOf(value);
    const newServiceChecked = [...servicechecked];

    if (currentIndex === -1) {
      newServiceChecked.push(value);
    } else {
      newServiceChecked.splice(currentIndex, 1);
    }

    setServiceChecked(newServiceChecked);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} p={3}>
          <Grid item md={12} pb={2}>
            <Element
              label="Service Name"
              inputProps={{
                type: "text",
                placeholder: "Enter Service Name",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "service_name",
              }}
              eletype={inputType.input}
              value={values.service_name}
              errorText={touched.service_name && errors.service_name}
            />
          </Grid>
          <Grid item md={12} pb={2}>
            <Input label="Select Service" />
            <SearchService />
          </Grid>
          <Grid item md={12} pb={2}>
            <Element
              label="Minimum Price"
              inputProps={{
                type: "text",
                placeholder: "Enter Minimum Price",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "minimum_price",
              }}
              eletype={inputType.input}
              value={values.minimum_price}
              errorText={touched.minimum_price && errors.minimum_price}
            />
          </Grid>
          <Grid item md={12} pb={2}>
            <Element
              label="Maximum Price"
              inputProps={{
                type: "text",
                placeholder: "Enter Maximum Price",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "maximum_price",
              }}
              eletype={inputType.input}
              value={values.maximum_price}
              errorText={touched.maximum_price && errors.maximum_price}
            />
          </Grid>
          <Grid item md={12} pb={2}>
            <Element
              label="Notes"
              inputProps={{
                type: "text",
                placeholder: "Enter Notes",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "notes",
              }}
              eletype={inputType.input}
              value={values.notes}
              errorText={touched.notes && errors.notes}
            />
          </Grid>
          {/* <Grid item md={12} pb={2}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Approved"
                />
              </FormGroup>
            </Grid> */}
          <Grid item md={12} pb={2} justify="center">
            <Button size="large" type="submit" variant="contained">
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SearchServices;
