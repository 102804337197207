import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Grid, Box } from "@mui/material";

function BuisnessVerificationLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "1.5rem",
            width: "15%",
            borderRadius: "5px",
            marginBottom: "4rem",
            position: "relative",
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "1.5rem",
                  width: "30%",
                  borderRadius: "5px",
                }}
              />
              <Skeleton
                style={{
                  backgroundColor: "#edf2f9",
                  display: "flex",
                  height: "3rem",
                  width: "100%",
                  borderRadius: "5px",
                  marginBottom: "5rem",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <div>
          <Skeleton
            style={{
              backgroundColor: "#edf2f9",
              display: "flex",
              height: "3rem",
              width: "10%",
              borderRadius: "5px",
              position: "absolute",
              right: 30,
              bottom: 20,
            }}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default BuisnessVerificationLoader;
