import React, { useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { DateTime } from "luxon";

// components
import Page from "./../../components/Page";

export default function ModalForm({ data }) {
   
  const {
    description,
    service,
    pow_files,
    created_at,
    client_name,
    client_budget,
  } = data;

  return (
    <Page title="Dashboard: Products | Minimal-UI">
      <Box p={5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box>
            <Typography variant="h4" sx={{ color: "#212B36" }}>
              {client_name}
            </Typography>
          </Box>
          {/* <Box
            sx={{
              border: "2px solid #212B36",
              borderRadius: "5px",
              padding: "5px 10px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <EditIcon
                sx={{ width: "18px", height: "18px", color: "#484848" }}
              />
              <Typography sx={{ color: "#484848" }}>Edit</Typography>
            </Stack>
          </Box> */}
        </Stack>

        <Box sx={{ marginTop: "2rem" }}>
          <Stack direction="row" justifyContent="space-between" spacing={15}>
            <Box>
              <Typography sx={{ color: "#212B36", paddingBottom: "12px" }}>
                <b>Service </b> : {service.service_name}
              </Typography>
              <Typography sx={{ color: "#212B36", paddingBottom: "12px" }}>
                <b> Date Created </b> :{" "}
                {DateTime.fromISO(created_at).toFormat("DD")}
              </Typography>
              <Typography sx={{ color: "#212B36", paddingBottom: "12px" }}>
                <b> Budget </b>: {client_budget}
              </Typography>
              <Typography sx={{ color: "#212B36", paddingBottom: "12px" }}>
                <b> Industries </b>:{" "}
                {data?.industry === undefined
                  ? data?.industries === undefined
                    ? ""
                    : data.industries.map((e) => e.industry_name).join(",")
                  : data.industry.map((e) => e.industry_name).join(",")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <Typography
                sx={{
                  color: "#212B36",
                  marginBottom: "12px",
                }}
              >
                <b>POW Attached </b>
              </Typography>
              {pow_files.length > 0
                ? pow_files.map((e) => (
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems=""
                      spacing={1}
                    >
                      <InsertDriveFileIcon
                        sx={{ width: "14px", height: "19px", color: "#484848" }}
                      />
                      <Typography sx={{ color: "#484848", fontSize: "14px" }}>
                        {e.filename}
                      </Typography>
                    </Stack>
                  ))
                : ""}
            </Box>
          </Stack>
        </Box>
        <Typography
          sx={{ color: "#212B36", marginBottom: "12px", marginTop: "40px" }}
        >
          <b>Description :</b>
        </Typography>
        <Typography sx={{ color: "#212B36" }}>{description}</Typography>
      </Box>
    </Page>
  );
}
