import React, { useEffect, useMemo, useState } from "react";
import { Container } from "@mui/material";
import ClientsMenu from "./../components/_dashboard/clients/ClientsMenu";
import ClientsTable from "../components/_dashboard/clients/ClientsTable";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import Page from "../components/Page";
import { fetchAllProjectsThunk } from "../store/slices/project/project.slice";
import SearchNotFound from "../components/NotFound/SearchNotFound";
import ClientsGrid from "../components/_dashboard/clients/ClientsGrid";
import useSearch from "../hooks/useSearch";
import { projectStatus } from "../utils/enum";
import BusinessNotFound from "../components/common/BusinessNotFound";

const useStyles = makeStyles({
  menuMarginTop: {
    marginTop: "24px",
  },
});

const typeEnum = {
  table: "table",
  grid: "grid",
};

const Clients = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const clients = useSelector(({ project }) => project.projects);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(projectStatus.all);
  const dispatch = useDispatch();
  const [type, setType] = useState(typeEnum.table);
  const classes = useStyles();
  const { isBusinessProfileExist } = useSelector(({ user }) => user);

  useEffect(() => {
    if (isBusinessProfileExist) {
      dispatch(fetchAllProjectsThunk());
    }
  }, [dispatch, isBusinessProfileExist]);

  const { handleTextChange } = useSearch(clients, "project_name");

  function getProjectsByStatus(clients) {
    if (status === projectStatus.all) return clients;
    return clients.filter((project) => project.project_status === status);
  }

  const data = useMemo(() => {
    const statusData = handleTextChange(search, clients);
    return getProjectsByStatus(statusData);
  }, [clients, status, search]);

  const handleSearchChange = (e) => {
    const lowercasedValue = e.target.value.toLowerCase();
    setSearch(lowercasedValue);
  };

  function renderData() {
    if (!isBusinessProfileExist) return <BusinessNotFound />;
    if (data.length === 0)
      return (
        <SearchNotFound
          src="/static/not-found/project_table.png"
          subtitle="No clients yet."
          title=" "
        />
      );

    return type === typeEnum.table ? (
      <ClientsTable data={data} />
    ) : (
      <ClientsGrid projects={data} />
    );
  }

  return (
    <>
      <Outlet />
      <Page title="Clients | EMB">
        <Container sx={{ margin: 0 }}>
          <ClientsMenu
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            type={type}
            setType={setType}
            handleSearchChange={handleSearchChange}
            setStatus={setStatus}
            status={status}
          />

          <div className={classes.menuMarginTop}>{renderData()}</div>
        </Container>
      </Page>
    </>
  );
};

export default Clients;
