import React, { useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
// material
import {
  Typography,
  Container,
  Box,
  Card,
  Stack,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Avatar,
  Chip,
} from "@mui/material";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import { UserMoreMenu } from "../../../components/_dashboard/user";
//
import { useDispatch, useSelector } from "react-redux";
import ListSearchbar from "../../../components/common/ListSearchbar";
import Modal from "./../../../components/Modal/Modal";
import TicketModal from "./../../../components/Modal/TicketModal";
import SearchNotFound from "../../../components/NotFound/SearchNotFound";
import {
  addReportIssueThunk,
  getReportedIssueThunk,
} from "../../../store/slices/project/project.slice";
import ListHead from "./../../../components/common/ListHead";
import { capitalCase } from "../../../utils/change-case";
import { DateTime } from "luxon";
import useSearch from "../../../hooks/useSearch";

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  // { id: "description", label: "Description", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdOn", label: "Created On", alignRight: false },
  { id: "" },
];

export default function Ticket() {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const tickets = useSelector(({ project }) => project.selectedProject.tickets);

  const [refresh, setRefesh] = useState(false);
  const [search, setSearch] = useState("");
  const { handleTextChange } = useSearch(tickets, "title");

  useEffect(() => {
    dispatch(getReportedIssueThunk(params.id));
  }, [refresh, dispatch, params.id]);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  function getInitials(project_name) {
    return capitalCase(project_name.substring(0, 2));
  }

  function handleSubmit(data) {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    if (data.files.length) {
      data.files.map((file) => {
        formData.append("file", file);
      });
    }
    formData.append("project_id", params.id);
    dispatch(addReportIssueThunk(formData)).unwrap().then(handleClose);
  }

  const serachedTickets = useMemo(() => {
    if (!search) return tickets;
    return handleTextChange(search);
  }, [search, tickets]);

  return (
    <div>
      <Container>
        <Typography variant="h4">Support Ticket</Typography>
        <Box mt={5}>
          <Card>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
                p={1}
              >
                <Paper>
                  <ListSearchbar
                    placeholder="Search tickets"
                    handleSearchChange={(e) => setSearch(e.target.value)}
                  />
                </Paper>
                <Paper>
                  <Button onClick={handleOpen}>
                    <AddIcon />
                    Raise Ticket
                  </Button>
                </Paper>
              </Stack>
            </Box>

            <Page title="Support | EMB">
              {serachedTickets?.length > 0 ? (
                <Card sx={{ boxShadow: "none", borderRadius: "0px!important" }}>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <ListHead
                          headLabel={TABLE_HEAD}
                          rowCount={serachedTickets?.length}
                          styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                        />
                        <TableBody>
                          {serachedTickets?.map((row) => {
                            const {
                              id,
                              title,
                              // description,
                              state,
                              created_at,
                            } = row;

                            return (
                              <TableRow
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      {/* <Avatar
                                        sx={{
                                          fontSize: ".8rem",
                                          fontWeight: "600",
                                          backgroundColor: "#f3f6f8",
                                          color: "#637281",
                                        }}
                                      >
                                        {getInitials(title)}
                                      </Avatar> */}
                                      <Typography
                                        variant="body2"
                                        noWrap
                                        sx={{
                                          fontWeight: "700",
                                          paddingLeft: "16px",
                                        }}
                                      >
                                        <Link
                                          to={`${id}`}
                                          style={{
                                            textDecoration: "none",
                                            color: "black",
                                          }}
                                        >
                                          {capitalCase(title)}
                                        </Link>
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </TableCell>
                                {/* <TableCell align="left">
                                 
                                  {title}
                                </TableCell> */}
                                {/* <TableCell align="left">
                                  {description}
                                </TableCell> */}
                                <TableCell
                                  align="left"
                                  sx={{ paddingLeft: "8px!important" }}
                                >
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    label={state}
                                    padding="0px!important"
                                    sx={{
                                      fontSize: "12px",
                                      border: "none",
                                      textTransform: "capitalize",
                                      paddingLeft: "0px!important",
                                      color:
                                        state === "open"
                                          ? "#11A54A"
                                          : "#909090",
                                      // backgroundColor:
                                      //   state === "open"
                                      //     ? "#eff8f1"
                                      //     : "#fdd8de",
                                    }}
                                  />
                                </TableCell>

                                <TableCell align="left">
                                  {DateTime.fromISO(created_at).toFormat("DD")}
                                </TableCell>

                                {/* <TableCell align="right">
                                <UserMoreMenu />
                              </TableCell> */}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Card>
              ) : (
                <SearchNotFound
                  src="/static/not-found/no_tickets.png"
                  subtitle="No tickets raise yet."
                  title=" "
                />
              )}
            </Page>
          </Card>
        </Box>
      </Container>
      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add ticket"
        content={
          <TicketModal
            refresh={refresh}
            setRefresh={setRefesh}
            handleClose={handleClose}
            onSubmit={handleSubmit}
          />
        }
        contentStyle={{ width: "400px" }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
      />
    </div>
  );
}
