import React from "react";

import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import Scrollbar from "../../components/common/Scrollbar";
import ListHead from "../../components/common/ListHead";
import { capitalCase } from "../../components/common/change-case";
import SearchNotFound from "../../components/NotFound/SearchNotFound";

const TABLE_HEAD = [
  // { id: "id", label: "Id", alignRight: false },
  { id: "applicant_name", label: "Name", alignRight: false },
  { id: "skill", label: "Skills", alignRight: false },
  { id: "vendor_budget", label: "Vendor Budget", alignRight: false },
  { id: "year_of_exp", label: "Year Of Experience", alignRight: false },
  { id: "cv_filename", label: "CV", alignRight: false },
  { id: "emb_applicant_status", label: "Status", alignRight: false },
];

function CVTable({ cvs }) {
  function handleRowClick() {}

  return (
    <>
      <Card>
        <Scrollbar>
          {cvs.length > 0 ? (
            <TableContainer sx={{ width: "100%" }}>
              <Table>
                <ListHead
                  rowCount={cvs.length}
                  styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                  headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {cvs.map((row) => {
                    const {
                      applicant_name,
                      cv_filename,
                      cv_url,
                      emb_applicant_status,
                      skill,
                      skill_name,
                      vendor_budget,
                      year_of_exp,
                      id,
                    } = row;

                    let status = "";

                    if (emb_applicant_status.includes("cv")) {
                      let new_arr = [];
                      let statusArr = emb_applicant_status.split("_");
                      for (let i = 0; i < statusArr.length; i++) {
                        if (statusArr[i].includes("cv")) new_arr.push("CV");
                        else new_arr.push(capitalCase(statusArr[i]));
                      }
                      status = new_arr.join(" ");
                    } else {
                      status = capitalCase(
                        emb_applicant_status.split("_").join(" ")
                      );
                    }

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer", zIndex: "" }}
                        onClick={() => handleRowClick(id)}
                      >
                        {/* <TableCell align="left">{id}</TableCell> */}

                        <TableCell align="left">
                          {capitalCase(applicant_name)}
                        </TableCell>

                        <TableCell align="left">
                          {capitalCase(skill_name)}
                        </TableCell>

                        <TableCell align="left">
                          {capitalCase(vendor_budget)}
                        </TableCell>

                        <TableCell align="left">
                          {capitalCase(year_of_exp)}
                        </TableCell>

                        <TableCell align="left">
                          <a href={cv_url} target="_blank">
                            {capitalCase(cv_filename)}
                          </a>
                        </TableCell>

                        <TableCell align="left">{status}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <SearchNotFound />
          )}
        </Scrollbar>
      </Card>
    </>
  );
}

export default CVTable;
