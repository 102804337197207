import { Box, Stack, Typography, Grid, FormControl } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import ProjectTable from "./ProjectTable";
import Kanban from "./Kanban";
import GanttChart from "./Gantt";

import Dropdown from "../../components/Form/Dropdown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { timelineType } from "../../utils/enum";
import { capitalCase } from "../../utils/change-case";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchProjectTimelineThunk } from "../../store/slices/project/project.slice";
import SearchNotFound from "../NotFound/SearchNotFound";

const timelineItems = {
  table: {
    key: "table",
    label: "Main Table",
    component: <ProjectTable />,
  },
  gantt: {
    key: "gantt",
    label: "Gantt",
    component: <GanttChart />,
  },
  kanban: {
    key: "kanban",
    label: "Kanban",
    component: <Kanban />,
  },
  calendar: {
    key: "calendar",
    label: "Calendar",
  },
};

function TimelineDashboard() {
  const [status, setStatus] = useState(timelineType.table);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchProjectTimelineThunk(params.id));
  }, [dispatch, params.id]);

  const { isProjectTablePublished } = useSelector(({ project }) => project);

  if (!isProjectTablePublished)
    return (
      <div style={{ padding: "3rem" }}>
        <SearchNotFound src="/static/not-found/project_table.png" title="" subtitle="No data to show" />
      </div>
    );

  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Box
            sx={{
              backgroundColor: "#f1f4f9",
              backgroundImage:
                "linear-gradient(270deg, #ffffff 10%, #f1f4f9 74%)",
              borderLeft: "5px solid #b8c6d3",
              padding: "5px 12px 0 12px",
            }}
          >
            <Typography variant="h5" gutterBottom>
              {capitalCase(status)}
            </Typography>
          </Box>

          <Grid item md={4} display="flex" justifyContent="end">
            <FormControl>
              <Dropdown
                styles={{
                  position: "relative",
                  height: "40px",
                  lineHeight: "1.4375em",
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#212B36,",
                  borderRadius: "8px",
                  backgroundColor: "#F3F6F8",
                  maxWidth: "150px",
                  margin: "1rem 1rem 0 0",
                  textAlign: "left",
                }}
                options={[
                  { label: "Main Table", value: timelineType.table },
                  { label: "Gantt", value: timelineType.gantt },
                  { label: "Kanban", value: timelineType.kanban },
                ]}
                icons
                inputProps={{
                  handleChange: (e) => setStatus(e.target.value),
                }}
                value={status}
              />
            </FormControl>
          </Grid>
        </Stack>
      </Box>
      {timelineItems[status].component}
    </>
  );
}

export default TimelineDashboard;
