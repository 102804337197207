// material
import {
  Box,
  Button,
  Typography,
  Container,
  Stack,
  Card,
  TextField,
  Link,
} from "@mui/material";
import queryString from "query-string";
// components
import { MotionContainer } from "../../components/animate";
import { styled } from "@mui/material/styles";
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import AuthLayout from "../../layouts/AuthLayout";
import { useEffect, useState } from "react";
import withAuthLoader from "../../hoc/withAuthLoader";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import {
  setPasswordHandler,
  validateToken,
  // } from "../../utils/global/user.global";
} from "../../utils/global/user.global";

import { useDispatch } from "react-redux";
import {
  showFailureToast,
  showSuccessToast,
} from "../../store/slices/toast/toast.slice";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  paddingBottom: "190px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480, 
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

function NewPassword() {
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = queryString.parse(location.search);
  const token = query.code;
  useEffect(() => {
    if (!token) return;
    setLoading(true);
    validateToken(token)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        navigate("/login");
      });
  }, [token, navigate]);

  const handleResetClick = () => {
    if (!password) return;
    setPasswordHandler(password, token)
      .then((response) => {
        dispatch(
          showSuccessToast({
            message: response?.message,
            vertical: "top",
            horizontal: "right",
          })
        );
        navigate("/login");
      })
      .catch((err) => {
        dispatch(
          showFailureToast({
            message: err,
            vertical: "top",
            horizontal: "right",
          })
        );
      });
  };

  if (!token) return <Navigate to="/login" />;

  if (isLoading) return <Loader />;

  return (
    <RootStyle title="forgot password | ExMyB">
      {/* <AuthLayout sx={{ mt: 10 }}>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" to="/register">
          Get started
        </Link>
      </AuthLayout> */}

      {/* <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 20 }}>
            {/* Generate, New Password */}
          {/* </Typography>
          <img
            src="/static/vectors/forgot-password.svg"
            alt="forgot password"
            style={{ padding: "40px", paddingBottom: "0px" }}
          />
        </SectionStyle>
      </MHidden> */} 

      <Container maxWidth="sm">
        <ContentStyle>
          <MotionContainer initial="initial" open>
            <Box
              sx={{
                maxWidth: 500,
                margin: "auto",
                textAlign: "left",
                mb: 5,
              }}
            >
              <Typography variant="h3" paragraph>
                Create your password?
              </Typography>

              <Typography sx={{ color: "text.secondary", fontSize: "1rem" }}>
                Please create new password associated with your account and We
                will redirect you login dashboard.
              </Typography>

              <Box sx={{ margin: "20px 0" }}>
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                >
                  <TextField
                    fullWidth
                    value={password}
                    type="password"
                    id="outlined-basic"
                    label="Password"
                    variant="outlined"
                    placeholder="Please enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Stack>
              </Box>

              <Button
                size="large"
                sx={{ mt: 3 }}
                variant="contained"
                fullWidth={true}
                onClick={() => handleResetClick()}
              >
                Create Password
              </Button>
            </Box>
          </MotionContainer>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

export default withAuthLoader(NewPassword);
