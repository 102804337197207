import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addPortfolioHandler,
  getPortfolioHandler,
  addUploadPortfolioHandler,
  fetchAllPortfolioHandler,
  deletePortfolioHandler,
  updatePortfolioHandler,
} from "./portfolio.thunk";

const INIT_STATE = {
  portfolio: [],
  uploadPortfolio: [],
};

export const addPortfolioThunk = createAsyncThunk(
  "addPortfolio",
  addPortfolioHandler
);

export const getPortfolioThunk = createAsyncThunk(
  "getPortfolio",
  getPortfolioHandler
);

export const addUploadPortfolioThunk = createAsyncThunk(
  "addUploadPortfolio",
  addUploadPortfolioHandler
);

export const fetchAllUploadPortfolioThunk = createAsyncThunk(
  "fetchAllUploadPortfolio",
  fetchAllPortfolioHandler
);

export const deletePortfolioThunk = createAsyncThunk(
  "deletePortfolio",
  deletePortfolioHandler
);

export const updatePortfolioThunk = createAsyncThunk(
  "updatePortfolio",
  updatePortfolioHandler
);

const portfolioSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,

  extraReducers: (builder) => {
    builder
      .addCase(addPortfolioThunk.fulfilled, (state, action) => {
        state.portfolio.push(action.payload);
        return state;
      })
      .addCase(getPortfolioThunk.fulfilled, (state, action) => {
        state.portfolio = action.payload;
        return state;
      })
      .addCase(getPortfolioThunk.rejected, (state) => {
        state.position = [];
        return state;
      })
      .addCase(fetchAllUploadPortfolioThunk.fulfilled, (state, action) => {
        state.uploadPortfolio = action.payload;
        return state;
      })
      .addCase(fetchAllUploadPortfolioThunk.rejected, (state) => {
        state.uploadPortfolio = [];
        return state;
      })
      .addCase(addUploadPortfolioThunk.fulfilled, (state, action) => {
        state.uploadPortfolio = [...state.uploadPortfolio, ...action.payload];
        return state;
      });
  },
});

export default portfolioSlice.reducer;
