import React from "react";
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  Stack,
  Avatar,
  Typography,
  Chip,
} from "@mui/material";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import Scrollbar from "./../../Scrollbar";
import ListHead from "./../../common/ListHead";
import users from "./../../../_mocks_/user";
import { useNavigate } from "react-router-dom";
import { capitalCase } from "../../../utils/change-case";
import { DateTime } from "luxon";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

const TABLE_HEAD = [
  { id: "projectName", label: "Project Name", alignRight: false },
  { id: "companyName", label: "Company Name", alignRight: false },

  { id: "projectStatus", label: "Project Status", alignRight: false },
  { id: "timestamp", label: "Added On", alignRight: false },
  // { id: "detDetails", label: "Services", alignRight: false },
  // { id: "viewProposal", label: "View Proposal", alignRight: false },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 300 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function getInitials(client_name) {
  return capitalCase(client_name.substring(0, 2));
}

const ClientsTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead
              headLabel={TABLE_HEAD}
              rowCount={users.length}
              styles={{ backgroundColor: "rgb(243, 246, 248)" }}
            />
            <TableBody>
              {data.map((row) => {
                const { project_id } = row;

                return (
                  <TableRow
                    key={project_id}
                    tabIndex={-1}
                    role="checkbox"
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Link
                          to={`/dashboard/projects/${project_id}/overview`}
                          className={css`
                            text-decoration: none;
                            color: black;
                          `}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            {/* <Avatar
                              sx={{
                                fontSize: ".8rem",
                                fontWeight: "600",
                                backgroundColor: "#f3f6f8",
                                color: "#637281",
                                marginLeft: "20px",
                              }}
                            >
                              {getInitials(row?.client_name)}
                            </Avatar> */}
                            <Typography
                              variant="body2"
                              noWrap
                              sx={{ paddingLeft: "16px" }}
                            >
                              {capitalCase(row?.project_name)}
                            </Typography>
                          </Stack>
                        </Link>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {capitalCase(row?.client_name)}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{ paddingLeft: "8px!important" }}
                    >
                      <Chip
                        className={css`
                          font-size: 12px;
                          border: none;

                          text-transform: capitalize;
                        `}
                        size="small"
                        variant="outlined"
                        label={capitalCase(row?.project_status)}
                        // color="primary"
                        sx={{ color: "#11a54a" }}
                      />
                    </TableCell>

                    {/* <TableCell align="center">Open</TableCell> */}
                    <TableCell align="left">
                      {DateTime.fromISO(row?.project_created_at).toFormat("DD")}
                    </TableCell>
                    {/* <TableCell align="left">
                      <Button
                        onClick={() => {
                          navigate({
                            pathname:
                              "/dashboard/projects/" +
                              row?.project_id +
                              "/overview",
                          });
                        }}
                      >
                        View
                      </Button>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
};

export default ClientsTable;
