import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Card,
  Container,
  Stack,
  Typography,
  Button,
  Link,
} from "@mui/material";
import { Document, Page as ReactPDF } from "react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { fetchProjectCharterByProjectIdThunk } from "../../../store/slices/project/project.slice";
import SearchNotFound from "../../NotFound/SearchNotFound";
import Page from "../../Page";
import Loader from "../../Loader/Loader";

function Charter() {
  const [numPages, setNumPages] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchProjectCharterByProjectIdThunk(params.id));
  }, [dispatch, params]);

  const { selectedCharter } = useSelector(({ project }) => project);

  if (!selectedCharter?.project_charter_url)
    return (
      <div style={{ padding: "3rem" }}>
        <SearchNotFound src="/static/not-found/no_scoping_data_found.png" title="" subtitle="No scope added yet."/>
      </div>
    );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Page title="Proposal | EMB">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Scope
          </Typography>
          <Link
            href={selectedCharter.project_charter_url}
            variant="outlined"
            sx={{
              border: "1px solid rgb(0, 170, 85)",
              padding: "5px 25px",
              borderRadius: "20px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            target="_blank"
            startIcon={<DownloadIcon />}
          >
            Download
          </Link>
        </Stack>
        <Card>
          <Document
            file={selectedCharter.project_charter_url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Loader bg="rgba(255,255,255)" />}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div style={{ marginBottom: "20px" }}>
                <ReactPDF
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width="1000"
                />
                <br />
                <div style={{ textAlign: "center" }}>Page: {index + 1}</div>
                <div
                  style={{
                    borderBottom: "1px solid #00AB55",
                    margin: "10px auto",
                  }}
                ></div>
              </div>
            ))}
          </Document>
        </Card>
      </Container>
    </Page>
  );
}

export default Charter;
