import React, { useEffect } from "react";
import { Document, Page as ReactPDF } from "react-pdf";
import { Stack, Typography, Container, Card, Link } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

import Page from "../../Page";
import { getAllProposalsThunk } from "../../../store/slices/project/project.slice";
import { useState } from "react";
import SearchNotFound from "../../NotFound/SearchNotFound";
import Loader from "../../Loader/Loader";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  "@media (max-width:900px)": {
    pdfSize: {
      overflow: "auto",
      width: "90vw",
    },
  },
});

export default function Proposal() {
  const [numPages, setNumPages] = useState(null);
  const proposals = useSelector(
    ({ project }) => project.selectedProject.proposals
  );

  const params = useParams();

  const classes = useStyles();

  const dispatch = useDispatch();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    dispatch(getAllProposalsThunk(params.id));
  }, [dispatch, params.id]);

  // if (proposals?.length === 0)
  //   return (
  //     <div style={{ padding: "3rem" }}>
  //       <SearchNotFound src="/static/not-found/no_proposals.png" />
  //     </div>
  //   );

  return (
    <Page title="Proposal | EMB">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Proposal
          </Typography>
          {/* <Button variant="outlined" startIcon={<DownloadIcon />}>
            Download
          </Button> */}

          <Link
            href={
              proposals?.proposals &&
              proposals.proposals[0] &&
              proposals.proposals[0]?.vendor_contract
            }
            variant="outlined"
            sx={{
              border: "1px solid rgb(0, 170, 85)",
              padding: "5px 25px",
              borderRadius: "20px",
              textDecoration: "none",
              cursor: "pointer",
            }}
            target="_blank"
            startIcon={<DownloadIcon />}
          >
            Download
          </Link>
        </Stack>
        {proposals?.proposals &&
          proposals.proposals[0] &&
          proposals.proposals[0]?.vendor_contract && (
            <Card>
              <Document
                file={proposals.proposals[0].vendor_contract}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Loader bg="rgba(255,255,255)" />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div style={{ marginBottom: "20px" }}>
                    <ReactPDF
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width="1000"
                      className={classes.pdfSize}
                    />
                    <br />
                    <div style={{ textAlign: "center" }}>Page: {index + 1}</div>
                    <div
                      style={{
                        borderBottom: "1px solid #00AB55",
                        margin: "10px auto",
                      }}
                    ></div>
                  </div>
                ))}
              </Document>
            </Card>
          )}
      </Container>
    </Page>
  );
}
