import React, { useEffect, useState } from "react";
import { Typography, Grid, Stack, Box, Button } from "@mui/material";
import { useStyles as formStyles } from "../../components/Form/Form.theme";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import "../FormData/upload.css";
import { uploadCVValidation } from "../../utils/validation";
import { makeStyles } from "@mui/styles";
import {
  addCVsThunk,
  attachCVsThunk,
  fetchAllCVsThunk,
} from "../../store/slices/ra/ra.slice";
import { Table } from "antd";
import { fetchBenchInfoThunk } from "../../store/slices/vendor/vendor.slice";
import useResourceBifercation from "../../hooks/useBifercation";
import { BenchStrengthValidation } from "../../utils/validations/vendor.validation";
import BenchStrength from "../Profile/BenchStrength";

export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "14px !important",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});

const INIT_STATE = {
  applicant_name: "",
  skill: "",
  year_of_exp: "",
  vendor_budget: "",
  description: "",
};

// function useIsDarkMode() {
//   const theme = useTheme();
//   return theme.palette.mode === 'dark';
// }

export default function AttachCV({ hidePortfolioModalCheck, raId, props }) {
  const dispatch = useDispatch();
  const classes = formStyles();
  const [file, setFile] = useState(null);
  const cvs = useSelector(({ ra }) => ra.cvs);
  const [cvError, setCvError] = useState("");
  const [step, setStep] = useState(1);
  const [page, setPage] = React.useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editSelectedCv, setEditSelectedCV] = useState(null);


  // console.log(editSelectedCv);
  // console.log(raId);


  const { benchStrengthInfoData, totalbenchStrength } = useSelector(
    ({ vendor }) => vendor
  );

  useEffect(() => {
    dispatch(fetchBenchInfoThunk());
  }, [dispatch]);

  const handleChangePage = (pagination) => {
    dispatch(fetchBenchInfoThunk(pagination.current));
    setPage(pagination.current);
  };

  useEffect(() => {
    setStep(1);
  }, [hidePortfolioModalCheck]);

  useEffect(() => {
    dispatch(fetchAllCVsThunk(raId));
  }, [dispatch, raId]);

  const handleEditClick = (item) => {
    setStep(2);
    setEditSelectedCV(item);
  };

  const columns = [
    {
      title: "Candidate Name",
      dataIndex: "candidate_name",
      key: "candidate_name",
    },
    {
      title: "Skills",
      dataIndex: "tech_stack",
      key: "tech_stack",
      render: (item) => <span>{item?.name || "-"}</span>,
    },
    {
      title: "Budget",
      dataIndex: "cost_per_month",
      key: "cost_per_month",
    },
    {
      title: "CV",
      dataIndex: "cv",
      key: "cv",
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <span onClick={() => handleEditClick(record)}>
          <Button variant="outlined" color="secondary">
            edit
          </Button>
        </span>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: 1,
  //     name: "John Snow",
  //     skills: "React",
  //     budget: 120000,
  //     cv: "",
  //     experience: 4,
  //   },
  // ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleCvsSubmit = () => {
    dispatch(attachCVsThunk({ raId, data: selectedRowKeys }))
      .unwrap()
      .then(hidePortfolioModalCheck);
  };

  return (
    <Grid container maxWidth={"700px"}>
      <Grid item md={12}>
        {step === 1 && (
          <>
            <Typography gutterBottom variant="h5" component="div" mt={2}>
              Attach Cv
            </Typography>

            <div style={{ width: "100%" }}>
              <Table
                columns={columns}
                rowSelection={rowSelection}
                dataSource={benchStrengthInfoData.map((item) => ({
                  ...item,
                  key: item.id, // key is important for selecting rows
                  candidate_name: item.candidate_name || "Null",
                  tech_stack: item.tech_stack,
                  cv: (
                    <a href={item.cv_file_url} target="_Blank">
                      {item.cv_file.filename}
                    </a>
                  ),
                }))}
                pagination={{
                  current: page,
                  total: totalbenchStrength,
                  pageSize: 10,
                  showSizeChanger: false,
                }}
                onChange={handleChangePage}
              />
            </div>

            <Button
              // type="submit"
              // disabled={!isJdUploaded}
              variant="contained"
              sx={{ float: "right", marginTop: "20px" }}
              onClick={handleCvsSubmit}
            >
              Save
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Typography gutterBottom variant="h5" component="div">
              <span onClick={() => setStep(1)}>{"<-"}</span> &nbsp; Edit Cv
            </Typography>

            <BenchStrength
              closeModal={hidePortfolioModalCheck}
              singleBenchStrengthDataInfo={editSelectedCv}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
