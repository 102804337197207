import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { Link, Grid, Button, Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { makeStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import ListSearchbar from "../components/common/ListSearchbar";
import Modal from "../components/Modal/Modal";
import useModal from "../hooks/useModal";
import ModalForm from "./FormData/ModalForm";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UploadPortfolio from "./FormData/UploadPortfolio";
import CreatePortfolio from "./FormData/CreatePortfolio";
import {
  getPortfolioThunk,
  fetchAllUploadPortfolioThunk,
  deletePortfolioThunk,
} from "../store/slices/portfolio/portfolio.slice";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessProfileAsyncThunk } from "../store/slices/user/user.slice";
import SearchNotFound from "../components/NotFound/SearchNotFound";
import Page from "../components/Page";
import { DateTime } from "luxon";
import { useEffect, useState, useMemo } from "react";
import useSearch from "../hooks/useSearch";
import withBusinessProfile from "../hoc/withBusinessProfile";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import UserMoreMenu from "../components/_dashboard/user/UserMoreMenu";
import { upperCase } from "lodash";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 256,
    textAlign: "center",
  },
  portfolioHeader: {
    "& span": {
      lineHeight: 1.3,
      height: "40px",
    },
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "auto",
  },
  heading: {
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: "gray",
    marginBottom: "0.875em",
  },
  statLabel: {
    fontSize: 12,
    color: "gray",
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    letterSpacing: "1px",
  },
  borderedGridStyles: {
    borderColor: "rgba(0, 0, 0, 0.08)",
    textAlign: "center",
  },
  "@media (max-width:1000px)": {
    cardInMobile: {
      paddingLeft: "40px !important",
      paddingRight: "20px",
    },
  },
  "@media (max-width:550px)": {
    cardInMobile: {
      margin: 15,
      padding: 0,
      paddingLeft: "30px !important",
    },
    cardParent: {
      display: "-webkit-inline-box",
    },
    centerDiv: {
      display: "flex !important",
      justifyContent: "center !important",
    },
  },
}));

function Portfolio() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [currentdata, setCurrentData] = useState("");

  const businessProfiles = useSelector(({ user }) => user.userData.business);
  const uploadPortfolioData = useSelector(
    ({ portfolio }) => portfolio.uploadPortfolio
  );

  const selectedBusiness = businessProfiles;

  useEffect(() => {
    dispatch(getBusinessProfileAsyncThunk());
    if (!selectedBusiness?.id) return;
    dispatch(fetchAllUploadPortfolioThunk(selectedBusiness?.id));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(fetchAllUploadPortfolioThunk(selectedBusiness?.id));
  }, [selectedBusiness?.id]);

  useEffect(() => {
    if (!selectedBusiness?.id) return;
    dispatch(getPortfolioThunk(selectedBusiness?.id));
  }, [selectedBusiness?.id, dispatch]);

  const portfolioData = useSelector(({ portfolio }) => portfolio.portfolio);

  const [search, setSearch] = useState("");
  const [modalViewData, setmodalViewData] = useState("");
  const { handleTextChange } = useSearch(portfolioData, "client_name");
  const { isModalOpen, showModal, hideModal } = useModal();
  const {
    isModalOpen: isPortfolioModal,
    showModal: showPortfolioModal,
    hideModal: hidePortfolioModal,
  } = useModal();
  const {
    isModalOpen: isPortfoliofile,
    showModal: showPortfoliofile,
    hideModal: hidePortfoliofile,
  } = useModal();

  const businessData = useMemo(() => {
    if (!search) return portfolioData;
    return handleTextChange(search);
  }, [search, portfolioData]);

  const fncSendModalData = (data) => {
    setmodalViewData(data);
    showModal();
  };

  function fncDeletePortfolio(id) {
    dispatch(deletePortfolioThunk(id));
  }
  function fncEditPortfolio(data) {
    setCurrentData(data);
    showPortfolioModal();
  }
  function hidePortfolioModalCheck() {
    hidePortfolioModal();
  }
  return (
    <>
      <Page title="Portfolio | EMB">
        <Container>
          <Grid container spacing={4} px={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={4}
                py={3}
              >
                <Grid md={6}>
                  <ListSearchbar
                    placeholder="Search Portfolios"
                    handleSearchChange={(e) => setSearch(e.target.value)}
                  />
                </Grid>
                <Grid md={6}>
                  <Stack
                    mr={3}
                    direction="row"
                    spacing={2}
                    sx={{
                      marginLeft: { sm: "23px", xs: "23px" },
                      float: "right",
                    }}
                  >
                    <Button
                      variant="contained"
                      to="/dashboard/user/createUser"
                      startIcon={<Icon icon={plusFill} />}
                      onClick={showPortfolioModal}
                    >
                      Add Project
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {businessData.length === 0 ? (
              <Box sx={{ margin: "0 auto" }}>
                <SearchNotFound
                  title=""
                  subtitle="No portfolio to show."
                  width="280px"
                />
              </Box>
            ) : (
              <Grid container spacing={4} px={2} className={classes.cardParent}>
                {businessData.map((user) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.cardInMobile}
                    >
                      <Card
                        sx={{
                          minWidth: 315,
                          height: 400,
                          borderRadius: "0.5rem",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <CardHeader
                            className={classes.portfolioHeader}
                            // avatar={
                            //   <Avatar
                            //     alt="Cindy Baker"
                            //     src="/static/default-user.png"
                            //     sx={{
                            //       width: 35,
                            //       height: 35,
                            //     }}
                            //   ></Avatar>
                            // }
                            title={user.service?.service_name}
                          />
                          {/* <UserMoreMenu
                            handleDelete={() => {
                              fncDeletePortfolio(user.id);
                            }}
                            handleEdit={() => {
                              fncEditPortfolio(user);
                            }}
                          /> */}
                        </Stack>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <CardMedia
                            component="img"
                            // src={user.pow_files && user.pow_files[0]?.url}
                            src={"/static/portfolioImg.png"}
                            alt="Paella dish"
                            className="portfolioImg"
                            sx={{
                              padding: "14px",
                              height: "200px",
                              borderRadius: "20px",
                            }}
                          />
                        </Box>
                        <CardContent>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "14px !important" }}
                            >
                              Client-
                              {/* {user.service?.service_name} */}
                            </Typography>

                            <Link
                              component="button"
                              className={styles.statLabel}
                              onClick={() => fncSendModalData(user)}
                              sx={{
                                textDecoration: "none",
                                borderRight: "0.5px solid #f2f3f3",
                                display: "flex",
                                fontSize: "11px",
                                // top: "-9px",
                              }}
                            >
                              <Box varient="span" component="span">
                                <RemoveRedEyeOutlinedIcon
                                  sx={{
                                    fontSize: "15px",
                                    marginRight: "4px",
                                  }}
                                />
                              </Box>
                              View Details
                            </Link>
                          </Stack>

                          <Typography variant="" component="div">
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#0947B3",
                                fontSize: "14px !important",
                                textTransform: "upperCase",
                                textOverflow: "ellipsis",
                                overflow: " hidden",
                                whiteSpace: "nowrap",
                                width: "175px",
                              }}
                            >
                              {user.client_name}
                              {/* {user.service?.service_name} */}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              <CalendarTodayIcon
                                sx={{
                                  fontSize: "11.6px",
                                  marginRight: "5px",
                                  marginTop: "15px",
                                }}
                              />
                              {DateTime.fromISO(user.created_at).toFormat("DD")}
                            </Typography>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>

          {/* ------------------ Uploadedportfoliodata ----------÷ */}
          <Box p={2}>
            <Stack
              direction="row"
              spacing={5}
              sx={{
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <Grid
                item
                sx={{
                  marginTop: "20px",
                  fontSize: "24px",
                  color: "#343434",
                  fontWeight: "bold",
                }}
                xs={12}
                md={12}
                className={classes.centerDiv}
              >
                {uploadPortfolioData?.length > 0 && "Uploaded Projects"}
              </Grid>
            </Stack>

            <Stack direction="row" spacing={5}>
              <Grid container spacing={2}>
                {uploadPortfolioData &&
                  uploadPortfolioData.map((data) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={classes.centerDiv}
                    >
                      <a
                        href={data.url}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <Card sx={{ width: "300px", borderRadius: "10px" }}>
                          <Box p={3}>
                            <Stack direction="row" spacing={2}>
                              <Avatar
                                sx={{ bgcolor: "#eff8f1" }}
                                variant="rounded"
                              >
                                {data.media_type === "pdf" ? (
                                  <PictureAsPdfIcon sx={{ color: "#de4435" }} />
                                ) : (
                                  <PhotoLibraryIcon sx={{ color: "#00c9a7" }} />
                                )}
                              </Avatar>
                              <Box sx={{ width: "160px" }}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: "500" }}
                                >
                                  {data.filename?.length > 15
                                    ? data.filename.substring(0, 15) + "..."
                                    : data.filename}
                                </Typography>
                                <Typography sx={{ fontSize: "12px" }}>
                                  {DateTime.fromISO(data.created_by).toFormat(
                                    "DD"
                                  )}
                                </Typography>
                              </Box>
                              <Avatar
                                sx={{ bgcolor: "#f3f6f8", color: "#637281" }}
                              >
                                S
                              </Avatar>
                            </Stack>
                          </Box>
                        </Card>
                      </a>
                    </Grid>
                  ))}
              </Grid>
            </Stack>
          </Box>
          {/* -------------------------- ENDS HERE ---------------- */}
        </Container>
      </Page>

      <Modal
        maxWidth="md"
        isOpen={isModalOpen}
        closeModal={hideModal}
        content={<ModalForm data={modalViewData} />}
      />
      <Modal
        isOpen={isPortfolioModal}
        closeModal={() => {
          hidePortfolioModalCheck();
          setCurrentData("");
        }}
        content={
          <CreatePortfolio
            sx={{ marginTop: "20px" }}
            hidePortfolioModalCheck={hidePortfolioModalCheck}
            businessId={selectedBusiness?.id}
            props={currentdata}
          />
        }
        contentStyle={{ minWidth: "400px" }}
        ac
      />

      <Modal
        isOpen={isPortfoliofile}
        closeModal={hidePortfoliofile}
        content={
          <UploadPortfolio
            hidePortfolioModal={hidePortfolioModal}
            sx={{ marginTop: "20px" }}
            businessId={selectedBusiness?.id}
          />
        }
        contentStyle={{ minWidth: "400px" }}
      />
    </>
  );
}

export default withBusinessProfile(Portfolio);
