import { Badge, Tab, Tooltip } from "@mui/material";

const ProfileTabs = ({ modifiedLabels, ind, getChildIcon, getIcon }) => {
  return (
    <>
      {modifiedLabels?.map((singleLink, i) =>
        singleLink.childTabs ? (
          <Tab
            key={i}
            label={singleLink?.label}
            icon={singleLink.icon && getChildIcon(singleLink, i)}
            iconPosition="start"
          />
        ) : (
          // <ProfileTabs  modifiedLabels={modifiedLabels} ind={i} getChildIcon={getChildIcon}/>
          <Tab
            key={i}
            label={
              <Tooltip
                title={
                  singleLink?.tooltip ||
                  "Some Details has not been filled in yet"
                }
                placement="top"
                arrow
              >
                <Badge badgeContent="!" color="error">
                  {singleLink?.label}
                </Badge>
              </Tooltip>
            }
            icon={singleLink.icon && getIcon(singleLink, i)}
            iconPosition="start"
          />
        )
      )}
    </>
  );
};
export default ProfileTabs;
