import { getWalletDetailsApi } from "./../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";

export const getWalletDetailsHandler = async (__, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(getWalletDetailsApi);
    const responseData = response.data;
    return responseData;
  } catch (err) {
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};
