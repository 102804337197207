import _ from "lodash";
import { userAxios } from "../axios/user.axios";
import {
  fetchAllProjectsApi,
} from "../apis.utils";
import { showFailureToast, showWarningToast } from "../../store/slices/toast/toast.slice";

export const getVirtualElement = function (args) {
  var { name, onChange, onBlur, type } = args;

  function getEvent() {
    let event = {};
    event.target = {
      type,
      name,
    };
    return event;
  }

  function setValue(value) {
    const event = getEvent();
    event.target.value = value;
    onChange(event);
  }

  function blurElement() {
    const event = getEvent();
    if (onBlur) {
      onBlur(event);
    }
  }

  function resetValue() {
    const event = getEvent();
    event.target.value = "";
    onChange(event, "");
  }

  return {
    setValue,
    blurElement,
    resetValue,
  };
};
export const createQueryString = (data) => {
  return "?" + new URLSearchParams(data).toString();
};

export const debounce = (time) => {
  return _.debounce((func) => {
    func();
  }, time);
};

export const getGanttChartData = (data) => {
  return data
    .map((section) => {
      const {
        id,
        sectionHeaderValues = [],
        sectionMatrix,
        headerIds,
      } = section;
      if (!sectionHeaderValues[1]?.value || !sectionHeaderValues[2]?.value)
        return undefined;
      const currentGanttSection = [
        {
          id: id,
          name: sectionHeaderValues[0].value,
          start: new Date(sectionHeaderValues[1].value),
          end: new Date(sectionHeaderValues[2].value),
          type: "project",
        },
      ];
      const childSection = sectionMatrix.map((row, idx) => {
        if (!row[1]?.value || !row[2]?.value) return undefined;
        return {
          id: headerIds[idx],
          name: row[0].value,
          start: new Date(row[1].value),
          end: new Date(row[2].value),
          project: id,
        };
      });
      return currentGanttSection.concat(childSection.slice(1).filter(Boolean));
    })
    .filter(Boolean);
};

export const navigateFromApp = ({ domain, redirectUrl = "/", search = {} }) => {
  const query = createQueryString(search);
  const url = new URL(domain);
  url.pathname = redirectUrl;
  url.search = query;
  return (window.location.href = url.toString());
};

export const getAllProjects = (query) => {
   
  return userAxios.get(
    fetchAllProjectsApi +
      `?query_term=${query.term}&type=${query.type}&spoc${query.spoc}&page=1` +
      "&limit=10"
  );
};

export const showToastAlert = (message, thunkApi)=> {
  if (message === "Request Received Successfully") {
    // Warning will come here
    return thunkApi.dispatch(
      showWarningToast({
        message: message,
        vertical: "top",
        horizontal: "right",
      })
    );
  } else {
    return thunkApi.dispatch(
      showFailureToast({
        message: message,
        vertical: "top",
        horizontal: "right",
      })
    );
  }
}