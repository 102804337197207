import React from "react";
import { useStyles } from "./Form.theme";
import { NativeSelect } from "@mui/material";

const SelectInput = ({ label, errorText, inputProps, value, styles, icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.formGroup}>
      {label ? <label className={classes.formLabel}>{label}</label> : null}
      <div className={classes.selectGroup}>
        <NativeSelect
          defaultValue={2}
          className={classes.selectOption}
          sx={{ width: "100px" }}
        >
          <option value={10}>https://</option>
          <option value={20}>http://</option>
        </NativeSelect>
        <input
          className={classes.formControl}
          style={styles}
          value={value}
          {...inputProps}
          autoComplete="off"
        />
      </div>
      {icon}
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </div>
  );
};

export default SelectInput;
