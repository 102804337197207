import {
  addBusinessProfile,
  getAddressesApi,
  getBusinessProfileApi,
  uploadUserProfileApi,
  userApi,
  editBusinessProfile,
} from "../../../utils/apis.utils";
import { storeOrgId } from "../../../utils/global/auth.global";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
// From all these thunk we have to return a Promise
export const getBusinessProfile = async (__, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(getBusinessProfileApi);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const getAddresses = async (__, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(getAddressesApi);
    const responseData = response.data;
    return responseData;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const createBusinessProfile = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addBusinessProfile, {
      data: {
        ...data,
      },
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    storeOrgId(response.data?.org_id);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updateBusinessProfile = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(`${editBusinessProfile}`, {
      data: {
        ...data,
      },
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updateUserProfileHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(userApi, {
      data: {
        ...data,
        country_code: data.mobile.countryCode,
        ...data.mobile,
        ...data.address,
        address: undefined,
        mobile: undefined,
      },
    });
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const createBusinessAddress = async (__, thunkApi) => { };

export const updateBusinessAdrress = async (__, thunkApi) => { };

export const deleteBusinessAddress = async (__, thunkApi) => { };

export const uploadProfileHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(uploadUserProfileApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};
