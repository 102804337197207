import React, { useRef } from "react";
import {
  Typography,
  Container,
  IconButton,
  Card,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
// components
import { green } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../../../hooks/useUser";
import { uploadProfileThunk } from "./../../../../store/slices/user/user.slice";
import AddressLoader from "../../../Skeletons/AddressLoader";
import Address2Loader from "../../../Skeletons/Address2Loader";
import { useStyles } from "./PersonalDetail.theme";

const PersonalDetails = ({ handleprofileEditMode }) => {
  const user = useUser();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();

  const profileData = useSelector(({ user }) => user.userData.profile);

  function handleFileChange(e) {
    var formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_type", "profile");
    dispatch(uploadProfileThunk(formData));
  }

  return (
    <>
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg, application/pdf"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleFileChange}
      />
      <Container>
        <Box>
          <Grid container spacing={4} mt={5}>
            <Grid item md={4} sx={{ width: "100%" }}>
              <Card sx={{ minHeight: "420px" }}>
                <Stack direction="column" spacing={2} sx={{ p: 3, pr: 0 }}>
                  <Box sx={{ textAlign: "right", mr: 1 }}>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      onClick={() => handleprofileEditMode(true)}
                    >
                      <ModeEditOutlinedIcon
                        fontSize="small"
                        sx={{ color: green[400] }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <Avatar
                      alt="Profile-image"
                      src={
                        user.profileUrl
                          ? user.profileUrl
                          : "https://cdn.worldvectorlogo.com/logos/israel-aircraft-unit-2.svg"
                      }
                      sx={{
                        marginTop: "10px",
                        width: 144,
                        height: 144,
                        mx: "auto",
                        borderRadius: "50%",
                        border: "1px dashed rgba(145, 158, 171, 0.32)",
                        backgroundColor: "rgb(244, 246, 248)",
                        cursor: "pointer",
                      }}
                      onClick={() => inputRef.current.click()}
                    />
                  </Box>
                  {profileData?.first_name && (
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      {user.fullName}
                    </Typography>
                  )}
                </Stack>
              </Card>
              {/* <AddressLoader /> */}
            </Grid>

            <Grid item md={8} sx={12} xs={12}>
              <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
                <Grid container className={classes.dBlock}>
                  {profileData?.first_name && (
                    <Grid item sm={12} md={6} xs={12}>
                      <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                        <Box
                          sx={{
                            backgroundColor: "rgb(244, 246, 248)",
                            borderRadius: "5px",
                            maxWidth: "50px",
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/static/icons/name.svg"
                            width={20}
                            alt="name"
                          />
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">Full Name</Typography>
                          <Typography variant="body2">
                            {profileData?.first_name} {profileData?.last_name}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  )}

                  {profileData?.email && (
                    <Grid item sm={6} md={6}>
                      <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                        <Box
                          sx={{
                            backgroundColor: "rgb(244, 246, 248)",
                            borderRadius: "5px",
                            maxWidth: "50px",
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/static/icons/mail.svg"
                            width={25}
                            alt="email"
                          />
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">Email</Typography>
                          <Typography variant="body2">
                            {profileData?.email}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  )}

                  {profileData?.mobile?.mobile_number && (
                    <Grid item sm={6} md={6}>
                      <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                        <Box
                          sx={{
                            backgroundColor: "rgb(244, 246, 248)",
                            borderRadius: "5px",
                            maxWidth: "50px",
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/static/icons/phone.svg"
                            width={25}
                            alt="phone"
                          />
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">Phone</Typography>
                          <Typography variant="body2">
                            {profileData?.mobile?.countryCode}{" "}
                            {profileData?.mobile?.mobile_number}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  )}

                  {profileData?.country && (
                    <Grid item sm={6} md={6}>
                      <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                        <Box
                          sx={{
                            backgroundColor: "rgb(244, 246, 248)",
                            borderRadius: "5px",
                            maxWidth: "50px",
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/static/icons/flag.svg"
                            width={25}
                            alt="phone"
                          />
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">Country</Typography>
                          <Typography variant="body2">USA</Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  )}

                  {profileData?.address_line1 && (
                    <Grid item sm={6} md={8}>
                      <Stack direction="row" spacing={2} sx={{ p: 3, pr: 0 }}>
                        <Box
                          sx={{
                            backgroundColor: "rgb(244, 246, 248)",
                            borderRadius: "5px",
                            maxWidth: "50px",
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/static/icons/company-address.svg"
                            width={25}
                            alt="address"
                          />
                        </Box>
                        <Box>
                          <Typography variant="subtitle2">Address</Typography>
                          <Typography variant="body2">
                            {user.address}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Card>
              {/* <Address2Loader /> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PersonalDetails;
