import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";
// import account from "../../mock-data/account";
import useUser from "../../hooks/useUser";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendorProfileDetailsThunk } from "../../store/slices/vendor/vendor.slice";
import ProfileCompletition from "../../components/_dashboard/profile/ProfileCompletition";
import { capitalCase } from "change-case";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
// const DRAWER_WIDTH = 359;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const user = useUser();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { profileData, ra_enabled } = useSelector(({ vendor }) => vendor);
  const [newSidebarConfig, setNewSidebarConfig] = useState(sidebarConfig);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchVendorProfileDetailsThunk());
    // .then((res) => {
    //   if (res.type === "fetchVendorProfileDetails/fulfilled") {
    //     // resource_augmentation_service
    //     if (res.payload?.resource_augmentation_service === false) {
    //       let sideBardata = newSidebarConfig.filter(
    //         (item) => item.title !== "RA Leads"
    //       );
    //       setNewSidebarConfig(sideBardata);
    //     }
    //     if (res.payload?.resource_augmentation_service === true) {
    //       setNewSidebarConfig(sidebarConfig);
    //     }
    //   }
    // });
  }, [dispatch]);

  // useEffect(() => {
  //   if (ra_enabled) {
  //     setNewSidebarConfig(sidebarConfig);
  //   } else {
  //     setNewSidebarConfig(
  //       sidebarConfig.filter((item) => item.title !== "RA Leads")
  //     );
  //   }
  // }, [ra_enabled]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        position: "relative",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/dashboard/myProfile">
          <AccountStyle>
            <Avatar src={user.profileUrl} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {capitalCase(user.fullName)}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {capitalCase(profileData.brand_name || "")}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      {/* <NavSection navConfig={sidebarConfig} /> */}
      <NavSection navConfig={newSidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: "relative",
            bgcolor: "grey.200",
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/support.svg"
            sx={{ width: 100, position: "absolute", top: -50 }}
          />

          <Box sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6">
              Need Help?
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Get 24/7 support
            </Typography>
          </Box>

          <Button
            fullWidth
            onClick={() => {
              navigate("/dashboard/contact_us");
            }}
            variant="contained"
          >
            Contact Us
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
