import React from "react";

import { useStyles } from "../Section.theme";

function TextInput({ value, change, styles, addRowExtra = {}, ...rest }) {
  const { icon, onClick } = addRowExtra;
  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <input
        className={classes.inputElement}
        value={value}
        onChange={(e) => change(e.target.value)}
        style={styles}
      ></input>
      {icon && (
        <div
          style={{
            position: "absolute",
            right: "3px",
            top: "10px",
            cursor: "pointer",
          }}
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              onClick(rest);
            }}
          >
            {icon}
          </span>
        </div>
      )}
    </div>
  );
}

export default TextInput;
