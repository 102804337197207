// routes
import NewRoutes from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Toast from "./components/Toast/Toast";
import ReactTour from "./components/Tour/ReactTour";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// ----------------------------------------------------------------------

export default function App() {
  // const flow = useGuidedFlow();

  return (
    <ThemeConfig>
      <Router>
        {/* {flow.isGuideOpen && <ReactTour />} */}
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <NewRoutes />
        <Toast />
      </Router>
    </ThemeConfig>
  );
}
