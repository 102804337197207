import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
  Tooltip,
  Avatar,
  Paper,
} from "@mui/material";
import clsx from "clsx";
import { useStyles } from "./Overview.theme";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import MyDropzone from "./../../app/DropZone";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectThunk,
  uploadProjectAttachmentThunk,
} from "../../../../store/slices/project/project.slice";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { capitalCase } from "../../../../utils/change-case";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { green } from "@mui/material/colors";

const Overview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const fileList = useSelector(
    ({ project }) => project.selectedProject.attachments
  );

  const data = useSelector(({ project }) => project.selectedProject);

  const addAttachment = (file) => {
    const formData = new FormData();
    file.file.forEach((ele) => {
      formData.append("file", ele);
    });
    dispatch(
      uploadProjectAttachmentThunk({
        id: params.id,
        file: formData,
      })
    );
  };

  function getFileIcon(ext) {
    ext = ext.toLowerCase();
    if (["jpg", "png", "gif", "jpeg"].includes(ext))
      return <PhotoLibraryIcon />;
    if (["pdf"].includes(ext)) return <PictureAsPdfIcon />;
    if (["doc", "docx"].includes(ext)) return <DescriptionIcon />;
  }

  function getInitials(project_name) {
    if (project_name?.toLowerCase() === "bot") return project_name;
    return capitalCase(project_name?.substring(0, 2));
  }

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card mb={5}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              gutterBottom
              variant="h5"
              sx={{ marginBottom: 0, fontSize: { xs: "16px", sm: "16px" } }}
            >
              Project Details
            </Typography>
            <Box
              component="span"
              display="inline"
              className={clsx(classes.badge_blue, classes.badge)}
              sx={{ fontSize: { xs: "16px", sm: "16px" } }}
            >
              {capitalCase(data?.project_status)}
            </Box>
          </Stack>
          <Typography
            variant="body1"
            sx={{ color: "#a4b0be", fontWeight: "600", paddingBottom: "10px" }}
          >
            Description
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              textAlign: "justify",
              fontSize: { xs: "14px", sm: "14px" },
            }}
          >
            {data?.description}
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  color: "#a4b0be",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
                mt={2}
              >
                Services
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Stack direction="row" mb={1} spacing={1}>
                {data?.services?.map((item) => {
                  return (
                    <Paper
                      sx={{
                        // background: "#f3f6f8",
                        color: "text.secondary",
                        borderRadius: "5px",
                        border: "2px solid #f3f6f8",
                        fontWeight: "400",
                        fontSize: "0.875rem",
                        lineHeight: 1.43,
                        letterSpacing: "0.01071em",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {item?.service_name}
                    </Paper>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                component="div"
                mt={3}
                display="flex"
                sx={{
                  flexDirection: "row",
                }}
                className={classes.dateInMobile}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ width: "300px" }}
                  justifyContent="space-start"
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "16px" },
                      color: "#a4b0be",
                      fontWeight: "600",
                    }}
                  >
                    <AccessTimeIcon sx={{ fontSize: "14px" }} /> Created Date:
                  </Typography>
                  <Typography
                    variant="body2"
                    ml={1}
                    sx={{
                      paddingRight: "1rem",
                      color: "text.secondary",
                      fontSize: { xs: "14px" },
                    }}
                  >
                    {" "}
                    {DateTime.fromISO(data?.created_at).toFormat("DD")}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ width: "300px" }}
                  justifyContent="space-start"
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "16px" },
                      color: "#a4b0be",
                      fontWeight: "600",
                    }}
                  >
                    <AccessTimeIcon sx={{ fontSize: "14px" }} /> Due Date:
                  </Typography>
                  <Typography
                    variant="body2"
                    ml={1}
                    sx={{ color: "text.secondary", fontSize: { xs: "14px" } }}
                  >
                    {" "}
                    {data?.expected_closing_date
                      ? DateTime.fromISO(data?.expected_closing_date).toFormat(
                          "DD"
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Typography
            variant="body1"
            display="block"
            mt={5}
            sx={{
              fontSize: { xs: "16px" },
              color: "#a4b0be",
              fontWeight: "600",
            }}
          >
            Attachment
          </Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ margin: "20px 50px 50px 0" }}
            >
              <MyDropzone addAttachment={addAttachment} />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            {fileList &&
              fileList.map((file) => (
                <Grid item xs={12} sm={12} md={4}>
                  <Box component="div" className={classes.attachFile}>
                    <Box component="div" className={classes.attachMedia}>
                      <Box component="span" className={classes.attachIcon}>
                        {getFileIcon("png")}
                      </Box>
                      <Box component="div" ml={2} sx={{ position: "relative" }}>
                        <a
                          href={file.url}
                          target="_blank"
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          <Typography
                            variant="body2"
                            component="h4"
                            className={classes.attachFileHeading}
                            sx={{ fontWeight: "600" }}
                          >
                            {file.filename}
                          </Typography>
                        </a>

                        <Typography
                          variant="caption"
                          sx={{ color: "text.secondary" }}
                        >
                          {DateTime.fromISO(file.timestamp).toFormat("DD")}
                        </Typography>
                        {/* <Box
                          varient="span"
                          component="span"
                          className={classes.closeFile}
                        >
                          <HighlightOffIcon sx={{ fontSize: "17px" }} />
                        </Box> */}
                      </Box>
                      <Box>
                        <LightTooltip title={file.name} placement="top" arrow>
                          <Avatar
                            sx={{
                              fontSize: ".8rem",
                              fontWeight: "600",
                              backgroundColor: "#f3f6f8",
                              color: "#637281",
                            }}
                          >
                            {getInitials(file.name)}
                          </Avatar>
                        </LightTooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Overview;
