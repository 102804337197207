import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllVendorServicesHandler,
  searchVendorServicesHandler,
  deleteVenodrServicesHandler,
  addVendorServicesHandler,
  updateVendorServicesHandler,
  createCustomServiceHandler,
  fetchAllProductCodesHandler,
  createProductCodesHandler,
  deleteProductCodesHandler,
} from "./vendorservice.thunk";

const INIT_STATE = {
  vendorServicedata: [],
  totalServices: 0,
  isLoading: false,
  productCodeList: [],
};

export const fetchAllVendorSericesThunk = createAsyncThunk(
  "fetchAllVendorServices",
  fetchAllVendorServicesHandler
);

export const searchVendorSericesThunk = createAsyncThunk(
  "searchVendorServices",
  searchVendorServicesHandler
);

export const AddVendorSericesThunk = createAsyncThunk(
  "addVendorService",
  addVendorServicesHandler
);

export const updateVenorSericesThunk = createAsyncThunk(
  "updateVendorService",
  updateVendorServicesHandler
);

export const deleteVendorSericesThunk = createAsyncThunk(
  "deleteVendorService",
  deleteVenodrServicesHandler
);

export const createCustomServiceThunk = createAsyncThunk(
  "createCustomService",
  createCustomServiceHandler
);

//pproduct code thunk
export const fetchAllProductCodeThunk = createAsyncThunk(
  "fetchAllProductCode",
  fetchAllProductCodesHandler
);

export const createProductCodeThunk = createAsyncThunk(
  "createProductCode",
  createProductCodesHandler
);

export const deleteProductCodeThunk = createAsyncThunk(
  "deleteProductCode",
  deleteProductCodesHandler
);

const venodrserviceSlice = createSlice({
  name: "vendorServices",
  initialState: INIT_STATE,
  reducers: {
    startVendorServiceLoader: (state) => {
      state.isLoading = true;
    },
    stopVendorServiceLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVendorSericesThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllVendorSericesThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendorServicedata = action.payload.data;
        state.totalServices = action.payload.total;
      })
      .addCase(fetchAllVendorSericesThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(searchVendorSericesThunk.fulfilled, (state, action) => {
        state.vendorServicedata = action.payload.data;
        state.totalServices = action.payload.total;
        return state;
      })
      .addCase(createCustomServiceThunk.fulfilled, (state, action) => {
        // state.vendorServicedata.push(action.payload);
      })
      .addCase(updateVenorSericesThunk.fulfilled, (state, action) => {
        // const idx = state.vendorServicedata.findIndex(
        //   (ele) => ele._id === action.payload._id
        // );
        // state.vendorServicedata[idx] = action.payload;
        // return state;
      })
      .addCase(deleteVendorSericesThunk.fulfilled, (state, action) => {
        // const id = action.payload;
        // const newServiceData = state.vendorServicedata.filter(
        //   (ele) => ele._id !== id
        // );
        // state.vendorServicedata = newServiceData;
        // return state;
      })
      //product code info
      .addCase(fetchAllProductCodeThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllProductCodeThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productCodeList = action.payload.data;
      })
      .addCase(fetchAllProductCodeThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createProductCodeThunk.fulfilled, (state, action) => {})
      .addCase(deleteProductCodeThunk.fulfilled, (state, action) => {});
  },
});

export const { startVendorServiceLoader, stopVendorServiceLoader } =
  venodrserviceSlice.actions;

export default venodrserviceSlice.reducer;
