import React, { useEffect } from "react";
import { pdfjs } from "react-pdf";

import { Container, Stack, Typography } from "@mui/material";
import CustomTabs from "./../../common/Tabs";
import Breadcrumbs from "./../../Breadcrumb/Breadcrumb";
import Page from "../../Page";
import { capitalCase } from "../../../utils/change-case";
import {
  milestonePath,
  overviewPath,
  proposalPath,
  scopePath,
  supportPath,
  tablePath,
} from "../../../utils/constant";
import { Outlet } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProjectThunk } from "../../../store/slices/project/project.slice";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const labels = [
  {
    label: "Overview",
    value: overviewPath,
  },
  {
    label: "Contract",
    value: proposalPath,
  },
  {
    label: "Project Table",
    value: tablePath,
  },
  {
    label: "Scope",
    value: scopePath,
  },
  {
    label: "Support",
    value: supportPath,
  },
  {
    label: "Milestone",
    value: milestonePath,
  },
];

const ClientRequirements = () => {
  const data = useSelector(({ project }) => project);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getProjectThunk(params));
  }, [dispatch, params]);

  return (
    <Page title="Project Requirement | EMB">
      <Container sx={{ margin: 0 }}>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {capitalCase(data?.selectedProject?.project_name)}
          </Typography>
          <Breadcrumbs />
        </Stack>
        <CustomTabs labels={labels} />
        <div style={{ marginBottom: "20px" }}></div>
        <Outlet />
      </Container>
    </Page>
  );
};

export default ClientRequirements;
