import {
  getAllVendorServices,
  searchVendorServiceApi,
  deleteVendorServices,
  addVendorServices,
  addCustomServiceApi,
  getAllProductCodeListApi,
  productCodeApi,
} from "../../../utils/apis.utils";
import {
  startVendorServiceLoader,
  stopVendorServiceLoader,
  fetchAllVendorSericesThunk,
  fetchAllProductCodeThunk,
} from "../vendorServices/vendorservice.slice";
import { createQueryString, showToastAlert } from "../../../utils/global/global";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { requestTypeEnum, tabsNameEnum } from "../../../utils/enum";

export const fetchAllVendorServicesHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  thunkApi.dispatch(startVendorServiceLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(getAllVendorServices + query);
  thunkApi.dispatch(stopVendorServiceLoader());
  return {
    data: response.data,
    total: response.total,
  };
};

export const searchVendorServicesHandler = async (
  { page = 1, search },
  thunkApi
) => {
  const { vendor } = thunkApi.extra.apiService;
  const query = createQueryString({
    page,
    bus_profile: search,
  });
  const response = await vendor.get(searchVendorServiceApi + query);
  return {
    data: response.data,
    total: response.total,
  };
};

export const deleteVenodrServicesHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startVendorServiceLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(deleteVendorServices + "/" + data.id);
    thunkApi.dispatch(stopVendorServiceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllVendorSericesThunk(data.page));
    // return id;
  } catch (err) {
    thunkApi.dispatch(stopVendorServiceLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopVendorServiceLoader());
  }
};

export const addVendorServicesHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startVendorServiceLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(addVendorServices, data);

    thunkApi.dispatch(stopVendorServiceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );

    thunkApi.dispatch(fetchAllVendorSericesThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopVendorServiceLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopVendorServiceLoader());
  }
};

export const updateVendorServicesHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    data: data.data,
  };

  try {
    thunkApi.dispatch(startVendorServiceLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(addVendorServices + "/" + id, apiData);
    thunkApi.dispatch(stopVendorServiceLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllVendorSericesThunk());
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopVendorServiceLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopVendorServiceLoader());
  }
};

export const createCustomServiceHandler = async function (data, thunkApi) {
  try {
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(addCustomServiceApi, data.sendData);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllVendorSericesThunk(data.page));
    // return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

//pproduct code
export const fetchAllProductCodesHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  thunkApi.dispatch(startVendorServiceLoader());
  const { profile } = thunkApi.extra.apiService;
  const response = await profile.get(getAllProductCodeListApi + query);
  thunkApi.dispatch(stopVendorServiceLoader());
  return {
    data: response.data,
    total: response.total,
  };
};

export const createProductCodesHandler = async ({ data, pastData, id }, thunkApi) => {
  try {
    if (!data.minimum_price) {
      data.minimum_price = null;
    }
    var response = null;
    const { profile } = thunkApi.extra.apiService;
    if (id) {
      response = await profile.put(`${productCodeApi}/${id}`, {data: data});
    } else {
      response = await profile.post(productCodeApi, {data: data});
    }
    // const { check } = thunkApi.extra.apiService;
    // if (id) {
    //   response = await profile.put(`${productCodeApi}/${id}`, {
    //     data: {
    //       data: data,
    //       past_data: pastData,
    //     },
    //     type: requestTypeEnum.edit,
    //     meta: tabsNameEnum.ProductCode,
    //   });
    // } else {
    //   response = await profile.post(productCodeApi, {
    //     data: {
    //       data: data
    //     },
    //     type: requestTypeEnum.new,
    //     meta: tabsNameEnum.ProductCode,
    //   });
    // }
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllProductCodeThunk(data.page));
  } catch (err) {
    // thunkApi.dispatch(showFailureToast({ message: err }));
    showToastAlert(err, thunkApi)
    return Promise.reject(err);
  }
};

export const deleteProductCodesHandler = async ({ id, page, currentData }, thunkApi) => {
  try {
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.delete(productCodeApi + "/" + id);
    // const { check } = thunkApi.extra.apiService;
    // const response = await profile.delete(productCodeApi + "/" + id, {
    //   data: {
    //     data: {
    //       data: currentData,
    //     },
    //     meta: tabsNameEnum.ProductCode,
    //     type: requestTypeEnum.delete,
    //   }
    // });
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllProductCodeThunk(page));
    return id;
  } catch (err) {
    // thunkApi.dispatch(showFailureToast({ message: err }));
    showToastAlert(err, thunkApi)
    return Promise.reject(err);
  }
};
