import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Button } from "@mui/material";
import useForm from "../../hooks/useForm";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { augmentationOptions } from "../../utils/options.utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { BenchStrengthValidation } from "../../utils/validations/vendor.validation";
import SingleSelectSearchIndustry from "../../components/Modal/singleSelectSearchIndustry";
import SearchCountry from "../../components/Modal/SearchCountry";
import { useDispatch } from "react-redux";
import { createBenchStrengthThunk } from "../../store/slices/vendor/vendor.slice";
import {
  benchResourceBifurcationHandler,
  benchStrengthLocationHandler,
  benchStrengthTechstackHandler,
  countryFormatter,
  deliveryclassFormatter,
  techstackFormatter,
} from "../../utils/global/vendor.global";
import useResourceBifercation from "../../hooks/useBifercation";
import useTeckStack from "../../hooks/useTechStack";

const BenchStrength = ({ closeModal, singleBenchStrengthDataInfo }) => {
  const dispatch = useDispatch();

  const [resourceBifercation, getObj] = useResourceBifercation();
  const [techStack, setTeckStack, getTechStackObj] = useTeckStack(
    singleBenchStrengthDataInfo?.resource_bifurcation?.id
  );

  // console.log("techStack: ",techStack);

  const [showLocationField, setShowLocationField] = useState(false);

  const onSubmit = (data) => {
    const { resource_value, location, tech_stack, experience } = data ?? {};

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "resource_value") {
        formData.append(
          "resource_bifurcation",
          JSON.stringify(getObj(resource_value))
        );
      } else if (key === "location") {
        formData.append(
          "locations",
          location?.map((item) => item?.location || item)?.toString()
        );
      } else if (key === "tech_stack") {
        formData.append(
          "tech_stack",
          JSON.stringify(getTechStackObj(tech_stack))
        );
      } else if (key === "experience") {
        formData.append("experience", experience);
      } else {
        formData.append(key, data[key]);
      }
    });

    if (singleBenchStrengthDataInfo) {
      dispatch(
        createBenchStrengthThunk({
          data: formData,
          id: singleBenchStrengthDataInfo?.id,
        })
      )
        // .unwrap()
        .then((res) => {
          // console.log(res, "qwerty");
          if (res.type === "createBenchStrength/fulfilled") {
            closeModal();
          }
        });
    } else {
      dispatch(createBenchStrengthThunk({ data: formData }))
        // .unwrap()
        // .then(closeModal());
        .then((res) => {
          // console.log(res, "qwerty");
          if (res.type === "createBenchStrength/fulfilled") {
            closeModal();
          }
        });
    }
  };
  const {
    candidate_name,
    resource_bifurcation,
    experience,
    tech_stack,
    team_model,
    cost_per_month,
    locations,
    cv_file_url,
    cv_file,
    certifications,
  } = singleBenchStrengthDataInfo ?? {};

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: {
        candidate_name: candidate_name,
        resource_value: resource_bifurcation?.id,
        experience: experience,
        tech_stack: tech_stack?.id,
        team_model: team_model,
        cost_per_month: cost_per_month,
        location: locations ?? [],
        // cv: cv_file_url,
        // certifications: certifications?.map((item) => item.url) ?? [],
        cv: cv_file,
        certifications: certifications ? certifications[0] : {},
      },
      onSubmit: onSubmit,
      validationSchema: BenchStrengthValidation,
    });

  console.log(values, singleBenchStrengthDataInfo, "values");

  function handleResourceChange(e) {
    const id = e.target.value;
    setTeckStack(id);
    handleChange({
      target: {
        name: `resource_value`,
        value: id,
      },
    });
  }

  useEffect(() => {
    if (values.team_model === "on_site") setShowLocationField(true);
    else setShowLocationField(false);
  }, [values.team_model]);

  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={2}>
          <Grid item md={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} p={3} alignItems={"end"}>
                <Grid item md={12} p={0}>
                  <Element
                    label="Candidate Name"
                    inputProps={{
                      name: "candidate_name",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.candidate_name}
                    errorText={touched.candidate_name && errors.candidate_name}
                    eletype={inputType.input}
                  />
                </Grid>

                <Grid item md={12} pb={1}>
                  <Element
                    label="Resource Bifurcation"
                    inputProps={{
                      name: "resource_value",
                      onChange: handleResourceChange,
                      onBlur: handleBlur,
                    }}
                    value={values.resource_value}
                    options={resourceBifercation}
                    errorText={touched.resource_value && errors.resource_value}
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: 20,
                          bottom: 24,
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12} pb={1}>
                  <Element
                    label="Tech Stack"
                    inputProps={{
                      name: "tech_stack",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.tech_stack}
                    options={techStack}
                    errorText={touched.tech_stack && errors.tech_stack}
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{
                          position: "absolute",
                          right: 20,
                          bottom: 24,
                        }}
                      />
                    }
                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <Element
                    label="Experience"
                    inputProps={{
                      name: "experience",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.experience}
                    errorText={touched.experience && errors.experience}
                    eletype={inputType.number}
                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <Element
                    label="Team Model"
                    inputProps={{
                      name: "team_model",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.team_model}
                    options={augmentationOptions}
                    errorText={touched.team_model && errors.team_model}
                    eletype={inputType.select}
                    icons={
                      <ArrowDropDownIcon
                        sx={{ position: "absolute", right: 20, bottom: 24 }}
                      />
                    }
                  />
                </Grid>

                {showLocationField && (
                  <Grid item md={12} p={0}>
                    <SearchCountry
                      name="location"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Location"
                      placeholder="Select Location"
                      formatter={countryFormatter}
                      value={values.location}
                      errorText={touched.location && errors.location}
                      handler={benchStrengthLocationHandler}
                    />
                  </Grid>
                )}

                <Grid item md={12} p={0}>
                  <Element
                    label="Cost per month"
                    inputProps={{
                      name: "cost_per_month",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.cost_per_month}
                    errorText={touched.cost_per_month && errors.cost_per_month}
                    eletype={inputType.input}
                  />
                </Grid>

                <Grid item md={12} p={0}>
                  <Element
                    label="CV Upload"
                    inputProps={{
                      name: "cv",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.cv}
                    errorText={touched.cv && errors.cv}
                    eletype={inputType.file}
                    fileUploadWithoutUrl={true}
                  />
                </Grid>

                {/* <Grid item md={12} p={0}>
                  <Element
                    label="Certification Upload"
                    inputProps={{
                      name: "certifications",
                      onChange: handleChange,
                      onBlur: handleBlur,
                    }}
                    value={values.certifications}
                    eletype={inputType.file}
                    fileUploadWithoutUrl={true}
                    // multiple={true}
                  />
                </Grid> */}

                <Grid item md={12} p={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ float: "right" }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default BenchStrength;
