import React, { useState } from "react";
import { Container, Typography } from "@mui/material";
import LeadsMenu from "../components/_dashboard/leads/LeadsMenu";
import LeadsTable from "../components/_dashboard/leads/LeadsTable";
import LeadsTableAccepted from "../components/_dashboard/leads/LeadsTableAccepted";
import LeadsTableRejected from "./../components/_dashboard/leads/LeadsTableRejected";
import Page from "../components/Page";
import withBusinessProfile from "../hoc/withBusinessProfile";
import { leadsType } from "../utils/enum";

const Leads = () => {
  const [status, setStatus] = useState(leadsType.pending);

  return (
    <Page title="Leads | EMB">
      <Container sx={{ margin: 0 }}>
        <Typography variant="h4" gutterBottom>
          {/* Leads */}
        </Typography>
        {/*<Box sx={{marginTop:"170px"}}>
        <SearchNotFound
          src="/static/no-leads.svg"
          title="Coming Soon !"
          subtitle="Leads page is in underconstruction. You will soon see your project leads."
        />
        </Box>*/}
        <LeadsMenu setStatus={setStatus} status={status} />
        {status === leadsType.pending && <LeadsTable />}
        {status === leadsType.approved && <LeadsTableAccepted />}
        {status === leadsType.rejected && <LeadsTableRejected />}
      </Container>
    </Page>
  );
};

export default withBusinessProfile(Leads);
