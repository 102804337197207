import React from "react";
import { Grid, Typography, Stack, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";

function BusinessNotFound({
  width = "50%",
  src = "/static/icons/spaceship.png",
  title = "You haven't created your Business Profile yet.",
}) {

  const navigate = useNavigate();

  function navigateToProfilePage() {
    navigate("/dashboard/myProfile");
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Box p={5}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              background: "#f3f6f8",
              pl: "4",
              borderRadius: "20px",
              height: "200px",
              marginRight: "20px",
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ padding: "30px 0px" }}
            >
              <LockIcon
                sx={{
                  fontSize: "3rem!important",
                  color: "#c6c9cb",
                }}
              />
              <Typography variant="h6">
                Complete your profile to view this section
              </Typography>

              <Button onClick={navigateToProfilePage} variant="contained">
                Complete Profile
              </Button>
            </Stack>
          </Grid>
          <Box>
            <img src={src} style={{ width: width, margin: "0 auto" }} alt="" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default BusinessNotFound;
