import axios from "axios";
import { getAccessToken, getCookiesToken } from "../global/auth.global";
import { setUserAsLogin } from "../../store/slices/auth/auth.slice";
import { useDispatch } from "react-redux";


const BASE_URL = process.env.REACT_APP_ACCOUNT_DOMAIN;

export const protectedAuthAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

protectedAuthAxios.interceptors.request.use((request) => {
  const token = getAccessToken();

  // if (!token) {
  //   const cookiesToken = getCookiesToken();

  //   //if we get the access token then we update the global state to loggedin
  //   // if (cookiesToken) {
  //   //   const dispatch = useDispatch()
  //   //   dispatch(setUserAsLogin());
  //   // } 

  //   request.headers["Authorization"] = token ? "Bearer " + cookiesToken : "";
  //   return request;
  // }

  request.headers["Authorization"] = token ? "Bearer " + token : "";
  return request;
});

protectedAuthAxios.interceptors.response.use(
  (response) => {
    if (response.data?.status === 1) return response.data;
    return Promise.reject(response.data?.err);
  },
  (error) => {
    const { response } = error;

    const message = response?.data?.err || "Something Went Wrong";
    return Promise.reject(message);
  }
);
