import React from "react";
import {
  Typography,
  Container,
  Box,
  Card,
  Stack,
  InputBase,
  Grid,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import SearchIcon from "@mui/icons-material/Search";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAccordion from "../../hooks/useAccordian";
import useDebounceSearch from "../../hooks/useDebounceSearch";
import Page from "../../components/Page";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "600px",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Faq() {
  const { handleChange, accordianState } = useAccordion(faqs.length, 1);
  const { data, handler } = useDebounceSearch({
    data: faqs,
    debounceTime: 500,
    predicate: (data, value) =>
      data.question.toLowerCase()?.includes(value.toLowerCase()),
  });

  return (
    <div>
      <Page title="FAQ's | EMB">
        <Container sx={{ margin: 0 }}>
          <Typography variant="h4">FAQ's</Typography>
          {/* <Box mt={5}>
            <Card
              sx={{
                padding: "6rem",
                backgroundColor: "#f3f6f8",
                backgroundImage:
                  // "url(https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/banner.c2d23a97.png)",
                  "url(/static/faq-01.svg)",
                backgroundPosition: "center",
                backgroundSize: "cover",
                boxShadow: "0 4px 24px 0 rgb(34 41 47 / 10%)",
              }}
            >
              <Stack alignItems="center" justifyContent="center">
                <Typography variant="h4" sx={{ color: "#11a54a" }}>
                  Let's answer some questions
                </Typography>
                <Typography>
                  or choose a category to quickly find the help you need
                </Typography>

                <Box sx={{ marginTop: "20px" }}>
                  <Search
                    sx={{
                      background: "#fff",
                      "&:hover": {
                        background: "#fff",
                      },
                      borderRadius: "50px",
                      boxShadow:
                        "0 2px 6px 0 rgb(136 148 171 / 20%), 0 24px 20px -24px rgb(71 82 107 / 10%)",
                    }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      sx={{ width: "100%", height: "60px" }}
                      onChange={(e) => handler(e.target.value)}
                    />
                  </Search>
                </Box>
              </Stack>
            </Card>
          </Box> */}

          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box pt={5}>
                <Card>
                  <Box p={3}>
                    <Typography variant="h5">
                      Frequently Ask Questions
                    </Typography>
                    <Box pt={4}>
                      {data.map((item) => (
                        <Accordion
                          expanded={accordianState[item.id]}
                          onChange={() => handleChange(item.id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>
                              {/* <ContactSupportIcon
                                sx={{
                                  marginBottom: "-6px",
                                  marginRight: "5px",
                                  opacity:.75,
                                }}
                              /> */}
                              <Stack direction="row" alignItems="center">
                                <Box
                                  sx={{
                                    marginLeft: "-20px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <img
                                    src={"/static/icons/faq.svg"}
                                    width={30}
                                    height={30}
                                    alt=""
                                  />
                                </Box>
                                <Box>{item.question}</Box>
                              </Stack>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography sx={{ marginLeft: "20px" }}>
                              {item.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </div>
  );
}

var faqs = [
  {
    id: 0,
    question:
      "Are any advance payments made to the vendor after deal finalization?",
    answer:
      "No. We follow a milestone-based system wherein payments are released to the vendor after a defined stage of progress or milestone has been achieved and approved by the client. Milestones depend on the predefined structure of your contract.",
  },
  {
    id: 1,
    question: "Do we outsource the project to you?",
    answer:
      "No, you will handle the clients in your own name. We act as the procurement and fulfillment partners. Our job is to facilitate vendor-client communication and ensure that projects are delivered within the stipulated timeline. ",
  },
  {
    id: 2,
    question: "Are there any charges for subscribing to your platform?",
    answer: "No, we don’t charge any subscription or sign-up fee.",
  },
  {
    id: 3,
    question: "Do you assign one consultant to one vendor?",
    answer:
      "No. We assign consultants on a project-to-project basis. Meaning, different consultants will contact you for the project that they have been assigned.  ",
  },
  {
    id: 4,
    question: "Do you have freelancers on your platform?",
    answer:
      "No. We only have verified agencies that offer digital services in online marketing, web development, app development, etc.  ",
  },
  {
    id: 5,
    question: "Do you give closed deals/converted clients to vendors?",
    answer:
      "No. We shortlist vendors for our clients based on their requirements, post which a vendor and client meeting is set up. Deals are closed only after the client chooses a vendor, based on their assessment of the vendor’s past work and meeting interactions.",
  },
];
