import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../../Scrollbar";
// import UserUpdate from "./UserUpdate";
import {
  Card,
  Table,
  Stack,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Button,
  TableFooter,
  TablePagination,
  Chip,
} from "@mui/material";
import _ from "lodash";
import { css } from "@emotion/css";
import Modal from "../../Modal/Modal";
import { Link } from "react-router-dom";
import ListHead from "../../common/ListHead";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { capitalCase } from "../../../utils/change-case";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import { fetchAllUsersHandler } from "../../../utils/global/user.global";
import useUser from "../../../hooks/useUser";
// import { roles } from "../../../utils/enum";
import { useSelector } from "react-redux";
import { createQueryString } from "../../../utils/global/global";
import { createSearchParams } from "react-router-dom";

const TABLE_HEAD = [
  { id: "project_name", label: "Project Name", alignRight: false },
  { id: "sku_name", label: "SKU ", alignRight: false },
  { id: "created_at", label: "Date", alignRight: false },
  { id: "spoc_name", label: "Sales SPOC", alignRight: false },
  {
    id: "status",
    label: "Status",
    alignRight: false,
  },
  
];

function getInitials(project_name) {
  return capitalCase(project_name.substring(0, 2));
}


const ProjectTable = ({
  searchValue,
  data,
  projectStat,
  search,
  page,
  status,
  searchedUser,
}) => {
  const navigate = useNavigate();
  const [showProjectModal, setShowProjectModal] = useState(false);
  // let { projects, totalProjects } = useSelector(({ project }) => project);
  let { leads, totalLeads, projectLimit } = useSelector(
    ({ projectleads }) => projectleads
  );
  const [allUsers, setAllUsers] = useState([]);
  const handleOpen = (project) => {
    setSelectedProject(project);
    setShowProjectModal(true);
  };
  const handleClose = () => setShowProjectModal(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const user = useUser();
  // const columnsAvailable = user.role === roles.admin ? 8 : 7;

  useEffect(() => {
    // fetchAllUsersHandler().then((response) => {
    //   setAllUsers(response.data);
    // });
  }, []);

  function fncPageNavigation(id) {
    var query = createQueryString({
      search: search,
      page: page,
      status: status,
      searchedUser: searchedUser,
    });
    var data = {
      search: search,
      page: page,
      status: status,
      searchedUser: searchedUser,
      isNew: 0,
    };
    navigate(`/dashboard/leads/${id}/overview`);
    // navigate({
    //   pathname: `/dashboard/projects/${id}/overview`,
    //   search: `?${createSearchParams(data)}`,
    // });
  }

  return (
    <div>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={leads?.length}
                styles={{ backgroundColor: "rgb(243, 246, 248)" }}
              />
              <TableBody>
                {leads?.map((row) => {
                  const {
                    deal_id,
                    project_name,
                    sku_name,
                    created_at,
                    spoc_name,
                    status,
                    
                  } = row;

                  return (
                    <TableRow
                      key={deal_id}
                      tabIndex={-1}
                      role="checkbox"
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Avatar
                            sx={{
                              bgcolor: "#eff8f1",
                              color: "#00aa55",
                              fontSize: ".9rem",
                              fontWeight: "600",
                              marginLeft: "20px",
                            }}
                          >
                            {getInitials(project_name)}
                          </Avatar>
                          <a
                            onClick={() => fncPageNavigation(deal_id)}
                            className={css`
                              text-decoration: none;
                              color: black;
                            `}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {project_name}
                            </Typography>
                          </a>
                        </Stack>
                      </TableCell>

                      

                      <TableCell align="left" style={{ minWidth: "300px" }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <a
                            onClick={() => fncPageNavigation(deal_id)}
                            className={css`
                              text-decoration: none;
                              color: black;
                            `}
                          >
                            {sku_name}
                          </a>
                        </Stack>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: "150px" }}>
                      {DateTime.fromISO(created_at).toFormat("DD")}
                      </TableCell>
                     
                      <TableCell align="left" style={{ minWidth: "150px" }}>
                        {spoc_name}
                      </TableCell>
                      <TableCell align="left">
                        <Chip
                          size="small"
                          variant="outlined"
                          label={status}
                          sx={{
                            padding: 1,
                            fontSize: "12px",
                            border: "none",
                            textTransform: "capitalize",
                            color:
                              status === "Active"
                                ? "#63A15D"
                                : "#EA5151",
                          }}
                        />
                      </TableCell>
                      
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>

    
    </div>
  );
};

export default ProjectTable;
