import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllLeadsHandler,
  fetchLeadsDetailsHandler,
  vendorUploadProposalApiHandler,
} from "./projectleads.thunk";
const INIT_STATE = {
  projectPagination: {
    query_term: "",
    type: "all",
    page: 1,
    spoc: -1,
  },
  leads: [],
  totalLeads: [],
  projectLimit: 50,
  isLoading: false,
  leadsOverviewAndProposal: [],
};
export const fetchLeadsDetailsThunk = createAsyncThunk(
  "fetchLeadsDetails",
  fetchLeadsDetailsHandler
);
export const vendorUploadProposalApiThunk = createAsyncThunk(
  "vendorUploadProposalApi",
  vendorUploadProposalApiHandler
);
export const fetchAllProjectLeadsThunk = createAsyncThunk(
  "fetchAllProjectsLeads",
  fetchAllLeadsHandler
);
const projectLeadsSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    startProjectLoader: (state) => {
      state.isLoading = true;
    },
    stopProjectLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProjectLeadsThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllProjectLeadsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leads = action.payload.data;
        state.totalLeads = action.payload.total;
      })
      .addCase(fetchLeadsDetailsThunk.fulfilled, (state, action) => {
        state.leadsOverviewAndProposal = action.payload;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchLeadsDetailsThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchLeadsDetailsThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(vendorUploadProposalApiThunk.fulfilled, (state, action) => {
        return state;
      })
      .addCase(vendorUploadProposalApiThunk.pending, (state) => {
        return state;
      })
      .addCase(vendorUploadProposalApiThunk.rejected, (state) => {
        return state;
      });
  },
});
export default projectLeadsSlice.reducer;
