/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState, useMemo } from "react";
import { Box, Grid, Button, Stack, Card, Container } from "@mui/material";
import { inputType } from "../../utils/enum";
import { resourceTypesOption } from "../../utils/options.utils";
import Element from "../../components/Form/Element";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Typography from "@mui/material/Typography";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react/dist/iconify.js";
import { changeStringToNum } from "../../hooks/changeStringToNum";
// import {
//   createExpertisebreakupThunk,
//   deleteExpertisebreakupThunk,
//   fetchExpertisebreakupThunk,
// } from "../../store/slices/vendor/vendor.slice";
import { deleteExpertiseThunk, fetchAllExpertiseThunk } from "../../store/slices/expertisebreakup/expertisebreakup.slice";
import ExpertiseModal from "../../components/Modal/ExpertiseModal";

export default function ExpertiseBreakup() {
  // const dispatch = useDispatch();
  // const { expertisebreakupList } = useSelector(({ vendor }) => vendor);

  // const handleClick = (data) => {
  //   const { maximum_value, minimum_value } = data ?? {};
  //   const respWithNumberCount = changeStringToNum(data?.infos, "count");
  //   const payload = {
  //     minimum_value,
  //     maximum_value,
  //     infos: respWithNumberCount,
  //   };
  //   dispatch(createExpertisebreakupThunk({ data: payload }));
  // };

  // const {
  //   values,
  //   errors,
  //   touched,
  //   handleChange,
  //   handleBlur,
  //   setFieldValue,
  //   handleSubmit,
  // } = useFormik({
  //   initialValues: {
  //     infos: [],
  //     minimum_value: expertisebreakupList?.[0]?.minimum_value ?? "",
  //     maximum_value: expertisebreakupList?.[0]?.maximum_value ?? "",
  //   },
  //   onSubmit: handleClick,
  // });

  // useEffect(() => {
  //   dispatch(fetchExpertisebreakupThunk());
  // }, [dispatch]);

  // useEffect(() => {
  //   setFieldValue("infos", expertisebreakupList?.[0]?.infos ?? []);
  // }, [expertisebreakupList]);

  // const addInputRow = () => {
  //   setFieldValue("infos", [
  //     ...values?.infos,
  //     {
  //       resource_bifurcation: values.resource_bifurcation,
  //       count: values.count,
  //     },
  //   ]);
  // };

  // const deleteInputRow = (item) => {
  //   const { id } = item ?? {};
  //   if (!id) {
  //     const remainValue = values?.infos?.filter(
  //       (_, i) => _?.resource_bifurcation !== item?.resource_bifurcation
  //     );
  //     setFieldValue("infos", remainValue);
  //   } else {
  //     dispatch(deleteExpertisebreakupThunk(id));
  //     setFieldValue("infos", expertisebreakupList?.[0]?.infos);
  //   }
  // };


  const { expertisedata, totalexpertise } = useSelector(
    ({ expertise }) => expertise
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [currentdata, setCurrentData] = useState("");
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    if (page !== 0) {
      dispatch(fetchAllExpertiseThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [expertisedata, dispatch, page]);

  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchAllExpertiseThunk(newPage + 1));
    setPage(newPage);
  };

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setCurrentData("");
    setShowModal(false);
  };

  function handleEdit(id) {
    var data = expertisedata.find((d) => d.id === id);
    setCurrentData(data);
    setTimeout(() => {
      setShowModal(true);
    }, 0);
  }
  function handleDelete(id) {
    dispatch(deleteExpertiseThunk({ id: id, page: page == 0 ? 1 : page + 1 }))
      .unwrap()
      .then((d) => {});
  }


  return (
    <>
      <Container>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Expertise BreakUp Info
          </Typography>
        </Stack>
        <Grid container spacing={2} mt={1}>
          <Grid item md={12}>
            {/* <Scrollbar>
                {expertisedata.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) =>
                            column.id != "action" ? (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  top: 57,
                                  minWidth: column.minWidth,
                                  backgroundColor: "#f3f6f8",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ) : (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  backgroundColor: "#f3f6f8",
                                  position: "sticky",
                                  right: 0,
                                }}
                              >
                                {column.label}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expertisedata.map((data) => (
                          <TableRow hover sx={{ cursor: "pointer" }}>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.tech_stack?.name}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.employees_1_3}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.employees_4_8}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {data.employees_8_more}
                            </TableCell>
                            <TableCell
                              onClick={() => handleEdit(data.id)}
                              component="th"
                              scope="row"
                            >
                              {DateTime.fromISO(data.created_at).toLocaleString(
                                DateTime.DATE_MED
                              )}
                            </TableCell>
                            <TableCell
                              style={{
                                position: "sticky",
                                right: 0,
                                background: "white",
                                boxShadow: "5px 2px 5px grey",
                                borderRight: "2px solid black",
                              }}
                            >
                              <DeleteAction
                                handleDelete={() => handleDelete(data.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter
                        style={{
                          display: "inline-block",
                          marginTop: "4rem",
                        }}
                      >
                        <TableRow>
                          <TablePagination
                            style={{
                              right: 0,
                              background: "white",
                              width: "100%",
                              position: "absolute",
                              bottom: 0,
                            }}
                            count={totalexpertise}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={false}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                ) : (
                  <SearchNotFound
                    src="/static/support.svg"
                    subtitle="There is currently no data to show."
                  />
                )}
              </Scrollbar> */}
            <ExpertiseModal
              closeModal={handleClose}
              props={currentdata}
              page={page === 0 ? 1 : page + 1}
            />
            {/* <Modal
              maxWidth="md"
              isOpen={showModal}
              closeModal={handleClose}
              title="Add Expertise Breakup Form"
              content={
                <ExpertiseModal
                  closeModal={handleClose}
                  props={currentdata}
                  page={page == 0 ? 1 : page + 1}
                />
              }
              dialogProps={{ fullWidth: true }}
              titleStyle={{ textAlign: "center", marginTop: 2 }}
              actionStyle={{ justifyContent: "center", marginBottom: 2 }}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Container>
    </>
  );
}
