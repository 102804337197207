import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  removeAccessToken,
  removeOrgId,
  storeAccessToken,
} from "../../../utils/global/auth.global";
// import * as CryptoJS from 'crypto-js'
// const Cryptr = require('cryptr');
import Cryptr from 'cryptr';

import {
  resendOTP,
  signInWithEmail,
  signInWithGoogle,
  signInWithPhone,
  signUpWithEmail,
  validateOTP,
  forgotPassword,
  sendMobileVerificationOtpHandler,
  sendEmailVerificationOtpHandler,
  validateOTPVerifyOnlyHandler,
} from "./auth.thunk";

const INIT_STATE = {
  isLoggedIn: false,
  isLoading: false,
  showOTPModal: false,
  showMobileModal: false,
  digitsInOTP: 4,
  mobileSession: {},
};

export const loginUserByGoogleAsyncThunk = createAsyncThunk(
  "googleLogin",
  signInWithGoogle
);

export const loginByEmailAsyncThunk = createAsyncThunk(
  "emailLogin",
  signInWithEmail
);

export const signupWithEmailAsyncThunk = createAsyncThunk(
  "emailSignup",
  signUpWithEmail
);

export const validateOTPAsyncThunk = createAsyncThunk(
  "validateOTP",
  validateOTP
);

export const resendEmailOTPAsyncThunk = createAsyncThunk(
  "resendEmailOTP",
  resendOTP
);

export const signInWithPhoneAsycnThunk = createAsyncThunk(
  "signInWithPhone",
  signInWithPhone
);

export const forgotPasswordAsycnThunk = createAsyncThunk(
  "forgotPassword",
  forgotPassword
);

export const sendEmailVerificationOtpThunk = createAsyncThunk(
  "sendEmailVerificationOtp",
  sendEmailVerificationOtpHandler
);

export const sendMobileVerificationOtpThunk = createAsyncThunk(
  "sendMobileVerificationOtp",
  sendMobileVerificationOtpHandler
);

export const validateOTPVerifyOnlyThunk = createAsyncThunk(
  "validateOTPVerifyOnly",
  validateOTPVerifyOnlyHandler
);

const initSlice = createSlice({
  name: "auth",
  initialState: INIT_STATE,
  reducers: {
    logout: (state) => {
      removeAccessToken();
      removeOrgId();
      state.isLoggedIn = false;
      return state;
    },
    closeOTPModal: (state) => {
      state.showOTPModal = false;
      return state;
    },
    openOTPModal: (state) => {
      state.showOTPModal = true;
      return state;
    },
    openMobileModal: (state) => {
      state.showMobileModal = true;
      return state;
    },
    closeMobileModal: (state) => {
      state.showMobileModal = false;
      return state;
    },
    setUserAsLogin: (state) => {
      state.isLoggedIn = true;
      return state;
    },
    startLoader: (state) => {
      state.isLoading = true;
      return state;
    },
    stopLoader: (state) => {
      state.isLoading = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserByGoogleAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loginUserByGoogleAsyncThunk.fulfilled, (state, action) => {
        const token = action.payload?.access_token;
        storeAccessToken(token);
        state.isLoading = false;
        state.isLoggedIn = true;
        return state;
      })
      .addCase(loginUserByGoogleAsyncThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(loginByEmailAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loginByEmailAsyncThunk.fulfilled, (state, action) => {
        const token = action.payload?.access_token;
        storeAccessToken(token);
        state.isLoading = false;

        state.isLoggedIn = true;
        return state;
      })
      .addCase(loginByEmailAsyncThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(signupWithEmailAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(signupWithEmailAsyncThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.digitsInOTP = 4;
        state.showOTPModal = true;
        return state;
      })
      .addCase(signupWithEmailAsyncThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(validateOTPAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(validateOTPAsyncThunk.fulfilled, (state, action) => {
        const token = action.payload?.access_token;
        storeAccessToken(token);
        state.isLoading = false;
        state.isLoggedIn = true;
        return state;
      })
      .addCase(validateOTPAsyncThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(resendEmailOTPAsyncThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(resendEmailOTPAsyncThunk.fulfilled, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(signInWithPhoneAsycnThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(signInWithPhoneAsycnThunk.fulfilled, (state) => {
        state.isLoading = false;
        state.digitsInOTP = 6;
        state.showOTPModal = true;
        return;
      })
      .addCase(signInWithPhoneAsycnThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })

      .addCase(forgotPasswordAsycnThunk.fulfilled, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(forgotPasswordAsycnThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(forgotPasswordAsycnThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(sendEmailVerificationOtpThunk.fulfilled, (state) => {
        state.digitsInOTP = 4;
        state.showOTPModal = true;
        return state;
      })
      .addCase(sendMobileVerificationOtpThunk.fulfilled, (state, action) => {
        state.digitsInOTP = 6;
        state.showOTPModal = true;
        state.mobileSession = {
          session_id: action.payload?.session_id,
        };
        return state;
      })
      .addCase(validateOTPVerifyOnlyThunk.fulfilled, (state) => {
        state.digitsInOTP = 4;
        state.mobileSession = {};
        state.showOTPModal = false;
        return state;
      });
  },
});

export const {
  logout,
  closeOTPModal,
  openOTPModal,
  openMobileModal,
  closeMobileModal,
  setUserAsLogin,
  startLoader,
  stopLoader,
} = initSlice.actions;

export default initSlice.reducer;
