import React, { useMemo } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  Stack,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Chip,
} from "@mui/material";
import { Link } from "react-router-dom";

// components
import Page from "../../components/Page";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import Scrollbar from "../../components/Scrollbar";
//
import { useDispatch, useSelector } from "react-redux";
import ListSearchbar from "../../components/common/ListSearchbar";
import Modal from "../../components/Modal/Modal";
import TicketModal from "../../components/Modal/TicketModal";
import ListHead from "../../components/common/ListHead";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import {
  createSupportTicketThunk,
  fetchAllSupportTicketsThunk,
  addReportIssueThunk,
} from "../../store/slices/support/support.slice";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import useSearch from "../../hooks/useSearch";
import { DateTime } from "luxon";
import { capitalCase } from "change-case";

import withBusinessProfile from "../../hoc/withBusinessProfile";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  // { id: "description", label: "Description", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "createdOn", label: "Created On", alignRight: false },
];

function Contact() {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const tickets = useSelector(({ support }) => support.tickets);

  const [search, setSearch] = useState("");
  const { handleTextChange } = useSearch(tickets, "title");

  useEffect(() => {
    dispatch(fetchAllSupportTicketsThunk());
  }, [dispatch]);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  function handleSubmit(data) {
     
    // dispatch(createSupportTicketThunk(data)).unwrap().then(handleClose);
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    if (data.files.length) {
      data.files.map((file) => {
        formData.append("file", file);
      });
    }
    // formData.append("project_id", params.id);
    dispatch(addReportIssueThunk(formData)).unwrap().then(handleClose);
  }
  const serachedTickets = useMemo(() => {
    if (!search) return tickets;
    return handleTextChange(search);
  }, [search, tickets]);
  return (
    <Page title="Contact Us | EMB">
      <Container sx={{ margin: 0 }}>
        <Typography variant="h4">Support</Typography>
        <Breadcrumb />

        <Box mt={5}>
          <Card>
            <Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
                p={1}
              >
                <Paper>
                  <ListSearchbar
                    placeholder="Search tickets"
                    handleSearchChange={(e) => setSearch(e.target.value)}
                  />
                </Paper>
                <Paper>
                  <Button onClick={handleOpen}>
                    <AddIcon />
                    Raise Ticket
                  </Button>
                </Paper>
              </Stack>
            </Box>

            {serachedTickets.length > 0 ? (
              <Page title="Support | EMB">
                <Card sx={{ boxShadow: "none", borderRadius: "0px!important" }}>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <ListHead
                          headLabel={TABLE_HEAD}
                          rowCount={serachedTickets.length}
                          styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                        />
                        <TableBody>
                          {serachedTickets.map((row) => {
                            const { id, title, state, created_at } = row;

                            return (
                              <TableRow
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell align="left">
                                  <Link
                                    to={`tickets/${id}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >
                                    {capitalCase(title)}
                                  </Link>
                                </TableCell>
                                {/* <TableCell align="left">
                                  {description}
                                </TableCell> */}
                                <TableCell
                                  align="left"
                                  sx={{ paddingLeft: "8px!important" }}
                                >
                                  <Chip
                                    size="small"
                                    variant="outlined"
                                    label={capitalCase(state)}
                                    sx={{
                                      fontSize: "12px",
                                      border: "none",
                                      textTransform: "capitalize",

                                      color:
                                        state === "open"
                                          ? "#11A54A"
                                          : "#909090",
                                      // backgroundColor:
                                      //   state === "open"
                                      //     ? "#eff8f1"
                                      //     : "#fdd8de",
                                    }}
                                  />
                                </TableCell>

                                <TableCell align="left">
                                  {DateTime.fromISO(created_at).toFormat("DD")}
                                </TableCell>

                                {/* <TableCell>
                                    <Box sx={{marginLeft:"-60px"}}>
                                  <UserMoreMenu />
                                  </Box>
                                  </TableCell> */}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Card>
              </Page>
            ) : (
              <SearchNotFound
                src="/static/not-found/no_tickets.png"
                subtitle="No tickets raise yet."
                title=" "
                width="200px"
              />
            )}
          </Card>
        </Box>
      </Container>
      {/* Modal Used */}
      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add ticket"
        content={
          <TicketModal handleClose={handleClose} onSubmit={handleSubmit} />
        }
        contentStyle={{ width: "400px" }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
      />
    </Page>
  );
}

export default withBusinessProfile(Contact);
