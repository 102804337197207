import React from "react";
import { styled } from "@mui/material/styles";
import { ToggleButton, ToggleButtonGroup, Paper } from "@mui/material";

const ToggleTabs = ({
  tabs,
  alignment,
  handleAlignment,
  width,
  setSelectedTab,
}) => {
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.5),
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));

  const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "#00ab55",
      "&:hover": {
        background: "#00ab55",
      },
    },
    "&.MuiToggleButton-root": {
      width: width,
    },
  }));

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: "wrap",
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="leads"
        >
          {tabs &&
            tabs.map((tab, i) => {
              return (
                <StyledToggleButton
                  key={i}
                  value={tab.value}
                  onClick={() => setSelectedTab(i)}
                >
                  {tab.label}
                </StyledToggleButton>
              );
            })}
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
};

export default ToggleTabs;
