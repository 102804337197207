import React from "react";
import { Grid } from "@mui/material";
import ClientCard from "./ClientCard";

const ClientsGrid = ({ projects, ...other }) => {
  return (
    <Grid container spacing={3} {...other}>
      {projects.map((project) => (
        <Grid
          key={project.id}
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ padding: "20px" }}
        >
          <ClientCard data={project} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ClientsGrid;
