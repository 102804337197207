import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Card,
  TableHead,
  TableFooter,
  TablePagination,
  Chip,
} from "@mui/material";
import { css } from "@emotion/css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "../Profile/upload.css";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import Modal from "../../components/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import Scrollbar from "../../components/common/Scrollbar";
import useBusinessId from "../../hooks/useBusinessId";
import DeleteAction from "../../components/common/DeleteAction";
import withBusinessProfile from "../../hoc/withBusinessProfile";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import PastWorkModal from "../../components/Modal/PastWorkModal";
import {
  deletePastworkThunk,
  fetchPastworkThunk,
} from "../../store/slices/vendor/vendor.slice";
import PortfolioModal from "../../components/Modal/PortfolioModal";
import {
  fetchAllResourceBiFurcationList,
  getAllProductListData,
} from "../../utils/server.util";

const TABLE_HEAD = [
  // { label: "ID", id: "tableid", alignRight: false },
  { label: "Client Name", id: "client_name", alignRight: false },
  { label: "Description", id: "description", alignRight: false },
  // { label: "Website", id: "website_link", alignRight: false },
  // { label: "Application", id: "app_link", alignRight: false },
  // { label: "Service", id: "service", alignRight: false },
  // { label: "Industry", id: "industries", alignRight: false },
  // { label: "Proof of Work", id: "pow_files", alignRight: false },
  { label: "Client Persona", id: "client_persona", alignRight: false },
  { label: "Project Budget", id: "project_budget", alignRight: false },
  { label: "Meta Tag", id: "meta_tags", alignRight: false },
  { label: "Verfied", id: "verified", alignRight: false },
  { id: "action", label: "Action ", alignRight: false, minWidth: 60 },
];

function setServiceName(params) {
  return params?.service_name;
}
function setIndustryName(params) {
  var data = "";
  if (params === undefined) return "";
  if (params.length === 0) return "";
  params.forEach((element) => {
    data = data + element.industry_name + ",";
  });
  return data.substring(0, data.length - 1);
}

function setPOWName(params) {
  var data = "";
  if (params === undefined) return "";
  if (params.length === 0) return "";
  params.forEach((element) => {
    data = data + element.filename + ",";
  });
  return data.substring(0, data.length - 1);
}

function PastWorkTable() {
  const [showModal, setShowModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const handleOpen = () => {
    setShowModal(true);
  };
  const dispatch = useDispatch();
  const { pastworkdata, totalcountpastwork } = useSelector(
    (vendor) => vendor.vendor
  );

  const [backendOptions, setBackend] = useState([]);
  const [frontendOptions, setFrontend] = useState([]);
  const [databaseOptions, setDatabase] = useState([]);
  const [cloudOptions, setCloud] = useState([]);

  useEffect(() => {
    (async function () {
      const response = await fetchAllResourceBiFurcationList();
      setBackend(response?.backend);
      setFrontend(response?.frontend);
      setDatabase(response.database);
      setCloud(response.cloud);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const response = await getAllProductListData();
      if (response.length > 0) {
        setProductList(
          response.map((ele) => ({
            value: ele.id,
            label: ele.code,
          }))
        );
      }
    })();
  }, []);

  // console.log("product list: ", productList);
  useEffect(() => {
    if (page != 0) {
      dispatch(fetchPastworkThunk(page));
      setPage(parseInt(page - 1));
    }
  }, [pastworkdata?.length == 0]);

  const businessId = useBusinessId();
  useEffect(() => {
    dispatch(fetchPastworkThunk());
  }, [dispatch, businessId]);
  const [selectedData, setSelectedData] = useState(null);
  const handleClose = () => {
    setShowModal(false);
    setSelectedData(null);
  };

  // --new for pagination starts here---

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);

  const handleChangePage = (_, newPage) => {
    dispatch(fetchPastworkThunk(newPage + 1));
    setPage(newPage);
  };

  // --new for pagination ends here---

  function handleDelete(row) {
    // sending the current data in the payload for the maker checker api
    var currentData = row;

    dispatch(deletePastworkThunk({ id: row._id, currrentData: currentData }));
  }

  function handleEdit(row) {
    setSelectedData(row);
    handleOpen();
  }

  return (
    <>
      <div style={{ height: 400, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            marginBottom: "30px",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpen}
            startIcon={<Icon icon={plusFill} />}
          >
            Add Past Work
          </Button>
        </Box>

        <Box
          sx={{
            height: "100%",
            width: 1,
            "& .table-header": {
              backgroundColor: "rgb(244, 246, 248)",
              color: "rgb(99, 115, 129)",
            },
          }}
        >
          <Card>
            <Scrollbar>
              {pastworkdata?.length > 0 ? (
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                      <TableRow>
                        {TABLE_HEAD.map((column) =>
                          column.id != "action" ? (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                top: 57,
                                minWidth: column.minWidth,
                                backgroundColor: "#f3f6f8",
                              }}
                            >
                              {column.label}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                                backgroundColor: "#f3f6f8",
                                position: "sticky",
                                right: 0,
                              }}
                            >
                              {column.label}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pastworkdata.length === 0
                        ? []
                        : pastworkdata.map((row) => {
                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                sx={{ cursor: "pointer" }}
                              >
                                {/* <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row._id}
                                </TableCell> */}
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row.client_name}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row.description}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row.client_persona}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row.project_budget}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {row.meta_tags &&
                                    row.meta_tags.length > 0 && (
                                      <>
                                        {row.meta_tags?.map((elem) => (
                                          <span
                                            style={{
                                              padding: "4px 8px",
                                              backgroundColor:
                                                "rgb(217, 255, 227)",
                                              borderRadius: "6px",
                                              margin: "5px",
                                              display: "flex",
                                              // flexWrap: "wrap",
                                              // width: "auto",
                                            }}
                                          >
                                            {elem}
                                          </span>
                                        ))}
                                      </>
                                    )}
                                </TableCell>
                                {/* <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {setServiceName(row.service)}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {setIndustryName(row.industries)}
                                </TableCell>
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  {setPOWName(row.pow_files)}
                                </TableCell> */}
                                <TableCell
                                  onClick={() => handleEdit(row)}
                                  align="left"
                                >
                                  <Chip
                                    className={css`
                                      font-size: 12px;
                                      border: none;
                                      text-transform: capitalize;
                                      padding-left: 5px;
                                      padding-right: 5px;
                                      background: transparent;
                                    `}
                                    size="small"
                                    label={
                                      row.verified ? (
                                        <CheckCircleOutlineIcon />
                                      ) : (
                                        <CancelOutlinedIcon />
                                      )
                                    }
                                    sx={{
                                      color: row.verified
                                        ? "#11a54a"
                                        : "#fe4842",
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  hover
                                  style={{
                                    position: "sticky",
                                    right: 0,
                                    background: "white",
                                    boxShadow: "5px 2px 5px grey",
                                  }}
                                  align="left"
                                >
                                  <DeleteAction
                                    handleDelete={() => handleDelete(row)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={totalcountpastwork}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPageOptions={false}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <SearchNotFound
                  src="/static/support.svg"
                  subtitle="There is currently no Past Work to show."
                />
              )}
            </Scrollbar>
          </Card>
        </Box>
      </div>

      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        title="Add Past Work"
        content={
          <PortfolioModal
            selectedData={selectedData}
            handleClose={handleClose}
            productList={productList}
            backendOptions={backendOptions}
            frontendOptions={frontendOptions}
            databaseOptions={databaseOptions}
            cloudOptions={cloudOptions}
            page={page == 0 ? 1 : page + 1}
          />
        }
        dialogProps={{ fullWidth: true }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        actionStyle={{ justifyContent: "center", marginBottom: 2 }}
      />
    </>
  );
}

export default withBusinessProfile(PastWorkTable);
