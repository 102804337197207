import { Link as RouterLink, Navigate, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
import queryString from "query-string";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { RegisterForm } from "../../components/authentication/register";
import withAuthLoader from "../../hoc/withAuthLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOTPModal,
  resendEmailOTPAsyncThunk,
  validateOTPAsyncThunk,
} from "../../store/slices/auth/auth.slice";
import Modal from "../../components/Modal/Modal";
import OTPModal from "../../components/Modal/OtpModal";
import { useEffect } from "react";
import { otpType } from "../../utils/enum";
import { navigateFromApp } from "../../utils/global/global";
import { getAccessToken } from "../../utils/global/auth.global";
import Loader from "../../components/Loader/Loader";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function Register() {
  const { showOTPModal, digitsInOTP } = useSelector(({ auth }) => auth);
  const { profile } = useSelector(({ user }) => user.userData);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const query = queryString.parse(location.search);

  function closeModal() {
    dispatch(closeOTPModal());
  }

  useEffect(() => {
    return function () {
      dispatch(closeOTPModal());
    };
  }, []);

  function validateOTP(otp) {
    let type = otpType.email;
    if (digitsInOTP === 6) type = otpType.mobile;
    dispatch(
      validateOTPAsyncThunk({
        otp,
        type,
        ...profile,
      })
    );
  }

  function resetOTP() {
    dispatch(resendEmailOTPAsyncThunk(profile));
  }

  if (isLoggedIn) {
    if (query.domain) {
      const allAvailableDomains = process.env.REACT_APP_REDIRECTION_DOMAINS;
      const domainsArray = allAvailableDomains.split(",");
      if (!domainsArray.includes(query.domain)) {
        return <Navigate to={"/dashboard"} />;
      }
      navigateFromApp({
        ...query,
        search: {
          token: getAccessToken(),
        },
      });
    } else {
      let url = query.redirectUrl ? query.redirectUrl : "/dashboard";
      return <Navigate to={url} />;
    }
    return <Loader />;
  }

  return (
    <>
      <Modal
        isOpen={showOTPModal}
        closeModal={closeModal}
        content={
          <OTPModal
            validateOTP={validateOTP}
            resetOTP={resetOTP}
            digitsInOTP={digitsInOTP}
          />
        }
      />
      <RootStyle title="Register | EMB">
        <AuthLayout>
          Already have an account? &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/login"
          >
            Login
          </Link>
        </AuthLayout>

        <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Manage the project more effectively with EMB
            </Typography>
            <img
              alt="register"
              src="/static/vectors/signup.svg"
              style={{ padding: "40px" }}
            />
          </SectionStyle>
        </MHidden>

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Get started absolutely free.
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Free forever. No credit card needed.
              </Typography>
            </Box>

            {/*<AuthSocial />

            <AuthMobile />*/}

            <RegisterForm />

            <Typography
              variant="body2"
              align="center"
              sx={{ color: "text.secondary", mt: 3 }}
            >
              By registering, I agree to EMB&nbsp;
              <a
                underline="always"
                target="_blank"
                href="https://www.exmyb.com/terms-and-conditions"
                sx={{ color: "#212B36" }}
              >
                Terms of Service
              </a>
              &nbsp;and&nbsp;
              <a
                underline="always"
                target="_blank"
                href="https://www.exmyb.com/privacy-policy"
                sx={{
                  color: "#212B36",
                }}
              >
                Privacy Policy
              </a>
              .
            </Typography>

            <MHidden width="smUp">
              <Typography
                variant="subtitle2"
                sx={{ mt: 3, textAlign: "center" }}
              >
                Already have an account?&nbsp;
                <Link to="/login" component={RouterLink}>
                  Login
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}

export default withAuthLoader(Register);
