import { Link as RouterLink, Navigate, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Link, Container, Typography } from "@mui/material";
import queryString from "query-string";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material-extend";
import { LoginForm } from "../../components/authentication/login";
import withAuthLoader from "../../hoc/withAuthLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOTPModal,
  resendEmailOTPAsyncThunk,
  validateOTPAsyncThunk,
} from "../../store/slices/auth/auth.slice";
import { useEffect } from "react";
import Modal from "../../components/Modal/Modal";
import OTPModal from "../../components/Modal/OtpModal";
import { otpType } from "../../utils/enum";
import { navigateFromApp } from "../../utils/global/global";
import { getAccessToken } from "../../utils/global/auth.global";
import Loader from "../../components/Loader/Loader";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function Login() {
  const { showOTPModal, digitsInOTP } = useSelector(({ auth }) => auth);
  const { profile } = useSelector(({ user }) => user.userData);
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const query = queryString.parse(location.search);

  function closeModal() {
    dispatch(closeOTPModal());
  }

  useEffect(() => {
    return function () {
      dispatch(closeOTPModal());
    };
  }, [dispatch]);

  function validateOTP(otp) {
    let type = otpType.email;
    if (digitsInOTP === 6) type = otpType.mobile;
    dispatch(
      validateOTPAsyncThunk({
        otp,
        type,
        ...profile,
      })
    );
  }

  function resetOTP() {
    dispatch(resendEmailOTPAsyncThunk(profile));
  }

  if (isLoggedIn) {
    if (query.domain) {
      const allAvailableDomains = process.env.REACT_APP_REDIRECTION_DOMAINS;
      const domainsArray = allAvailableDomains.split(",");
      if (!domainsArray.includes(query.domain)) {
        return <Navigate to={"/dashboard"} />;
      }

      navigateFromApp({
        ...query,
        search: {
          token: getAccessToken(),
        },
      });
    } else {
      let url = query.redirectUrl ? query.redirectUrl : "/dashboard";
      return <Navigate to={url} />;
    }
    return <Loader />;
  }

  return (
    <>
      <Modal
        isOpen={showOTPModal}
        closeModal={closeModal}
        content={
          <OTPModal
            validateOTP={validateOTP}
            resetOTP={resetOTP}
            digitsInOTP={digitsInOTP}
          />
        }
      />
      <RootStyle title="Login | EMB">
        <AuthLayout>
          Don’t have an account? &nbsp;
          {/* <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to={"/register/" + location.search}
          >
            Get started
          </Link> */}
          <span
            style={{
              color: "green",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() =>
              window.location.assign(process.env.REACT_APP_ONBOARDING_DOMAIN)
            }
          >
            Get Started
          </span>
        </AuthLayout>

        <MHidden width="mdDown">
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img
              src="/static/vectors/login.svg"
              alt="login"
              style={{ padding: "40px" }}
            />
          </SectionStyle>
        </MHidden>

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Sign In
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Enter your details below.
              </Typography>
            </Stack>
            {/*<AuthSocial />
            <AuthMobile />*/}

            <LoginForm />

            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?&nbsp;
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={"/register/" + location.search}
                >
                  Get started
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}

export default withAuthLoader(Login);
