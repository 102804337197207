import React, { useEffect, useState, useMemo } from "react";
import { Card, Grid, Button, CardContent, Typography } from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import { useDispatch, useSelector } from "react-redux";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";

import MyDropzone from "../../../../src/components/_dashboard/app/DropZone";
import {
  getProposalsByLeadIdThunk,
  uploadProposalThunk,
} from "../../../store/slices/lead/lead.slice";
import { showFailureToast } from "../../../store/slices/toast/toast.slice";

export default function SendProposal() {
  const [proposalFile, setProposalFile] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProposalsByLeadIdThunk(params.id));
  }, [params.id, dispatch]);

  const data = useSelector(({ leads }) => leads.selectedLead);

  let proposalData = useSelector(({ leads }) => leads.selectedLead.proposals);

  proposalData = useMemo(() => {
    return proposalData.filter((proposal) => proposal.proposal_file);
  }, [proposalData]);

  function addAttachment(file) {
    setProposalFile(file.file);
    const maxSizeInkB = 10 * 1024;
    const kb = file.size / 1024;
    const formData = new FormData();
    formData.append("file", file.file);
    if (kb > maxSizeInkB) {
      return "File size should less than 500kB";
    }
  }

  const handleUpload = () => {
    if (!proposalFile) {
      return dispatch(
        showFailureToast({
          message: "Please Select a File",
          vertical: "top",
          horizontal: "right",
        })
      );
    }
    dispatch(
      uploadProposalThunk({
        proposalFile,
        projectId: data.project.project_id,
        leadId: data.lead_id,
        proposalId: data?.proposals[0]?.proposal_id,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getProposalsByLeadIdThunk(params.id));
      });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={12} sx={{ margin: "50px 0" }}>
        <MyDropzone addAttachment={addAttachment} />
      </Grid>
      <Grid item xs={12} sm={6} md={12}>
        <Button
          variant="contained"
          sx={{ float: "right" }}
          onClick={() => handleUpload()}
        >
          <BackupIcon sx={{ fontSize: "14px", marginRight: "5px" }} />
          Upload
        </Button>
      </Grid>

      <Grid container spacing={3} mt={5}>
        <Grid item xs={12} sm={12} md={12}>
          {proposalData &&
            proposalData.map((proposal, id) => {
              return (
                <TimelineItem key={id}>
                  <TimelineOppositeContent
                    color="text.secondary"
                    sx={{ flex: "0.12" }}
                  >
                    {DateTime.fromISO(proposal.proposal_updated_at).toFormat(
                      "DD"
                    )}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="success" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card
                      mb={5}
                      sx={{
                        width: "100%",
                        borderRadius: "6px",
                      }}
                    >
                      <CardContent>
                        <Grid container>
                          <Grid item md={8}>
                            <Grid container>
                              <Grid item md={6}>
                                <Typography variant="h6" sx={{ float: "left" }}>
                                  <span style={{ fontWeight: "500" }}>
                                    Client status:&nbsp;&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      backgroundColor:
                                        proposal?.status_by_client ===
                                        "accepted"
                                          ? "#c6f6d4"
                                          : "#fed6d7",
                                      color:
                                        proposal?.status_by_client ===
                                        "accepted"
                                          ? "#276749"
                                          : "#9b2c2c",
                                      fontSize: ".75rem",
                                      padding: "0.25rem 0.75rem",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    {proposal?.status_by_client === ""
                                      ? "Pending"
                                      : proposal?.status_by_client}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item md={6}>
                                <Typography variant="h6" sx={{ float: "left" }}>
                                  <span style={{ fontWeight: "500" }}>
                                    EMB status:&nbsp;
                                  </span>
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                      backgroundColor: "#c6f6d5",
                                      color: "#276749",
                                      fontSize: ".75rem",
                                      padding: "0.25rem 0.75rem",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    {proposal?.status_by_exmyb === ""
                                      ? "Pending"
                                      : proposal?.status_by_exmyb}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={4}>
                            <Button
                              startIcon={<DownloadIcon />}
                              sx={{ float: "right" }}
                              href={proposal.proposal_file}
                              target="blank"
                            >
                              Download
                            </Button>
                          </Grid>
                          <Grid item md={12}>
                            <Typography variant="body2" my={2}>
                              {proposal?.reason_for_rejection}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
}
