import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import DashboardApp from "./pages/DashboardApp";
import User from "./pages/User";
import Portfolio from "./pages/Portfolio";
import NotFound from "./pages/Page404";
import Leads from "./pages/Leads";
import Clients from "./pages/Clients";
import { Outlet } from "react-router-dom";
import ClientRequirements from "./components/_dashboard/clients/ClientRequirements";
import MyProfile from "./pages/Profile/MyProfile";
import withAuth from "./hoc/withAuth";
import AuthProtector from "./components/Protector/AuthProtector";
import Faq from "./pages/Faq/Faq";
import Services from "./pages/services/Services";
import Contact from "./pages/Contact/Contact";
import ContactChatTicket from "./pages/Contact/ContactChatTicket";
import Notification from "./pages/Notification/Notification";
import Wallet from "./pages/Wallet/Wallet";
import LeadRequirements from "./components/_dashboard/clients/LeadRequiremnets";
import Overview from "./components/_dashboard/clients/Overview/Overview";
// import OverViewLeads from "./pages/ProjectDetail/Overview/OverView";

import MainChat from "./pages/Chat";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import NewPassword from "./pages/Auth/NewPassword";
//--------------------------Leads Additions ---------------------------------
import AllProjects from "./components/_dashboard/lead/AllProjects";
import ProjectDetails from "./pages/ProjectDetail";
import OverViewLeads from "./pages/ProjectDetail/Overview/OverView";
import ProposalLeads from "./pages/Proposal/Proposal";
//--------------------------Leads Additions Ends here -----------------------

import {
  basicInfoPath,
  cvPath,
  milestonePath,
  overviewPath,
  proposalPath,
  scopePath,
  supportPath,
  tablePath,
} from "./utils/constant";
import Proposal from "./components/_dashboard/clients/Proposal";

import TimelineDashboard from "./components/TimelineDashboard";
import Charter from "./components/_dashboard/clients/Charter";
import Ticket from "./components/_dashboard/clients/Ticket";
import Collections from "./pages/Collection/Collections";
import SendProposal from "./components/_dashboard/clients/SendProposal";
import RAList from "./pages/RA/RA_List";
import RaDetails from "./pages/RA/RaDetails";
import RaCvs from "./pages/RA/RaCvs";
import RaDetailsFull from "./pages/RA";
import CheckOnboardingToken from "./pages/Auth/CheckOnboardingToken";

// ----------------------------------------------------------------------

function NewRoutes() {
  return useRoutes([
    {
      path: "/dashboard",
      element: (
        <AuthProtector>
          <DashboardLayout />
        </AuthProtector>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard/app" />,
        },
        { path: "app", element: <DashboardApp /> },
        { path: "user", element: <User /> },
        // { path: "leads", element: <Leads /> },
        { path: "faq", element: <Faq /> },
        { path: "contact_us", element: <Contact /> },

        {
          path: "leads",
          element: <Outlet />,
          children: [
            { path: "", element: <AllProjects /> },
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <ProjectDetails />,
                  children: [
                    { path: overviewPath, element: <OverViewLeads /> },
                    { path: proposalPath, element: <ProposalLeads /> },
                  ],
                },
              ],
            },
          ],
        },

        {
          path: "leads",
          element: <Outlet />,
          children: [
            { path: "", element: <AllProjects /> },
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <ProjectDetails />,
                  children: [
                    { path: overviewPath, element: <OverViewLeads /> },
                    { path: proposalPath, element: <ProposalLeads /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "ra",
          element: <Outlet />,
          children: [
            { path: "", element: <RAList /> },
            {
              path: ":leadId",
              element: <RaDetailsFull />,
              children: [
                { path: basicInfoPath, element: <RaDetails /> },
                {
                  path: cvPath,
                  element: <RaCvs />,
                },
              ],
            },
          ],
        },
        {
          path: "projects",
          element: <Outlet />,
          children: [
            { path: "", element: <Clients /> },
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <ClientRequirements />,
                  children: [
                    { path: overviewPath, element: <Overview /> },
                    { path: proposalPath, element: <Proposal /> },
                    { path: tablePath, element: <TimelineDashboard /> },
                    { path: scopePath, element: <Charter /> },
                    {
                      path: supportPath,
                      element: <Outlet />,
                      children: [
                        { path: "", element: <Ticket /> },
                        { path: ":ticketId", element: <ContactChatTicket /> },
                      ],
                    },
                    { path: milestonePath, element: <Collections /> },
                  ],
                },
              ],
            },
          ],
        },
        { path: "chat", element: <MainChat /> },

        {
          path: "contact_us",
          element: <Outlet />,
          children: [
            { path: "", element: <Contact /> },
            { path: "tickets/:ticketId", element: <ContactChatTicket /> },
          ],
        },
        {
          path: "myProfile",
          element: <MyProfile />,
        },
        {
          path: "requirement",
          element: <Outlet />,
          children: [
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <LeadRequirements />,
                  children: [
                    { path: "overview", element: <Overview /> },
                    { path: "send-proposal", element: <SendProposal /> },
                  ],
                },
              ],
            },
          ],
        },
        { path: "notification", element: <Notification /> },
        // { path: "wallet", element: <Wallet /> },
        { path: "services", element: <Services /> },
        { path: "profile", element: <MyProfile /> },
        { path: "portfolio", element: <Portfolio /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "reset-password", element: <NewPassword /> },
        { path: "404", element: <NotFound /> },
        { path: "auth/:token", element: <CheckOnboardingToken /> }, // to check for the token in the url so that user can login by default
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },

    

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

export default withAuth(NewRoutes);
