import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  modifyBusinessProfiles,
  removeOrgId,
} from "../../../utils/global/vendor.global";
import {
  addAddressForBusinessProfileHandler,
  createBusinessProfileHandler,
  fetchAllAddressHandler,
  fetchAllVendorProfilesHandler,
  fetchVendorDetailsHandler,
  updateBusinessProfileHandler,
  updateLanguagesHandler,
  updateSkillsHandler,
  fetchAllPOCHandler,
  createPOCHandler,
  updatePOCHandler,
  deletePOCHandler,
  updateBusinessAddressHandler,
  deleteBusinessAddressHandler,
  fetchTurnoverForLast3YearsHandler,
  deleteVendorProfilesHandler,
  addTurnoverForLast3YearsHandler,
  deleteTurnoverForLast3YearsHandler,
  editTurnoverForLast3YearsHandler,
  searchVendorProfilesHandler,
  fetchProfilesByAdvanceFilterHandler,
  fetchSassChannelDetailsHandler,
  fetchCertificationHandler,
  deleteSassChannelProfileHandler,
  createSassChannelProfileHandler,
  fetchFounderProfileHandler,
  createFounderProfileHandler,
  deleteFounderProfileHandler,
  fetchBenchStrengthInfoHandler,
  fetchTestimonialInfoHandler,
  deleteTestimonialInfoHandler,
  createTestimonialInfoHandler,
  deleteCertificationHandler,
  createCertificationHandler,
  fetchPlatformReviewHandler,
  createPlatformReviewHandler,
  fetchTechStackForteHandler,
  createTechStackForteHandler,
  deleteTechStackForteHandler,
  createBenchStrengthHandler,
  deleteBenchStrengthHandler,
  fetchExpertiseBreakupHandler,
  createExpertiseBreakupHandler,
  deleteExpertiseBreakupHandler,
  deletePlatformReviewHandler,
  updateTechStackForteHandler,
  fetchProductCodeOptionsHandler,
} from "./vendor.thunk";
import { certification } from "../../../utils/constant";

import {
  getAllPastWorkApi,
  getTechDataApi,
  pastworkApi,
} from "../../../utils/apis.utils";
import {
  createQueryString,
  showToastAlert,
} from "../../../utils/global/global";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { requestTypeEnum, tabsNameEnum } from "../../../utils/enum";

const INIT_STATE = {
  profiles: [],
  profileData: {},
  isLoading: false,
  allAddress: [],
  totalAddress: 0,
  pocData: [],
  totalPOC: 0,
  turnover: [],
  totalProfiles: 0,
  sassChannelPartner: {},
  certification: [],
  certificationTotalNo: 0,
  totalSassPartnerDataLength: 0,
  founderInfoProfileList: [],
  totalNumberProfileList: 0,
  benchStrengthInfoData: [],
  totalbenchStrength: 0,
  testimonialInfoData: [],
  totalNumberTestimonial: 0,
  platformReviewList: [],
  techStackForteList: [],
  expertisebreakupList: {
    infos: [],
    minimum_value: null,
    maximum_value: null,
  },
  pastworkdata: [],
  frontend: null,
  backend: null,
  totalcountpastwork: 0,
  ra_enabled: null,
  TotalProfileProgressCount: null,
  profileLastUpdated: null,
  isProfileDataFetched: false,
};

export const fetchAllVendorProfilesThunk = createAsyncThunk(
  "fetchAllVendorProfiles",
  fetchAllVendorProfilesHandler
);

export const fetchProfilesByAdvanceFilterThunk = createAsyncThunk(
  "fetchProfilesByAdvanceFilter",
  fetchProfilesByAdvanceFilterHandler
);

export const searchVendorProfilesThunk = createAsyncThunk(
  "searchVendorProfiles",
  searchVendorProfilesHandler
);

export const deleteVendorProfilesThunk = createAsyncThunk(
  "deleteVendorProfiles",
  deleteVendorProfilesHandler
);

export const fetchVendorProfileDetailsThunk = createAsyncThunk(
  "fetchVendorProfileDetails",
  fetchVendorDetailsHandler
);

export const updateBusinessProfileThunk = createAsyncThunk(
  "updateBusinessProfileThunk",
  updateBusinessProfileHandler
);

export const fetchAllAddressThunk = createAsyncThunk(
  "fetchAllAddress",
  fetchAllAddressHandler
);

export const addAddressForBusinessProfileThunk = createAsyncThunk(
  "addAddressForBusinessProfile",
  addAddressForBusinessProfileHandler
);

export const updateBusinessAddressThunk = createAsyncThunk(
  "updateBusinessAddress",
  updateBusinessAddressHandler
);

export const deleteBusinessAddress = createAsyncThunk(
  "deleteBusinessAddress",
  deleteBusinessAddressHandler
);

export const updateLanguagesThunk = createAsyncThunk(
  "updateLanguages",
  updateLanguagesHandler
);

export const updateSkillsThunk = createAsyncThunk(
  "updateSkills",
  updateSkillsHandler
);

export const createBusinessProfileThunk = createAsyncThunk(
  "createBusinessProfile",
  createBusinessProfileHandler
);

export const fetchAllPOCThunk = createAsyncThunk(
  "fetchAllPOC",
  fetchAllPOCHandler
);

export const fetchTurnoverForLast3YearsThunk = createAsyncThunk(
  "fetchTurnoverForLast3Years",
  fetchTurnoverForLast3YearsHandler
);

export const addTurnoverForLast3YearsThunk = createAsyncThunk(
  "addTurnoverForLast3Years",
  addTurnoverForLast3YearsHandler
);

export const deleteTurnoverForLast3YearsThunk = createAsyncThunk(
  "deleteTurnoverForLast3Years",
  deleteTurnoverForLast3YearsHandler
);

export const editTurnoverForLast3YearsThunk = createAsyncThunk(
  "editTurnoverForLast3Years",
  editTurnoverForLast3YearsHandler
);

//sass partner thunk create
export const fetchSassChannelDetailsThunk = createAsyncThunk(
  "fetchSassChannelDetails",
  fetchSassChannelDetailsHandler
);

export const deleteSassChannelProfileThunk = createAsyncThunk(
  "deleteSassChannelProfile",
  deleteSassChannelProfileHandler
);

export const createSassChannelProfileThunk = createAsyncThunk(
  "createSassChannelProfile",
  createSassChannelProfileHandler
);

//protfolio profile info
export const fetchFounderProfileThunk = createAsyncThunk(
  "fetchFounderProfile",
  fetchFounderProfileHandler
);
export const createFounderProfileThunk = createAsyncThunk(
  "createFounderProfile",
  createFounderProfileHandler
);
export const deleteFounderProfileThunk = createAsyncThunk(
  "deleteFounderProfile",
  deleteFounderProfileHandler
);

//bench strength info
export const fetchBenchInfoThunk = createAsyncThunk(
  "fetchBenchInfo",
  fetchBenchStrengthInfoHandler
);

export const createBenchStrengthThunk = createAsyncThunk(
  "createBenchStrength",
  createBenchStrengthHandler
);

export const deleteBenchStrengthThunk = createAsyncThunk(
  "deleteBenchStrength",
  deleteBenchStrengthHandler
);

//testimonial data info
export const fetchTestimonialInfoThunk = createAsyncThunk(
  "fetchTestimonialInfo",
  fetchTestimonialInfoHandler
);

export const deleteTestimonialInfoThunk = createAsyncThunk(
  "deleteTestimonialInfo",
  deleteTestimonialInfoHandler
);

export const createTestimonialInfoThunk = createAsyncThunk(
  "createTestimonialInfo",
  createTestimonialInfoHandler
);

//certification thunk create
export const fetchCertificationThunk = createAsyncThunk(
  "fetchCertification",
  fetchCertificationHandler
);

export const createCertificationThunk = createAsyncThunk(
  "createCertification",
  createCertificationHandler
);

export const deleteCertificationThunk = createAsyncThunk(
  "deleteCertification",
  deleteCertificationHandler
);

//platform review info
export const fetchPlatformReviewThunk = createAsyncThunk(
  "fetchPlatformReview",
  fetchPlatformReviewHandler
);

export const createPlatformReviewThunk = createAsyncThunk(
  "createPlatformReview",
  createPlatformReviewHandler
);

export const deletePlatformReviewThunk = createAsyncThunk(
  "deletePlatformReview",
  deletePlatformReviewHandler
);

//tech stack forte
export const fetchTechStackForteThunk = createAsyncThunk(
  "fetchTechStackForte",
  fetchTechStackForteHandler
);

export const createTechStackForteThunk = createAsyncThunk(
  "createTechStackForte",
  createTechStackForteHandler
);

export const updateTeckStackForteThunk = createAsyncThunk(
  "updateTechStackForte",
  updateTechStackForteHandler
);

export const deleteTechStackForteThunk = createAsyncThunk(
  "deleteTechStackForte",
  deleteTechStackForteHandler
);

//expertise tabs info
export const fetchExpertisebreakupThunk = createAsyncThunk(
  "fetchExpertisebreakup",
  fetchExpertiseBreakupHandler
);
export const createExpertisebreakupThunk = createAsyncThunk(
  "createExpertisebreakup",
  createExpertiseBreakupHandler
);

export const deleteExpertisebreakupThunk = createAsyncThunk(
  "deleteExpertisebreakup",
  deleteExpertiseBreakupHandler
);

//past work info
export const fetchPastworkThunk = createAsyncThunk(
  "fetchPastwork",
  // fetchPastworkHandler
  async (page = 1, thunkApi) => {
    const query = createQueryString({
      page,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await profile.get(getAllPastWorkApi + query);
    return {
      data: response.data,
      total: response.total,
    };
  }
);

export const fetchTechDataThunk = createAsyncThunk(
  "fetchTechData",
  // fetchTechDataHandler
  async (rb_type, thunkApi) => {
    const query = createQueryString({
      rb_type,
    });
    const { profile } = thunkApi.extra.apiService;
    const response = await Promise.all([
      profile.get(getTechDataApi + query),
      profile.get(getTechDataApi + query), // Adjust this to the actual endpoint for backend data
    ]);
    return {
      frontend: response?.[0]?.data,
      backend: response?.[1]?.data,
    };
  }
);

export const deletePastworkThunk = createAsyncThunk(
  "deletePastWork",
  //
  async ({ id, currentData }, thunkApi) => {
    try {
      const { profile } = thunkApi.extra.apiService;
      // const { check } = thunkApi.extra.apiService;
      const response = await profile.delete(
        pastworkApi + "/" + id
        //   ,{
        //   data: {
        //     data: {
        //       data: currentData
        //     },
        //     type: requestTypeEnum.delete,
        //     meta: tabsNameEnum.PastWork,
        //   }
        // }
      );
      thunkApi.dispatch(
        showSuccessToast({
          message: response.message,
          vertical: "top",
          horizontal: "right",
        })
      );
      thunkApi.dispatch(fetchPastworkThunk());
      return id;
    } catch (err) {
      // thunkApi.dispatch(showFailureToast({ message: err }));
      showToastAlert(err, thunkApi);
      return Promise.reject(err);
    }
  }
);

export const updatePastworkThunk = createAsyncThunk(
  "updatePastWork",
  // updatePastworkHandler
  async ({ id, data, pastData }, thunkApi) => {
    try {
      // console.log("data: " + data);

      const { profile } = thunkApi.extra.apiService;
      // const { check } = thunkApi.extra.apiService;
      const response = await profile.put(
        pastworkApi + "/" + id,
        data
        //   , {
        //   data: {
        //     data: data,
        //     past_data: pastData,
        //   },
        //   type: requestTypeEnum.edit,
        //   meta: tabsNameEnum.PastWork,
        // }
      );
      thunkApi.dispatch(
        showSuccessToast({
          message: response.message,
          vertical: "top",
          horizontal: "right",
        })
      );
      thunkApi.dispatch(fetchPastworkThunk());
      return response.data;
    } catch (err) {
      // thunkApi.dispatch(showFailureToast({ message: err }));
      showToastAlert(err, thunkApi);
      return Promise.reject(err);
    }
  }
);

export const createPastworkThunk = createAsyncThunk(
  "createPastwork",
  // createPastworkHandler
  async ({ data }, thunkApi) => {
    try {
      const { profile } = thunkApi.extra.apiService;
      // const { check } = thunkApi.extra.apiService;
      var response = null;
      // response = await profile.post(pastworkApi, {
      //   data: {
      //     data: data,
      //   },
      //   meta: tabsNameEnum.PastWork,
      //   type: requestTypeEnum.new
      // });
      response = await profile.post(pastworkApi, data);

      // if (id) {
      //   response = await check.put(`${pastworkApi}/${id}`, data);
      // } else {
      //   response = await check.post(pastworkApi, data);
      // }
      thunkApi.dispatch(
        showSuccessToast({
          message: response.message,
          vertical: "top",
          horizontal: "right",
        })
      );
      thunkApi.dispatch(fetchPastworkThunk(data.page));
    } catch (err) {
      // thunkApi.dispatch(showFailureToast({ message: err }));
      showToastAlert(err, thunkApi);
      return Promise.reject(err);
    }
  }
);

//product code dropdown
export const fetchProductCodeOptionsThunk = createAsyncThunk(
  "fetchProductCodeOptions",
  fetchProductCodeOptionsHandler
);

export const createPOCThunk = createAsyncThunk("createPOC", createPOCHandler);
export const updatePOCThunk = createAsyncThunk("updatePOC", updatePOCHandler);
export const deletePOCThunk = createAsyncThunk("deletePOC", deletePOCHandler);

const vendorSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    clearVendorProfile: (state) => {
      state.profileData = {};
      removeOrgId();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendorProfileDetailsThunk.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.profileLastUpdated = new Date();
        state.isLoading = false;

        if (action.payload?.resource_augmentation_service === true) {
          state.ra_enabled = true;
        } else if (action.payload?.resource_augmentation_service === false) {
          state.ra_enabled = false;
        }

        if (action.payload?.profile_progress_count) {
          state.TotalProfileProgressCount =
            action.payload?.profile_progress_count;
        }
        state.isProfileDataFetched = true;
        return state;
      })
      .addCase(fetchVendorProfileDetailsThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchVendorProfileDetailsThunk.rejected, (state) => {
        state.isLoading = false;
        state.isProfileDataFetched = true;
        return state;
      })
      .addCase(fetchAllVendorProfilesThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllVendorProfilesThunk.fulfilled, (state, action) => {
        state.profiles = modifyBusinessProfiles(action.payload.data);
        state.totalProfiles = action.payload.total;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchProfilesByAdvanceFilterThunk.fulfilled, (state, action) => {
        state.profiles = modifyBusinessProfiles(action.payload.data);
        state.totalProfiles = action.payload.total;
      })
      .addCase(fetchAllVendorProfilesThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(searchVendorProfilesThunk.fulfilled, (state, action) => {
        state.profiles = modifyBusinessProfiles(action.payload.data);
        state.totalProfiles = action.payload.total;
        return state;
      })
      .addCase(updateBusinessProfileThunk.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.profileLastUpdated = new Date();
        return state;
      })
      .addCase(fetchAllAddressThunk.fulfilled, (state, action) => {
        state.allAddress = action.payload.data;
        state.totalAddress = action.payload.total;
        return state;
      })
      .addCase(addAddressForBusinessProfileThunk.fulfilled, (state, action) => {
        // state.allAddress.push(action.payload);
        // state.totalAddress = state.totalAddress + 1;
        // return state;
      })
      .addCase(updateBusinessAddressThunk.fulfilled, (state, action) => {
        const idx = state.allAddress.findIndex(
          (ele) => ele.id === action.payload.id
        );
        state.allAddress[idx] = action.payload;
        return state;
      })
      .addCase(deleteBusinessAddress.fulfilled, (state, action) => {
        // state.allAddress = state.allAddress.filter(
        //   (ele) => ele.id !== action.payload
        // );
        // state.totalAddress = state.totalAddress - 1;
        // return state;
      })
      .addCase(updateLanguagesThunk.fulfilled, (state, action) => {
        state.profileData.languages = action.payload;
        state.profileLastUpdated = new Date();
        return state;
      })
      .addCase(updateSkillsThunk.fulfilled, (state, action) => {
        state.profileData.skills = action.payload;
        state.profileLastUpdated = new Date();
        return state;
      })
      .addCase(createBusinessProfileThunk.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.profileLastUpdated = new Date();
        return state;
      })
      .addCase(fetchAllPOCThunk.fulfilled, (state, action) => {
        state.pocData = action.payload.data;
        state.totalPOC = action.payload.total;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchAllPOCThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchAllPOCThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(updatePOCThunk.fulfilled, (state, action) => {
        const idx = state.pocData.findIndex(
          (ele) => ele._id === action.payload._id
        );
        state.pocData[idx] = action.payload;
        return state;
      })
      .addCase(deletePOCThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var data = state.pocData.filter((ele) => ele._id !== id);
        // state.pocData = data;
        // state.totalPOC = state.totalPOC - 1;
        // return state;
      })
      .addCase(createPOCThunk.fulfilled, (state, action) => {
        // state.pocData.push(action.payload);
        // state.totalPOC = state.totalPOC + 1;
      })
      .addCase(fetchTurnoverForLast3YearsThunk.fulfilled, (state, action) => {
        state.turnover = action.payload;
        return state;
      })
      .addCase(deleteVendorProfilesThunk.fulfilled, (state, action) => {
        var id = action.payload;
        var data = state.profiles.filter((ele) => ele.id != id);
        state.profiles = data;
        return state;
      })
      .addCase(addTurnoverForLast3YearsThunk.fulfilled, (state, action) => {
        state.turnover.push(action.payload);
      })
      .addCase(editTurnoverForLast3YearsThunk.fulfilled, (state, action) => {
        const idx = state.turnover.findIndex(
          (ele) => ele.id === action.payload.id
        );
        state.turnover[idx] = action.payload;
        return state;
      })
      .addCase(deleteTurnoverForLast3YearsThunk.fulfilled, (state, action) => {
        var id = action.payload;
        var data = state.turnover.filter((ele) => ele.id !== id);
        state.turnover = data;
        return state;
      })
      //sass channel partner slice
      .addCase(fetchSassChannelDetailsThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchSassChannelDetailsThunk.fulfilled, (state, action) => {
        state.sassChannelPartner = action.payload.data;
        state.totalSassPartnerDataLength = action.payload.total;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchSassChannelDetailsThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(deleteSassChannelProfileThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deleteSassChannelProfileThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var data = state.sassChannelPartner.filter((ele) => ele.id != id);
        // state.sassChannelPartner = data;
        // return state;
      })
      .addCase(deleteSassChannelProfileThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createSassChannelProfileThunk.fulfilled, (state, action) => {})
      //founder profile info
      .addCase(fetchFounderProfileThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchFounderProfileThunk.fulfilled, (state, action) => {
        state.founderInfoProfileList = action.payload.data;
        state.totalNumberProfileList = action.payload.total;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchFounderProfileThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createFounderProfileThunk.fulfilled, (state, action) => {})
      .addCase(deleteFounderProfileThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deleteFounderProfileThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var data = state.founderInfoProfileList.filter((ele) => ele.id != id);
        // state.founderInfoProfileList = data;
        // return state;
      })
      .addCase(deleteFounderProfileThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      //product code options
      .addCase(fetchProductCodeOptionsThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchProductCodeOptionsThunk.fulfilled, (state, action) => {
        state.productCodeOptionsData = action.payload.data;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchProductCodeOptionsThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      //bench strength info
      .addCase(fetchBenchInfoThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchBenchInfoThunk.fulfilled, (state, action) => {
        state.benchStrengthInfoData = action.payload.data;
        state.totalbenchStrength = action.payload.total;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchBenchInfoThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createBenchStrengthThunk.fulfilled, (state, action) => {})
      .addCase(deleteBenchStrengthThunk.fulfilled, (state, action) => {})
      //testimonial info
      .addCase(fetchTestimonialInfoThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchTestimonialInfoThunk.fulfilled, (state, action) => {
        state.testimonialInfoData = action.payload.data;
        state.totalNumberTestimonial = action.payload.total;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchTestimonialInfoThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(deleteTestimonialInfoThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deleteTestimonialInfoThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var data = state.testimonialInfoData.filter((ele) => ele.id != id);
        // state.testimonialInfoData = data;
        // return state;
      })
      .addCase(deleteTestimonialInfoThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createTestimonialInfoThunk.fulfilled, (state, action) => {})
      //certificate info
      .addCase(fetchCertificationThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchCertificationThunk.fulfilled, (state, action) => {
        state.certification = action.payload.data;
        state.certificationTotalNo = action.payload.total;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchCertificationThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createCertificationThunk.fulfilled, (state, action) => {})
      .addCase(deleteCertificationThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var data = state.certification.filter((ele) => ele.id != id);
        // state.certification = data;
        // return state;
      })

      //platform review info
      .addCase(fetchPlatformReviewThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchPlatformReviewThunk.fulfilled, (state, action) => {
        state.platformReviewList = action.payload.data;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchPlatformReviewThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createPlatformReviewThunk.fulfilled, (state, action) => {})
      .addCase(deletePlatformReviewThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deletePlatformReviewThunk.fulfilled, (state, action) => {})
      .addCase(deletePlatformReviewThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      //tech stack forte
      .addCase(fetchTechStackForteThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchTechStackForteThunk.fulfilled, (state, action) => {
        state.techStackForteList = action.payload.data;
        state.isLoading = false;
        return state;
      })
      .addCase(fetchTechStackForteThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createTechStackForteThunk.fulfilled, (state, action) => {})
      .addCase(deleteTechStackForteThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(deleteTechStackForteThunk.fulfilled, (state, action) => {})
      .addCase(deleteTechStackForteThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      //expertise breakup info
      .addCase(fetchExpertisebreakupThunk.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchExpertisebreakupThunk.fulfilled, (state, action) => {
        state.expertisebreakupList = {
          ...state.expertisebreakupList,
          ...action.payload.data,
        };
        state.isLoading = false;
        return state;
      })
      .addCase(fetchExpertisebreakupThunk.rejected, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(createExpertisebreakupThunk.fulfilled, (state, action) => {})
      .addCase(deleteExpertisebreakupThunk.fulfilled, (state, action) => {})
      //past work tabs info
      .addCase(fetchPastworkThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPastworkThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pastworkdata = action.payload.data;
        state.totalcountpastwork = action.payload.total;
      })

      .addCase(fetchPastworkThunk.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchTechDataThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTechDataThunk.fulfilled, (state, action) => {
        const { frontend, backend } = action.payload;
        state.frontend = frontend;
        state.backend = backend;
        state.isLoading = false;
      })

      .addCase(fetchTechDataThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(createPastworkThunk.fulfilled, (state, action) => {})

      .addCase(deletePastworkThunk.fulfilled, (state, action) => {});
  },
});

export const { clearVendorProfile } = vendorSlice.actions;

export default vendorSlice.reducer;
