import React from "react";
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Draggable } from "react-beautiful-dnd";

function Item({ task, index }) {
  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Box pl={1} pr={1} pb={1}>
            <Card
              sx={{
                borderRadius: "5px",
                boxShadow: "0 1px 6px 0 rgb(32 33 36 / 28%)",
              }}
            >
              <Typography p={4} pt={2} pl={2}>
                {task.content}
              </Typography>
              {/* <Box p={2}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="center">
                    <Avatar
                      sx={{ bgcolor: "#00AB55", width: 24, height: 24 }}
                      variant="rounded"
                    >
                      <AssignmentIcon sx={{ width: 15, height: 15 }} />
                    </Avatar>
                    <Typography
                      ml={1}
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                    >
                      ERM1-94
                    </Typography>
                  </Stack>
                  <Box>
                    <Avatar
                      sx={{
                        bgcolor: "#00AB55",
                        fontSize: 14,
                        width: 26,
                        height: 26,
                      }}
                    >
                      KS
                    </Avatar>
                  </Box>
                </Stack>
              </Box> */}
            </Card>
          </Box>
        </div>
      )}
    </Draggable>
  );
}

export default Item;
