import { LeftOutlined } from "@ant-design/icons";
import { ConfigProvider, Input, Tabs, Tooltip, Typography, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import toast from "react-hot-toast";
import { getAccessToken } from "../../utils/global/auth.global";
// import {
//   getServicesListThunk,
//   postServicesThunk,
// } from "../store/slices/onboard/onboard.slice";
import "./services.css";
import { Button, Card } from "@mui/material";
import withBusinessProfile from "../../hoc/withBusinessProfile";
import {
  getSelectedServicesListThunk,
  getServicesListThunk,
  postServicesThunk,
  updateServicesThunk,
} from "../../store/slices/onboard/onboard.slice";
import { v4 as uuidv4 } from "uuid";

const VendorServices = () => {
  const dispatch = useDispatch();
  const {
    servicesList = [],
    selectedServicesList = { service_ids: [], custom_service_ids: [] },
  } = useSelector(({ onBoard }) => onBoard.data || {});

  const [selectedServices, setSelectedServices] = useState([]);

  const [customServices, setCustomServices] = useState(() => {
    if (typeof window !== "undefined") {
      return JSON.parse(localStorage.getItem("customServices")) || [];
    }
    return [];
  });
  const [currentCustomService, setCurrentCustonService] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("1");

  function handleCustomChange(deleteId) {
    if (deleteId) {
      const newServices = customServices.filter((ele) => ele.id !== deleteId);
      return setCustomServices(newServices);
    }
    setCustomServices([
      ...customServices,
      {
        name: currentCustomService,
        id: uuidv4(),
      },
    ]);
    setCurrentCustonService("");
  }

  useEffect(() => {
    setSelectedServices(selectedServicesList?.service_ids || []);
    setCustomServices(
      selectedServicesList?.custom_service_ids?.map((serviceId) => ({
        name: serviceId, // Adjust this to get the actual name if necessary
        id: uuidv4(),
      })) || []
    );
  }, [selectedServicesList]);

  useEffect(() => {
    dispatch(getServicesListThunk());
    dispatch(getSelectedServicesListThunk());
  }, [dispatch]);

  const handleSubmit = () => {
    dispatch(
      updateServicesThunk({
        selectedServices,
        customServices:
          customServices.length > 0
            ? customServices.map((ele) => ele.name)
            : undefined,
      })
    ).then((res) => {
      if (res.type === "updateServices/fulfilled") {
        // toast.success("Services Added Successfully");
        // handleNextStepClick();
        localStorage.removeItem("selectedServices");
      }
    });
  };

  const handleServiceClick = (id) => {
    if (selectedServices?.length > 0) {
      if (selectedServices?.includes(id)) {
        setSelectedServices(selectedServices?.filter((item) => item !== id));
      } else {
        setSelectedServices((prev) => [...prev, id]);
      }
    } else {
      setSelectedServices([id]);
    }
  };

  const handleRemoveService = (id) => {
    setSelectedServices((prev) => prev.filter((item) => item !== id));
  };

  return (
    <>
      <Card
        sx={{
          paddingRight: "14px",
        }}
      >
        <div className="flex items-start justify-between">
          <section className="w-full">
            <h1
              className="text-3xl text-slate-600 mb-6"
              style={{
                margin: "0px 20px",
                marginTop: "30px",
              }}
            >
              Add Services
            </h1>
            <div className="my-4 mt-8 w-full flex">
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      /* here is your component tokens */
                      inkBarColor: "rgb(249 115 22)", //Color of indicator
                      itemActiveColor: "rgb(249 115 22)", //Text color of active tab
                      itemHoverColor: "rgb(251 146 60)", //Text color of hover tab
                      itemSelectedColor: "rgb(251 146 60)", //Text color of selected tab
                    },
                  },
                }}
              >
                <Tabs
                  animated
                  tabPosition={"left"}
                  color="orange"
                  className="w-[70%]"
                  style={{ minWidth: "70%" }}
                  activeKey={activeTabKey}
                  onChange={setActiveTabKey}
                  items={[
                    ...servicesList?.map((ele, i) => {
                      const id = String(i + 1);
                      return {
                        label: (
                          <>
                            <Tooltip
                              placement="bottomLeft"
                              title={ele.title}
                              key={ele._id}
                            >
                              <p
                                className={`text-xl w-[250px] text-left  transition-colors duration-100 p-0 
                            
                            `}
                              >
                                {ele.title}
                              </p>
                            </Tooltip>
                          </>
                        ),
                        key: id,
                        children: (
                          <>
                            <div className="flex items-center flex-wrap">
                              {ele?.sub_services?.map((subEle) => (
                                <div
                                  className={` rounded-xl p-2 px-4 m-2 cursor-pointer text-lg tracking-wider ${
                                    selectedServices.includes(subEle._id)
                                      ? "border-orange-500 text-orange-400 border-2 shadow-lg font-semibold "
                                      : "border-2"
                                  }`}
                                  onClick={() => handleServiceClick(subEle._id)}
                                  key={subEle._id}
                                >
                                  <span>{subEle.title}</span>
                                </div>
                              ))}
                            </div>
                          </>
                        ),
                      };
                    }),
                    {
                      label: (
                        <span style={{ fontSize: "1.25rem" }}>
                          Add Custom Service
                        </span>
                      ),
                      key: "custom",
                      children: (
                        <>
                          <div className="p-4">
                            <Typography.Title level={5}>
                              Enter Your Custom Service
                            </Typography.Title>
                            <Input
                              value={currentCustomService}
                              onChange={(e) => {
                                setCurrentCustonService(e.target.value);
                              }}
                              placeholder="Enter your custom service"
                              style={{
                                width: "70%",
                                height: "40px",
                                fontSize: "18px",
                                padding: "20px",
                                marginRight: "20px",
                              }}
                            />
                            <Button
                              style={{ height: "40px", width: "100px" }}
                              type="primary"
                              htmlType="button"
                              onClick={() => handleCustomChange()}
                            >
                              Add
                            </Button>
                          </div>
                          {customServices.map((service) => (
                            <Tag
                              style={{
                                marginLeft: "20px",
                                fontSize: "16px",
                                padding: "5px",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                              closeIcon
                              onClose={(e) => {
                                e.preventDefault();
                                handleCustomChange(service.id);
                              }}
                              key={service.id}
                            >
                              {service.name}
                            </Tag>
                          ))}
                        </>
                      ),
                    },
                  ]}
                />
              </ConfigProvider>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-evenly",
                  minWidth: "30%",
                }}
              >
                <div
                  className="border-l min-w-[26%] pl-4"
                  style={{ minWidth: "26%" }}
                >
                  <h2 className="font-semibold text-2xl mb-4">
                    Your selected services...
                  </h2>
                  {selectedServices.length > 0 ? (
                    <>
                      {selectedServices.map((ele) => {
                        return (
                          <>
                            <div
                              key={ele}
                              className="flex gap-2 items-center justify-between text-lg mb-2 p-2 pr-4 border rounded-lg"
                              style={{ marginBottom: "10px" }}
                            >
                              <span>
                                {servicesList?.map((service) =>
                                  service.sub_services.map((subService) => {
                                    if (subService?._id === ele) {
                                      return (
                                        <span key={subService._id}>
                                          {subService.title}
                                        </span>
                                      );
                                    }
                                    return null;
                                  })
                                )}
                              </span>
                              <span
                                className="border px-2 rounded-lg border-red-500 text-red-500 hover:text-red-700 hover:border-red-700 hover:font-semibold cursor-pointer"
                                onClick={() => handleRemoveService(ele)}
                              >
                                x
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <h4 className="text-lg text-slate-500 italic">
                        No Services Selected yet.
                      </h4>
                    </>
                  )}
                </div>
                <div
                  className="border-l min-w-[26%] pl-4"
                  style={{ minWidth: "26%", marginTop: "30px" }}
                >
                  <h2 className="font-semibold text-2xl mb-4">
                    Your custom services...
                  </h2>
                  {customServices.length > 0 ? (
                    <>
                      {customServices.map((ele) => {
                        return (
                          <>
                            <div
                              key={ele.id}
                              className="flex gap-2 items-center justify-between text-lg mb-2 p-2 pr-4 border rounded-lg"
                              style={{ marginBottom: "10px" }}
                            >
                              <span>{ele.name}</span>
                              <span
                                className="border px-2 rounded-lg border-red-500 text-red-500 hover:text-red-700 hover:border-red-700 hover:font-semibold cursor-pointer"
                                onClick={() => handleCustomChange(ele.id)}
                              >
                                x
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <h4 className="text-lg text-slate-500 italic">
                        No Custom Services Selected yet.
                      </h4>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="w-full flex justify-end my-8 p-8">
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ float: "right" }}
          >
            Save
          </Button>
        </div>
      </Card>
    </>
  );
};
export default withBusinessProfile(VendorServices);
