import { Button } from "@mui/material";

export const steps = [
  {
    selector: '[data-tut="reactour__iso"]',
    content: (
      <div>
        <h4 style={{ marginBottom: "10px" }}>Welcome to EMB!</h4>
        <p style={{ marginBottom: "10px" }}>
          We&apos;re thrilled to have you onboard!
        </p>
        <p>
          Let&apos;s dive into a quick guided tour to help you navigate our
          platform&apos;s key features.
        </p>
        <p style={{ marginTop: "10px" }}>Let&apos;s get started!</p>
        {/* <Button
          type="submit"
          color="primary"
          variant="contained"
          size="small"
          style={{ marginTop: "15px" }}
        >
          Watch Video
        </Button> */}
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__leads"]',
    content: (
      <div>
        <h4 style={{ marginBottom: "10px" }}>Leads:</h4>
        <p style={{ marginBottom: "10px" }}>
          These are the potential deals that you have been shortlisted for.
        </p>
        <p>Review each deal, and pitch to win new projects & clients.</p>
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__clients"]',
    content: (
      <div>
        <h4 style={{ marginBottom: "10px" }}>Clients:</h4>
        <p style={{ marginBottom: "10px" }}>
          Track your ongoing projects with existing clients you have closed
          deals with on EMB.
        </p>
        <p>
          You can keep a track and organise your running projects and their
          details on this tab.
        </p>
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__profile"]',
    content: (
      <div>
        <h4 style={{ marginBottom: "10px" }}>Complete Your Profile:</h4>
        <p style={{ marginBottom: "10px" }}>
          {" "}
          <span style={{ fontWeight: "bold" }}>Business Information:</span>{" "}
          Enter general details about your company to let potential clients know
          who you are.
        </p>
        <p style={{ marginBottom: "10px" }}>
          {" "}
          <span style={{ fontWeight: "bold" }}>Bank Details:</span> Upload
          financial documents for verification to ensure your transactions are
          smooth and secure.
        </p>
        <p style={{ marginBottom: "10px" }}>
          {" "}
          <span style={{ fontWeight: "bold" }}>Portfolio:</span> Showcase your
          past work and client successes to attract high-quality deals.
        </p>
        <p style={{ marginBottom: "10px" }}>
          {" "}
          <span style={{ fontWeight: "bold" }}>Expertise Breakdown:</span>{" "}
          Detail the resources and team members in your company to highlight
          your capabilities.
        </p>
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__faq"]',
    content: (
      <div>
        <h3 style={{ marginBottom: "10px" }}>FAQs:</h3>
        <p style={{ marginBottom: "10px" }}>
          {" "}
          Browse our community FAQs for quick answers to common questions.
        </p>
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__support"]',
    content: (
      <div>
        <h3 style={{ marginBottom: "10px" }}>Support:</h3>
        <p style={{ marginBottom: "10px" }}>
          {" "}
          Have a specific question or issue?
          <br />
          Raise a support ticket, and your Vendor Account Manager (VAM) will
          assist you promptly.
        </p>
      </div>
    ),
  },
  {
    selector: '[data-tut="reactour__profile-progress"]',
    content: (
      <div>
        <h4 style={{ marginBottom: "10px" }}>Profile Completion Progress:</h4>
        <p style={{ marginBottom: "10px" }}>
          {" "}
          Boost your chances of landing more leads by completing your profile to
          100%!
        </p>
        <p style={{ marginBottom: "10px" }}>
          An up-to-date and comprehensive profile attracts the best
          opportunities.
        </p>
        <p>
          <em>
            Let&apos;s maximise your potential, complete your profile now!
          </em>
        </p>
      </div>
    ),
  },
];
