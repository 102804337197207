import { Box, Card, Chip, ListItem } from "@mui/material";
import TreeList from "../Modal/TreeList";
import { useEffect, useState } from "react";
import Input from "./Input";
import { useStyles } from "./Form.theme";

export default function ChipInput({
  onChange,
  onBlur,
  label,
  style,
  placeholder,
  value = [],
  errorText,
  name,
}) {
  // const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const [input, setInput] = useState("");
  const [chipsData, setChipsData] = useState([]);

  function handleTextChange(e) {
    const inputValue = e.target.value;
    if (e.key === "Enter") {
      setChipsData([...chipsData, inputValue]);
      setInput("");
      onChange({
        target: {
          name: name,
          value: [...chipsData, inputValue],
        },
      });
    }
  }

  function handleDelete(idx) {
    const copiedData = chipsData.filter((ele) => ele !== idx);
    setChipsData(copiedData);
    onChange({
      target: {
        name: name,
        value: copiedData,
      },
    });
  }

  useEffect(() => {
    if (value && typeof value === "string") {
      const chips = value?.split(",");
      setChipsData(chips);
    } else if (value && typeof value === "object") {
      setChipsData(value);
    }
  }, [value]);

  //   console.log("Value in chipInput : ", value);
  //   console.log("input value : ", input);
  //   console.log("chipdata : ", chipsData);

  return (
    <>
      <div className={classes.formGroup}>
        {label ? (
          <label className={classes.formLabel} style={style}>
            {label}
          </label>
        ) : null}
        <input
          className={classes.formControl}
          placeholder={placeholder}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleTextChange}
          value={input}
          errorText={errorText}
        />
      </div>

      {chipsData.map((val) => (
        <ListItem sx={{ display: "contents" }}>
          <Chip
            label={val}
            onDelete={() => handleDelete(val)}
            sx={{ marginRight: "1rem", marginBottom: "1rem" }}
          />
        </ListItem>
      ))}
    </>
  );
}
