//Auth
export const googleLogin = "/social_login";
export const emailLogin = "/user";
export const signup = "/user/signup";
export const validateEmailOTP = "/validate_email/otp";
export const validateMobileOTP = "/validate_mobile/otp";
export const resendEmailOTP = "/email/resend_otp";
export const signInWithPhoneApi = "/mobile_login";
export const mobileVerificationApi = "/mobile/verify";
export const emailVerificationApi = "/email/verify";
// export const fetchAllForgotPasswordsApi = "/forgot_password";
export const validateTokenApi = "/user/validate_user_password_link";
export const setPasswordApi = "/reset_password";

//Users
export const userApi = "/user";
export const getBusinessProfileApi = "/vendor/business_profile";
export const getAddressesApi = "/vendor/business_address";
export const uploadUserProfileApi = "/user/upload_profile";
export const addBusinessProfile = "/vendor/business_profile";
export const editBusinessProfile = "/vendor/business_profile";

//Onboarding - services
export const getServiceListApi = "/level-zero/service-listing";
export const postServicesApi = "vendor/level-zero/service";
export const getSelectedServicesList = "vendor/level-zero/service";

//milestones
export const getAllMilestonesApi = "/vendor/collection/all_milestones";

// profile details
export const getVendorProfileDetails = "/vendor/business_profile";
export const fetchAllVendorProfilesApi = "/vendor/get_all_business_profiles";
export const searchBusinessProfileApi = "/vendor/filter_bus_profile";
export const getNotesByID = "vendor/business_profile/get_notes";
export const getVenderExist = "check_vendor";
export const addNotesByID = "vendor/business_profile/notes";
export const updateNotesByID = "vendor/business_profile/notes";
export const businessAddressApi = "/vendor/business_addresses/new";
export const addBusinessAddress = "/vendor/business_address/new";
export const searchLanguageApi = "/search/languages";
export const searchSkillApi = "/search/skills";
export const updateLanguagesApi = "/vendor/languages";
export const updateSkillsApi = "/vendor/skills";
export const getOrgIdByProfileIdApi = "/get_profile_org";
export const deleteNoteApi = "/vendor/business_profile/notes";
export const advanceFilterApi = "/vendor/filter_bus_profile";

//leads
export const getVendorLeadsAPI = "/vendor/get_leads";
export const getProjectAPI = "/project";
export const acceptLeadAPI = "/vendor/action_project_lead";
export const UploadProposalAPI = "/upload_proposal";
export const uploadAttachmentAPI = "/upload/project/attachment";
export const rejectLeadAPI = "/vendor/action_project_lead";
export const getProposalsByLeadIdApi = "/vendor/proposals";

//forgotpassword
export const fetchAllForgotPasswordsApi = "/forgot_password";
export const userportfolioApi = "/vendor/add_portfolio/1";
export const portfolioApi = "/vendor/portfolio";
export const getAllPortfoliosApi = "/vendor/get_profile_portfolios";

// Charter
export const fetchProjectCharterByProjectIdApi = "/project_charter";
export const fetchProjectTimelineByProjectIdApi = "/vendor/project_table";
export const commentsCharterApi = "/charter/comment";
export const filesCharterApi = "/charter/file_upload";
export const updatePorjectCharterApi = "/vendor_charter";
export const createProjectTimelineApi = "/project_timeline_charter";

// Dashboard
export const projectMatrixApi = "/vendor_dashboard_metrics";

//Clients
export const createProjectSupportTicketApi = "/support";
export const reportedIssueApi = "/support/get_tickets";
export const fetchAllSupportCommentApi = "/support/comments";
export const createSupportCommentApi = "/support/comment";
export const globalReportedIssueApi = "/support_ticket";
export const fetchAllProjectsApi = "/vendor/projects";
export const proposalApi = "/vendor/proposals";

// Notifications
export const fetchAllNotificationsApi = "/get_all_user_notifications/";
export const markNotificationAsReadApi = "/mark_push_notification_as_read";
export const markAllNotificationsAsReadApi =
  "/mark_all_push_notification_as_read";
export const fetchAllSupportApi = "/support/get_tickets";

// Project Table
export const createNewSectionApi = "/vendor/project_table/add_new_section";
export const deleteNewSectionApi = "/vendor/project_table/remove_section";
export const newRowApi = "/vendor/project_table/row";
export const removeRowApi = "/vendor/project_table/remove_row";
export const newColumnApi = "/vendor/project_table/column";
export const removeColumnApi = "/vendor/project_table/remove_column";
export const shiftRowApi = "/vendor/project_table/row_shift";
export const columnShiftApi = "/vendor/project_table/column_shift";
export const addChoiceApi = "/vendor/project_table/column_choice/add";
export const removeChoiceApi = "/vendor/project_table/column_choice/remove";
export const publishSectionDataApi = "/published_project_table";
export const updateSectionNameApi = "/vendor/project_table/update_section_name";

// Kanban
export const changeRowStatusApi = "/vendor/project_table/change_row_status";

// Gantt
export const updateStartEndDateApi =
  "/vendor/project_table/update_start_end_date";

//Wallet
export const getWalletDetailsApi = "/vendor/wallet_transactions";

// vendor services
export const getAllVendorServices = "/get_vendor_services";
export const searchVendorServiceApi = "/get_vendor_services";
export const deleteVendorServices = "/vendor_service";
export const addVendorServices = "/vendor_service";
export const searchServiceApi = "/service/search_service";
export const addCustomServiceApi = "/vendor_service";

export const businessProfileApi = "/vendor/business_profile";
export const vendorService = "/vendor_service";
export const addUploadPortfolioForPortfolio = "/vendor/portfolio_files";

// service
export const getServiceTagsApi = "/get_service_tags";
export const getServiceTeckStacksApi = "/get_service_tech_stacks";
export const searchIndustryApi = "/industries/search_industry";

// portfolio
export const deletePortfolio = "/vendor/portfolio";
export const addPortfolio = "/vendor/portfolio";

//support
export const ticketstatusApi = "/change_support_ticket/state";
export const createSupportApi = "/support";

// Turnover
export const fetchTurnoverForLast3YearsApi = "/vendor/last_three_turnovers";
export const turnoverApi = "/vendor/turnover/new";

// leads section api urls ----------------------------------------------------------------
export const fetchAllLeadsApi = "/vendor/get_leads_data";
export const getLeadsDetailsApi = "/vendor/get_lead_detail_data";
export const vendorUploadProposalApi = "/vendor/upload_proposal";

// RA
// export const fetchAllRA = "/ra/partner_leads";
export const fetchAllRA = "/ra/partner_leads/new";
export const fetchRAById = "/ra/partner_lead";
export const fetchRAByLineItems = "/supply/ra_lead/line_item";
// export const fetchAllCVs = "/ra/line_items";
export const fetchAllCVs = "/ra/line_items/new";
// export const addVendorCV = "/ra/line_item";
export const addVendorCV = "/ra/line_item/new";
export const attachVendorCVs = "/ra/partner/attach_cv";
export const notInterested = "/ra/partner_lead/change_status";

//sass channel profile api url
export const getVendorSassDetailsApi =
  "/vendor/business-profile/get-saas-partnership";
export const deleteVendorSassProfile =
  "/vendor/business-profile/saas-partnership";
export const createVendorSassProfile =
  "/vendor/business-profile/saas-partnership";

//certificate info api
export const getCertificateInfoApi =
  "/vendor/business-profile/get-certifications";
export const createCertificateInfoApi =
  "/vendor/business-profile/certification";
export const deleteCertificateInfoApi =
  "/vendor/business-profile/certification";

//testimonial info api
export const getTestimonialInfoApi =
  "/vendor/business-profile/get-testimonials";
export const deleteTestimonialInfoApi = "/vendor/business-profile/testimonial";
export const createTestimonialInfoApi = "/vendor/business-profile/testimonial";

//product code options api
export const getProductCodeOptionsApi = "/vendor/product-codes";

//social media rating info api
export const getPlatformReviewApi = "/vendor/business-profile/platform-review";
export const createPlatformReviewApi =
  "/vendor/business-profile/platform-review";
export const deletePlatformReviewApi =
  "/vendor/business-profile/platform-review";

//expertise breakup info api
export const getExpertiseBreakupApi = "/vendor/expertise/new";
export const createExpertiseBreakupApi = "/vendor/expertise/new";
export const deleteExpertiseBreakupApi = "/vendor/expertise/info/new";

//poc info api
export const addPOCApi = "/vendor/contact/new";
export const fetchAllPOCApi = "/vendor/contacts";
export const updatePOCApi = "/vendor/contact/new";
export const deletePOCApi = "/vendor/contact";

// Accounts
export const getAssignedVendorApi = "/vendor/business_profile/assign_vendor";

//founder profile info
export const getFounderProfileApi = "/vendor/business-profile/get-founders";
export const createFounderProfileApi = "/vendor/business-profile/founder";
export const deleteFounderProfileApi = "/vendor/business-profile/founder";

//tech stack forte info api
export const getTechStackForteApi = "/vendor/business-profile/get-tech-stacks";
export const createTechStackForteApi = "/vendor/business-profile/tech-stack";
export const deleteTechStackForteApi = "/vendor/business-profile/tech-stack";

//bench strength info
export const getBenchStrengthInfoApi =
  "/vendor/business-profile/bench-strengths";
export const createBenchStrengthInfoApi =
  "/vendor/business-profile/bench-strength";
export const deleteBenchStrengthInfoApi =
  "/vendor/business-profile/bench-strength";

//bench strength
export const searchBenchStrengthLocationApi =
  "/vendor/business-profile/india-cities";
export const searchBenchResourceBifurcationApi =
  "/vendor/bench-strength/resource-bifurcations";

// resource bifurcation
export const getResourceBifurcationListApi =
  "/vendor/bench-strength/resource-bifurcations";
export const getTechStackListApi = "/vendor/bench-strength/tech-stacks";
export const fetchAllProductCodeListApi = "/vendor/product-codes";

// new services and industryApis
export const fetchAllServicesApi = "/service/search_service/new";
export const fetchAllIndustriesApi = "/industries/search_industry/new";
export const fetchAllDeliveryClassApi = "/vendor/get-delivery-class";
export const fetchSubIndustryApi = "/sub-industry";
export const fetchResourceListApi =
  "/vendor/past-work/resource-bifurcation/tech-stacks";

//product code api
export const searchProductCodeIndustryApi = "/industries/search_industry";
export const searchProductCodeSubIndustryApi = "/sub-industry";
export const searchProductCodeDeliveryClassApi = "/vendor/get-delivery-class";
export const getAllProductCodeListApi = "/vendor/get-product-codes";
export const productCodeApi = "/vendor/product-code";

//past work info
export const getAllPastWorkApi = "/vendor/get-pastworks";
export const pastworkApi = "/vendor/portfolio/new";
export const getTechDataApi =
  "/vendor/past-work/resource-bifurcation/tech-stacks";

// upload portfolio
export const getAllUploadPortfolio = "/vendor/portfolio_files";
export const deleteUploadPortfolio = "/portfolio";
export const addUploadPortfolio = "/vendor/portfolio_files";

// expertise_breakup
export const getAllExpertise = "/vendor/all_expertise";
export const deleteExpertise = "/vendor/expertise";
export const updateExpertise = "/vendor/expertise";
export const addExpertise = "/vendor/expertise";
export const updateDeleteAddExpertise = "/vendor/expertise";

export const profileCountApi = "/partner/login-count";
