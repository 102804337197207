import React, { useEffect, useState } from "react";
import { Grid, Box, Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

const CustomTabs = ({ labels }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (e, val) => {
    setSelectedTab(val);
  };


  const location = useLocation();

  useEffect(() => {
    const idx = labels.findIndex((label) =>
      location.pathname.includes(label.value)
    );
    if (idx >= 0) {
      setSelectedTab(idx);
    }
  }, [labels, location.pathname]);

  return (
    <Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          {labels &&
            labels.map((label, i) => {
              return (
                <Tab
                  key={i}
                  label={label.label}
                  component={Link}
                  to={label.value}
                />
              );
            })}
        </Tabs>
      </Box>
    </Grid>
  );
};

export default CustomTabs;
