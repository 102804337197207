import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  nonePadding: {
    "& svg": {
      bottom: "14px !important",
    },
  },
  "@media (max-width:600px)": {
    dBlock: {
      display: "block",
    },
    nonePadding: {
      padding: "0px  !important",
    },
  },
});
