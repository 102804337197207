import React from "react";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { useStyles } from "./TreeList.theme.js";

function TreeListNew({
  id,
  path,
  onChange,
  onBlur,
  title,
  setOpen,
  name,
  idName = "service_id",
  valueName = "service_name",
}) {
  const classes = useStyles();

  function GetLi({ value, icon, beforeIcon }) {
    return (
      <li className={classes.nest_list} className={classes.link}>
        {beforeIcon && <ArrowRightAltIcon />}{" "}
        <span style={{ fontSize: "14px" }}>{value}</span>
        {icon && <ChevronRightOutlinedIcon />}
      </li>
    );
  }

  return (
    <ul
      className={classes.main_list}
      onClick={() => {
        setOpen(false);
        onChange({
          target: {
            name: name,
            value: {
              [idName]: id,
              [valueName]: title,
            },
          },
        });
      }}
    >
      {path.map((ele, idx) => (
        <GetLi
          value={ele}
          beforeIcon={idx === 0}
          icon={idx < path.length - 1}
        />
      ))}
    </ul>
  );
}

export default TreeListNew;
