import {
  fetchAllSupportApi,
  createProjectSupportTicketApi,
  fetchAllSupportCommentApi,
  createSupportCommentApi,
  ticketstatusApi,
  createSupportApi,
} from "../../../utils/apis.utils";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { createQueryString } from "../../../utils/global/global";

export const createSupportTicketHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createProjectSupportTicketApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const fetchAllSupportTicketsHandler = async (__, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(fetchAllSupportApi);
  return response.data;
};

export const fetchAllGlobalTicketsDataByTicketIdHanldler = async (
  { project_id, support_id },
  thunkApi
) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const query = createQueryString({
      project_id: project_id,
    });
    let url = `${fetchAllSupportCommentApi}/${support_id}`;
    if (project_id) {
      url += query;
    }
    const response = await user.get(url);
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const createGlobalSupportTicketCommentHandler = async (
  data,
  thunkApi
) => {
  try {
    // thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createSupportCommentApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    // thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updateTicketStatusHandler = async (data, thunkApi) => {
  try {
     
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(ticketstatusApi, data);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  }
};

export const createReportedIssue = async (data, thunkApi) => {
   
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createSupportApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
     
    return response.data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
  }
};
