import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { checkForTokenValidityAsyncThunk } from "../store/slices/init/init.slice";
import Loader from "../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { storeAccessToken } from "../utils/global/auth.global";

function withAuth(Component) {
  return function ({ ...props }) {
    const navigate = useNavigate();
    const pathname = window.location.pathname;

    const isInitAuthCheckedDone = useSelector(
      ({ init }) => init.isInitAuthCheckedDone
    );
    const dispatch = useDispatch();
    useEffect(() => {
      if (pathname.includes("/auth")) {
        const token = pathname.split("/")[2];
        if (token) {
          storeAccessToken(token);
          navigate("/dashboard");
        }
      }
      dispatch(checkForTokenValidityAsyncThunk());
    }, [dispatch]);

    if (isInitAuthCheckedDone) return <Component {...props} />;
    return <Loader />;
  };
}

export default withAuth;
