import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#11a64a",
      boxRadius: "8px",
    },
  },
});
