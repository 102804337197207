import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import Scrollbar from "./../../Scrollbar";
import ListHead from "./../../common/ListHead";
import users from "../../../_mocks_/user";
import { capitalCase } from "../../../utils/change-case";
import { useDispatch, useSelector } from "react-redux";
import {
  getAcceptedLeadThunk,
  selectLead,
} from "../../../store/slices/lead/lead.slice";
import { useNavigate } from "react-router-dom";
import SearchNotFound from "../../NotFound/SearchNotFound";

const TABLE_HEAD = [
  { id: "projectName", label: "Project Name", alignRight: false },
  { id: "clientName", label: "Client Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "detail", label: "Detail", alignRight: false },
];

const LeadsTableAccepted = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAcceptedLeadThunk());
  }, [dispatch]);

  const acceptedLeads = useSelector(({ leads }) => leads.acceptedLeads);

  const handleView = (data) => {
    dispatch(selectLead(data));
    navigate({
      pathname: "/dashboard/requirement/" + data.project.project_id + "/overview",
    });
  };

  if (acceptedLeads?.length === 0) return <SearchNotFound />;

  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, padding: "20px" }}>
            <Table>
              <ListHead headLabel={TABLE_HEAD} rowCount={users.length} />
              <TableBody>
                {acceptedLeads
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, id) => {
                    const { client, project, lead_status } = row;

                    return (
                      <TableRow key={id} tabIndex={-1} role="checkbox">
                        <TableCell >
                          <Typography noWrap>
                            {capitalCase(project?.project_name)}
                          </Typography>
                        </TableCell>
                        <TableCell >
                          {capitalCase(client?.client_name)}
                        </TableCell>
                        <TableCell >
                          <Chip
                            label={capitalCase(lead_status)}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell >
                          <Button onClick={() => handleView(row)}>View</Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
};

export default LeadsTableAccepted;
