import React, { useEffect, useRef } from "react";
import { Document, Page as ReactPDF } from "react-pdf";
import {
  Stack,
  Typography,
  Container,
  Card,
  Link,
  Grid,
  Box,
  Avatar,
  Tooltip,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import { useStyles } from "./Proposal.theme";
import { styled } from "@mui/material/styles";
// import Page from "../../../Page";
import Page from "../../components/Page";
import { DateTime } from "luxon";
import {
  fetchLeadsDetailsThunk,
  vendorUploadProposalApiThunk,
} from "../../store/slices/leadsProject/projectleads.slice";
import Modal from "../../components/Modal/Modal";
import useModal from "../../hooks/useModal";
import { useState } from "react";

import { tooltipClasses } from "@mui/material/Tooltip";

import SearchNotFound from "../../components/NotFound/SearchNotFound";
import ProposalModal from "./ProposalModal";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
export default function LeadProposal() {
  const { leadsOverviewAndProposal } = useSelector(
    ({ projectleads }) => projectleads
  );

  const params = useParams();
  const classes = useStyles();
  const { isModalOpen, showModal, hideModal } = useModal();
  const [isFileUpload, setIsFileUpload] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchLeadsDetailsThunk(params.id));
  }, [params.id]);

  const inputRef = useRef();

  function handleFileChange(e) {
    showModal();
    setIsFileUpload(e);
  }

  function uploadFileClick() {
    var formData = new FormData();
    formData.append("file", isFileUpload.target.files[0]);
    formData.append("uploaded_by", "vendor");
    formData.append("deal_id", params.id);

    var newFormData = {
      formData: formData,
      deal_id: params.id,
    };

    dispatch(vendorUploadProposalApiThunk(newFormData));
    hideModal();
  }
  function uploadFileCancelClick() {
    inputRef.current.click();
  }

  return (
    <Page title="Proposal | EMB">
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg, application/pdf"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleFileChange}
      />

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          sx={{ float: "right" }}
        >
          {leadsOverviewAndProposal?.can_upload_proposal && (
            <Button
              onClick={() => inputRef.current.click()}
              variant="contained"
              className={classes.propsalBtn}
            >
              Upload proposal
            </Button>
          )}
        </Stack>
        {leadsOverviewAndProposal?.time_line?.length === 0 ? (
          <div style={{ padding: "3rem" }}>
            <SearchNotFound
              src="/static/not-found/no_leads_yet.png"
              subtitle="No proposals yet."
              title=" "
            />
          </div>
        ) : (
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12}>
              {leadsOverviewAndProposal?.time_line?.map((data, i) => (
                <>
                  {i != 0 && <div className={classes.vl}></div>}
                  {data.type == "proposal" ? (
                    <Card className={classes.attachePropsal}>
                      <Box component="div" className={classes.attachFile}>
                        <Box component="div" className={classes.attachMedia}>
                          <Box component="span" className={classes.attachIcon}>
                            <a href={data.s3_file_URL} download target="_blank">
                              <img
                                alt="register"
                                src="/static/vectors/chp.png"
                                width={80}
                                height={100}
                              />
                            </a>
                          </Box>
                          <Box
                            component="div"
                            ml={2}
                            sx={{ position: "relative" }}
                          >
                            <Typography
                              variant="body2"
                              component="h4"
                              className={classes.attachFileHeading}
                              sx={{ fontWeight: "700" }}
                            >
                              {data.file_name}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="h4"
                              className={classes.attachFileHeading}
                              sx={{ fontWeight: "500" }}
                            >
                              Date:{" "}
                              {DateTime.fromISO(data.created_at).toFormat("DD")}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="h4"
                              className={classes.attachFileHeading}
                              sx={{ fontWeight: "500" }}
                            >
                              Uploaded by
                              {data.uploaded_by === "vendor" ? " you" : " EMB"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  ) : (
                    ""
                  )}

                  {data.type == "text" ? (
                    <Box component="div" className={classes.propsalContent}>
                      <Card className={classes.parentCard}>
                        <Typography
                          variant="body2"
                          component="h4"
                          sx={{ fontWeight: "700", fontSize: "18px" }}
                        >
                          Remarks:
                        </Typography>
                        <Box
                          component="div"
                          ml={2}
                          sx={{ position: "relative" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "500" }}
                            className={classes.proposalTitle}
                          >
                            {data.reason}
                          </Typography>
                        </Box>
                      </Card>
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </Grid>
          </Grid>
        )}

        <Modal
          maxWidth="lg"
          isOpen={isModalOpen}
          closeModal={hideModal}
          content={
            <ProposalModal
              uploadFileCancelClick={uploadFileCancelClick}
              uploadFileClick={uploadFileClick}
            />
          }
          contentStyle={{ height: "200px", width: "500px" }}
        />
      </Container>
    </Page>
  );
}
