import React, { useEffect, useState } from "react";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getGanttChartData,
  getStartEndColumnIndex,
  getStartEndDateForProject,
} from "../../../utils/timeline.utils";
import { useParams } from "react-router-dom";
import { fetchProjectTimelineThunk } from "../../../store/slices/project/project.slice";
import { userAxios } from "../../../utils/axios/user.axios";
import { updateStartEndDateApi } from "../../../utils/apis.utils";
import { DateTime } from "luxon";
import SearchNotFound from "../../NotFound/SearchNotFound";

function GanttChart() {
  const { timelineRef } = useSelector(({ project }) => project);

  const [tasks, setTasks] = useState([]);

  const [colIds, setColIds] = useState([]);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (!timelineRef) return;
    setColIds(getStartEndColumnIndex(timelineRef));
    setTasks(getGanttChartData(timelineRef));
  }, [timelineRef]);

  useEffect(() => {
    dispatch(fetchProjectTimelineThunk(params.id));
  }, [dispatch, params.id]);

  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
      setTasks(newTasks);
    }

    const rowId = task.id;
    const [startColId, endColId] = colIds;

    userAxios.put(updateStartEndDateApi, {
      project_table_id: timelineRef.project_table_id,
      row_id: rowId,
      start_col_id: startColId,
      start_date: DateTime.fromJSDate(task.start).toUTC().toString(),
      end_col_id: endColId,
      end_date: DateTime.fromJSDate(task.end).toUTC().toString(),
    });
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {};

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  if (tasks.length === 0) return <SearchNotFound />;
  return (
    <Gantt
      tasks={tasks}
      onDateChange={handleTaskChange}
      onProgressChange={handleProgressChange}
      onDoubleClick={handleDblClick}
      onSelect={handleSelect}
      onExpanderClick={handleExpanderClick}
      viewMode={ViewMode.Day}
    />
  );
}

export default GanttChart;
