import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getCookiesToken } from "../../utils/global/auth.global";

function AuthProtector({ children }) {
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);

  //new condition added
  // const token = getCookiesToken()

  if (!isLoggedIn) return <Navigate to="/login" />;

  return children;
}

export default AuthProtector;
