import React from "react";
import {
  Stack,
  Card,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import Modal from "../../components/Modal/Modal";
import MilestoneModal from "../../components/Modal/MilestoneModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCollectionsByProjectThunk } from "../../store/slices/project/project.slice";
import ListHead from "../../components/common/ListHead";
import { DateTime } from "luxon";
import SearchNotFound from "../../components/NotFound/SearchNotFound";

//declaring column heading
const columns = [
  {
    id: "m_name",
    label: "Mil. Name",
    align: "left",
    minWidth: 180,
  },
  // { id: "project_id", label: "Project ID", align: "left", minWidth: 150 },
  {
    id: "percent_release",
    label: "% Release of project",
    align: "center",
    minWidth: 150,
  },
  {
    id: "m_amount",
    label: "Mil. Amount",
    align: "center",
    minWidth: 150,
  },
  {
    id: "release_amount",
    label: "Released Amount.",
    align: "center",
    minWidth: 200,
  },
  { id: "timeline", label: "Timeline", align: "center", minWidth: 150 },

  // {
  //   id: "invoice",
  //   label: "Invoice",
  //   align: "left",
  //   minWidth: 150,
  // },
  {
    id: "invoice_approved",
    label: "Invoice App.",
    align: "center",
    minWidth: 150,
  },
  // {
  //   id: "payment_released",
  //   label: "Amt. Released",
  //   align: "left",
  //   minWidth: 200,
  // },

  {
    id: "delivery_date",
    label: "Delivery Date",
    align: "center",
    minWidth: 150,
  },
  {
    id: "m_completed ",
    label: "Mil. Completed ",
    align: "center",
    minWidth: 150,
  },
];

export default function Collections() {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(fetchAllCollectionsByProjectThunk(params.id));
  }, [dispatch, params.id]);

  const collection = useSelector(({ project }) => project.collections);

  if (collection.length === 0)
    return (
      <div style={{ padding: "3rem" }}>
        <SearchNotFound
          src="/static/not-found/no_milestones.png"
          subtitle="No milestone to display."
          title=" "
        />
      </div>
    );

  return (
    <>
      <Page title="Proposal | EMB">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5" gutterBottom>
            Milestones (Mil)
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: "rgb(243, 246, 248)" }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 57, minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {collection.map((row) => {
                    const {
                      project_id,
                      milestone_name,
                      actual_release,
                      milestone_amount,
                      percentage_of_project,
                      timeline,
                      invoice_approved,
                      milestone_completed,
                      expected_delivery_date,
                    } = row;

                    return (
                      <TableRow
                        key={project_id}
                        tabIndex={-1}
                        role="checkbox"
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">{milestone_name}</TableCell>
                        <TableCell align="center">
                          {percentage_of_project}%
                        </TableCell>
                        <TableCell align="center">
                          ₹ {milestone_amount}
                        </TableCell>

                        <TableCell align="center">₹ {actual_release}</TableCell>
                        <TableCell align="center">{timeline} days</TableCell>
                        <TableCell align="center">
                          {invoice_approved ? "Approved" : "Pending"}
                        </TableCell>
                        <TableCell align="center">
                          {expected_delivery_date
                            ? DateTime.fromISO(expected_delivery_date).toFormat(
                                "DD"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {milestone_completed ? "Approved" : "Pending"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {/* {proposals?.length === 0 && <SearchNotFound />} */}
        </Card>
      </Page>
    </>
  );
}
