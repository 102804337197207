import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import Scrollbar from "./../../Scrollbar";
import ListHead from "./../../common/ListHead";
import users from "../../../_mocks_/user";
import Modal from "../../Modal/Modal";
import RequirementModal from "../../Modal/RequirementModal/RequirementModal";
import { capitalCase } from "change-case";
import { useDispatch, useSelector } from "react-redux";
import { getVendorLeadThunk } from "./../../../store/slices/lead/lead.slice";
import SearchNotFound from "../../NotFound/SearchNotFound";

const TABLE_HEAD = [
  { id: "projectName", label: "Project Name", alignRight: false },
  { id: "clientName", label: "Client Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "interested", label: "Interested", alignRight: false },
];

const LeadsTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [projectId, setProjectId] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVendorLeadThunk());
  }, [dispatch]);

  const data = useSelector(({ leads }) => leads.leads);

  const handleOpen = (projectId, leadId) => {
    setShowModal(true);
    setProjectId(projectId);
    setLeadId(leadId);
  };
  const handleClose = () => setShowModal(false);

  if (data?.length === 0)
    return (
      <div style={{ padding: "3rem" }}>
        <SearchNotFound
          src="/static/not-found/no_leads_yet.png"
          subtitle="No leads yet."
          title=" "
        />
      </div>
    );

  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                styles={{
                  backgroundColor: "rgb(243, 246, 248)",
                }}
              />
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, id) => {
                    const { client, project, lead_status, lead_id } = row;

                    return (
                      <TableRow key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">
                          <Typography noWrap>{project.project_name}</Typography>
                        </TableCell>
                        <TableCell align="left">{client.client_name}</TableCell>
                        <TableCell align="left">
                          <Chip
                            label={capitalCase(lead_status)}
                            color="warning"
                            sx={{
                              color: "#f8c100",
                              backgroundColor: "#fcf7dd",
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            onClick={() =>
                              handleOpen(project.project_id, lead_id)
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <Modal
        isOpen={showModal}
        closeModal={handleClose}
        content={<RequirementModal projectId={projectId} leadId={leadId} />}
        dialogProps={{ maxWidth: "md" }}
        titleStyle={{ textAlign: "center", marginTop: 2 }}
        dialogStyle={{ margin: "0px auto" }}
        modalBoxStyle={{ width: "900px" }}
      />
    </>
  );
};

export default LeadsTable;
