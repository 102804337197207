import { Card } from "@mui/material";
import React from "react";
import { useStyles } from "./SearchServiceTree.theme";
// import "./SearchService.theme.js";

function SearchServiceTree() {
  const classes = useStyles();
  return (
    <Card sx={{ borderRadius: "10px", padding: "1rem" }}>
      <details open="" className={classes.details}>
        <summary className={classes.summary}>
          <i className={classes.fa}></i>
          <div className={classes.selector}></div>
          Fruits
        </summary>
        <details open="" className={`${classes.details} ${classes.child}`}>
          <summary className={classes.summary}>
            <i className={classes.fa}></i>
            <div className={classes.selector}></div>
            Oranges
          </summary>
        </details>
        <details open="" className={`${classes.details} ${classes.child}`}>
          <summary className={classes.summary}>
            <i className={classes.fa}></i>
            <div className={classes.selector}></div>
            Bananas
          </summary>
        </details>
        <details open="" className={`${classes.details} ${classes.child}`}>
          <summary className={classes.summary}>
            <i className={classes.fa}></i>
            <div className={classes.selector}></div>
            Apples
          </summary>
          <details open="" className={`${classes.details} ${classes.child}`}>
            <summary className={classes.summary}>
              <i className={classes.fa}></i>
              <div className={classes.selector}></div>
              Granny Smith
            </summary>
            <details open="" className={`${classes.details} ${classes.child}`}>
              <summary className={classes.summary}>
                <i className={classes.fa}></i>
                <div className={classes.selector}></div>
                Michigan
              </summary>
            </details>
            <details open="" className={`${classes.details} ${classes.child}`}>
              <summary className={classes.summary}>
                <i className={classes.fa}></i>
                <div className={classes.selector}></div>
                New York
              </summary>
            </details>
          </details>
        </details>
      </details>
      <details className={classes.details}>
        <summary className={classes.summary}>
          <i className={classes.fa}></i>
          <div className={classes.selector}></div>
          Vegetables
        </summary>
        <details className={`${classes.details} ${classes.child}`}>
          <summary className={classes.summary}>
            <i className={classes.fa}></i>
            <div className={classes.selector}></div>
            Carrots
          </summary>
          <details open="" className={`${classes.details} ${classes.child}`}>
            <summary className={classes.summary}>
              <i className={classes.fa}></i>
              <div className={classes.selector}></div>
              New York
            </summary>
          </details>
        </details>
        <details open="" className={`${classes.details} ${classes.child}`}>
          <summary className={classes.summary}>
            <i className={classes.fa}></i>
            <div className={classes.selector}></div>
            New York
          </summary>
        </details>
        <details open="" className={`${classes.details} ${classes.child}`}>
          <summary className={classes.summary}>
            <i className={classes.fa}></i>
            <div className={classes.selector}></div>
            New York
          </summary>
        </details>
      </details>
    </Card>
  );
}

export default SearchServiceTree;
