import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  Box,
  FormControl,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Stack,
} from "@mui/material";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import { green } from "@mui/material/colors";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getWalletDetailsThunk } from "./../../store/slices/wallet/wallet.slice";
import SearchNotFound from "../../components/NotFound/SearchNotFound";
import { DateTime } from "luxon";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Dropdown from "../../components/Form/Dropdown";
import ListHead from "../../components/common/ListHead";
import { transactionType } from "../../utils/enum";
import withBusinessProfile from "../../hoc/withBusinessProfile";
import { useStyles } from "./WalletCard.theme";

const TABLE_HEAD = [
  { id: "", maxWidth: 2000, align: "left" },
  { id: "name", label: " Name", maxWidth: 2000, align: "left" },
  { id: "amount", label: "Amount", maxWidth: 2000, align: "left" },
];

function Wallet() {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWalletDetailsThunk());
  }, [dispatch]);
  const { transactions, wallet_description } = useSelector(
    ({ wallet }) => wallet
  );
  const updateAmount = wallet_description?.amount;
  const [status, setStatus] = useState(transactionType.all);

  const updatedData = useMemo(() => {
    if (status === transactionType.all) return transactions;
    return transactions.filter((ele) => ele.transaction_type === status);
  }, [status, transactions]);

  return (
    <div>
      <Container sx={{ margin: 0 }}>
        <Grid container spacing={2}>
          <Grid
            md={8}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            order={{ md: 1, lg: 1, sm: 2, xs: 2 }}
          >
            <Box>
              <Typography ml={3} variant="h4">
                All Transactions
              </Typography>
              <Breadcrumb />
            </Box>
            <Box>
              <FormControl>
                <Dropdown
                  styles={{
                    position: "relative",
                    height: "40px",
                    lineHeight: "1.4375em",
                    fontSize: "1rem",
                    fontWeight: 600,
                    color: "#212B36,",
                    borderRadius: "8px",
                    backgroundColor: "#F3F6F8",
                    maxWidth: "100px",
                    margin: "1rem 1rem 0 0",
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                  options={[
                    { label: "All", value: transactionType.all },
                    { label: "Credit", value: transactionType.credit },
                    { label: "Debit", value: transactionType.debit },
                  ]}
                  icons
                  inputProps={{
                    handleChange: (e) => {
                      setStatus(e.target.value);
                    },
                  }}
                  value={status}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={8} order={{ md: 2, lg: 2, sm: 2, xs: 2 }}>
            <Box mt={2}>
              <Card>
                {updatedData?.length === 0 ? (
                  <div style={{ padding: "3rem" }}>
                    <SearchNotFound
                      src="/static/not-found/transactions.png"
                      subtitle="No transaction history."
                      title=" "
                    />
                  </div>
                ) : (
                  <div>
                    <TableContainer sx={{ maxWidth: 800 }}>
                      <Table>
                        <ListHead
                          headLabel={TABLE_HEAD}
                          rowCount={updatedData?.length}
                          styles={{ backgroundColor: "rgb(243, 246, 248)" }}
                        />
                        <TableBody>
                          {updatedData?.map((row) => {
                            const { id } = row;

                            return (
                              <TableRow key={id}>
                                <TableCell
                                  align={row.align}
                                  style={{ maxWidth: row.maxWidth }}
                                >
                                  <Box pr={2} color={green[500]}>
                                    {row.transaction_type === "debit" ? (
                                      <NorthWestIcon sx={{ color: "red" }} />
                                    ) : (
                                      <SouthEastIcon />
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  align={row.align}
                                  style={{ maxWidth: row.maxWidth }}
                                >
                                  <div>
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      {row.description}
                                    </Typography>
                                    <div
                                      style={{ display: "flex", gap: "1rem" }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.8rem",
                                          color: "#6C7B88",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: ".25rem",
                                        }}
                                      >
                                        <CalendarMonthIcon
                                          sx={{ fontSize: "1rem" }}
                                        />
                                        {DateTime.fromISO(
                                          row.created_at
                                        ).toFormat("DD")}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "0.8rem",
                                          color: "#6C7B88",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: ".25rem",
                                        }}
                                      >
                                        <AccessTimeIcon
                                          sx={{ fontSize: "1rem" }}
                                        />
                                        {DateTime.fromISO(
                                          row.created_at
                                        ).toFormat("TT")}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "0.8rem",
                                          color: "#6C7B88",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: ".25rem",
                                        }}
                                      >
                                        ID: {row.transaction_id}
                                      </Typography>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  align={row.align}
                                  style={{ maxWidth: row.maxWidth }}
                                >
                                  <Typography
                                    sx={{ fontSize: "16px", fontWeight: "500" }}
                                  >
                                    {row.transaction_type === "debit" ? (
                                      <div> ₹ {row.amount}</div>
                                    ) : (
                                      <div> ₹ {row.amount}</div>
                                    )}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </Card>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            mt={2}
            className={classes.wallet}
            order={{ md: 3, lg: 3, sm: 1, xs: 1 }}
          >
            <Card className={classes.wallet_card}>
              <Box sx={{ textAlign: "center", padding: "1rem 0.5rem" }}>
                <Grid container spacing={2} p={2}>
                  <Grid item md={12}>
                    <Box sx={{ position: "absolute", top: -25, left: 0 }}>
                      <img src="/static/ec 3.svg" alt="" />
                    </Box>
                    <Typography
                      // variant="h5"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        textTransform: "uppercase",
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      Total Earnings
                    </Typography>
                  </Grid>

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.amount}
                  >
                    <Box>
                      <Grid item md={8}>
                        <Typography
                          variant="h2"
                          sx={{ color: "#fff", marginLeft: "16px" }}
                        >
                          {updateAmount ? updateAmount : 0}
                        </Typography>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid
                        item
                        md={4}
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          textAlign: "right",
                          paddingLeft: "0",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "20px",
                            color: "#fff",
                          }}
                        >
                          INR
                        </Typography>
                      </Grid>
                    </Box>
                  </Stack>

                  <Grid item md={12} className={classes.company}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#fff",
                        float: "left",
                      }}
                    >
                      Expand My Business
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ position: "absolute", bottom: -10, right: 0 }}>
                <img src="/static/ec 1.svg" alt="" />
              </Box>
              <Box sx={{ position: "absolute", bottom: -10, right: -10 }}>
                <img src="/static/ec 2.svg" alt="" />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default withBusinessProfile(Wallet);
