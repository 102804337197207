// import React from "react";
// import styles from "./FormElement.module.css";

// function Switch({ label, change, value, name, handleBlur, error }) {
//   function handleChange() {
//     if (!value || value === "off") {
//       return change(name, "on");
//     }
//     change(name, "off");
//   }

//   return (
//     <div style={{ marginBottom: "1rem" }}>
//       <div className={styles.switch}>
//         <input
//           type="checkbox"
//           id="toggleAll"
//           onChange={handleChange}
//           value={value ? "on" : "off"}
//           checked={value === "on"}
//           onBlur={handleBlur}
//           name={name}
//         />
//         <label htmlFor="toggleAll"></label>
//         <p>{label}</p>
//       </div>
//       <p className={styles.error}>{error}</p>
//     </div>
//   );
// }

// export default Switch;

import React from "react";
import styles from "./FormElement.module.css";

function Switch({ label, onChange, value, name, handleBlur, error }) {
  function handleChange(e) {
    const value = e.target.value;
    if (!value || value === "off") {
      return onChange({
        target: {
          name,
          value: "on",
        },
      });
    }
    onChange({
      target: {
        name,
        value: "off",
      },
    });
  }

  return (
    <div style={{ marginBottom: "1rem" }}>
      <div className={styles.switch}>
        <input
          type="checkbox"
          id={name}
          onChange={handleChange}
          value={value === ("on" || true) ? "on" : "off"}
          checked={(value === "on")||(value===true)}
          onBlur={handleBlur}
          name={name}
        />
        <label htmlFor={name}></label>
        <p>{label}</p>
      </div>
      <p className={styles.error}>{error}</p>
    </div>
  );
}

export default Switch;
