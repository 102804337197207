import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  details: {
    position: "relative",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    "&:before": {
      content: "",
      position: "absolute",
      height: "100%",
      top: "-8px",
    },
    "&:last-child:before": {
      height: "16px",
    },

    // "& >summary ": {
    //   listStyleType: "+",
    // },
    // "&[open]>summary": {
    //   listStyleType: "-",
    // },
  },

  child: {
    marginLeft: "1rem",
  },

  summary: {
    color: "#949fa9",
    padding: "10px",
    outline: "none",
    fontSize: "18px",
    fontWeight: "500",
    borderRadius: "10px",
    border: "1px solid #fff",
    fontFamily: "sans-serif",
    "&::-webkit-details-marker": {
      display: "none",
    },
    "&::after": {
      backgroundColor: "red",
    },
    "&[open] > summary::after": {
      transform: "rotate(90deg)",
    },

    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    "&:hover": {
      color: "rgb(0, 171, 85)",
      cursor: "pointer",
      transition: "background-color 0.3s",
      backgroundColor: "rgba(0, 171, 85, 0.08)",
      border: "1px solid #00aa55",
    },
    " & $selector": {
      position: "absolute",
      top: "8px",
      left: "-200px",
      right: "0",
      bottom: "0",
      height: "35px",
      pointerEvents: "none",
      zIndex: "-1",
    },
  },

  fa: {
    fontSize: " 18px",
    // marginLeft: "-22px",
    marginRight: " 8px",
    width: "auto",
    background: "#414042",
    borderRadius: "6px",
  },

  selector: {
    transition: "background-color 0.3s",
  },
});
