import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Stack,
  Box,
  Link,
  Typography,
  Avatar,
  Button,
  Chip,
} from "@mui/material";
import Scrollbar from "./../../Scrollbar";
import ListHead from "./../../common/ListHead";
import { capitalCase } from "change-case";
import { useDispatch, useSelector } from "react-redux";
import {
  getAcceptedLeadThunk,
  selectLead,
} from "../../../store/slices/lead/lead.slice";
import { useNavigate } from "react-router-dom";
import { getRejectedLeadThunk } from "./../../../store/slices/lead/lead.slice";
import SearchNotFound from "../../NotFound/SearchNotFound";

const TABLE_HEAD = [
  { id: "projectName", label: "Project Name", alignRight: false },
  { id: "clientName", label: "Client Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "reason", label: "Reason", alignRight: false },
];

const LeadsTableRejected = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRejectedLeadThunk());
  }, []);

  const rejectedLeads = useSelector(({ leads }) => leads.rejectedLeads);

  if (rejectedLeads?.length === 0) return <SearchNotFound />;

  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, padding: "20px" }}>
            <Table>
              <ListHead
                headLabel={TABLE_HEAD}
                rowCount={rejectedLeads.length}
              />
              <TableBody>
                {rejectedLeads
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, id) => {
                    const {
                      client,
                      project,
                      lead_status,
                      vendor_rejection_reason,
                    } = row;

                    return (
                      <TableRow key={id} tabIndex={-1} role="checkbox">
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            // onChange={(event) => handleClick(event, name)}
                          />
                        </TableCell> */}
                        <TableCell align="center">
                          <Typography noWrap>
                            {project?.project_name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          {client?.client_name}
                        </TableCell>
                        <TableCell align="center">
                          <Chip
                            label={capitalCase(lead_status)}
                            color="error"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {vendor_rejection_reason}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
};

export default LeadsTableRejected;
