import React, { useState } from "react";
import {
  Input,
  Box,
  Grid,
  Typography,
  Card,
  Container,
  Stack,
  Button,
  Table,
  Chip,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ListHead from "../../components/common/ListHead";
import Scrollbar from "../../components/common/Scrollbar";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import _ from "lodash";

import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import useModal from "../../hooks/useModal";
import Modal from "../../components/Modal/Modal";
import SearchServices from "./SearchServices";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import LeadsTable from "../../components/_dashboard/leads/LeadsTable";
import users from "../../_mocks_/user";

const TABLE_HEAD = [
  { label: "ID", alignRight: false },
  { label: "Max Price", id: "max_price", alignRight: false },
  { label: "Min Price", id: "min_price", alignRight: false },
  { label: "Service Name", id: "service_name", alignRight: false },
  { label: "Service Type", id: "service_type", alignRight: false },
  { label: "Notes", id: "notes", alignRight: false },
  { label: "Verified", id: "verified", alignRight: false },
];

const nodesData = [
  {
    value: "1-1",
    label: "1-1",
    children: [
      {
        value: "1-1-1",
        label: "1-1-1",
      },
      {
        value: "1-1-2",
        label: "1-1-2",
        children: [
          {
            value: "1-1-2-1",
            label: "1-1-2-1",
            children: [
              {
                value: "1-1-2-1-1",
                label: "1-1-2-1-1",
              },
              {
                value: "1-1-2-1-2",
                label: "1-1-2-1-2",
              },
            ],
          },
          {
            value: "1-1-2-2",
            label: "1-1-2-2",
          },
        ],
      },
    ],
  },
  {
    value: "1-2",
    label: "1-2",
    children: [
      {
        value: "1-2-1",
        label: "1-2-1",
      },
      {
        value: "1-2-2",
        label: "1-2-2",
        children: [
          {
            value: "1-2-2-1",
            label: "1-2-2-1",
            children: [
              {
                value: "1-2-2-1-1",
                label: "1-2-2-1-1",
              },
              {
                value: "1-2-2-1-2",
                label: "1-2-2-1-2",
              },
            ],
          },
          {
            value: "1-2-2-2",
            label: "1-2-2-2",
          },
        ],
      },
    ],
  },
];

function Services() {
  const [state, setState] = useState({
    expanded: [],
    keyword: "",
  });

  const { isModalOpen, showModal, hideModal } = useModal();

  const onSearchInputChange = (event, data, searchedNodes) => {
    setState((prevState) => {
      if (prevState.keyword.trim() && !data.value.trim()) {
        return {
          expanded: [],
          keyword: data.value,
        };
      }
      return {
        expanded: getAllValuesFromNodes(searchedNodes, true),
        keyword: data.value,
      };
    });
  };

  const getHighlightText = (text, keyword) => {
    const startIndex = text.indexOf(keyword);
    return startIndex !== -1 ? (
      <span>
        {text.substring(0, startIndex)}
        <span style={{ color: "#2cb664" }}>
          {text.substring(startIndex, startIndex + keyword.length)}
        </span>
        {text.substring(startIndex + keyword.length)}
      </span>
    ) : (
      <span>{text}</span>
    );
  };

  const keywordFilter = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n.children) {
        const nextNodes = keywordFilter(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        } else if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if (
          nextNodes.length > 0 ||
          n.label.toLowerCase().includes(keyword.toLowerCase())
        ) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      } else {
        if (n.label.toLowerCase().includes(keyword.toLowerCase())) {
          n.label = getHighlightText(n.label, keyword);
          newNodes.push(n);
        }
      }
    }
    return newNodes;
  };

  const getAllValuesFromNodes = (nodes, firstLevel) => {
    if (firstLevel) {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    } else {
      const values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllValuesFromNodes(n.children, false));
        }
      }
      return values;
    }
  };

  let searchedNodes = state.keyword.trim()
    ? keywordFilter(_.cloneDeep(nodesData), state.keyword)
    : nodesData;

  return (
    <div>
      <Grid container sx={{ background: "#f3f6f8" }}>
        <Grid item xs={12}>
          <Box my={5} p={2}>
            <img
              src="/static/icons/search-services.svg"
              style={{ width: "50%", margin: "0 auto" }}
              alt=""
            />
            <Typography
              variant="h5"
              textAlign="center"
              mb={2}
              sx={{ color: "#11a54a" }}
            >
              What You Offer
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              mb={2}
              sx={{ color: "#48505a" }}
            >
              Here’s a quick look at the services you offer!
            </Typography>
            <Box textAlign="center">
              <Button
                variant="contained"
                to="#"
                startIcon={<Icon icon={plusFill} />}
                onClick={showModal}
                textAlign="center"
              >
                Services
              </Button>
            </Box>
            <Modal
              isOpen={isModalOpen}
              closeModal={hideModal}
              title="Add Service"
              content={<SearchServices />}
              dialogProps={{ fullWidth: true }}
              titleStyle={{ textAlign: "center", marginTop: 2 }}
              actionStyle={{ justifyContent: "center", marginBottom: 2 }}
            />
          </Box>
        </Grid>

        <Grid container xs={12} md={12} sx={{ margin: 3 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                height: "100%",
                width: 1,
                "& .table-header": {
                  backgroundColor: "rgb(244, 246, 248)",
                  color: "rgb(99, 115, 129)",
                },
              }}
            >
              <Card>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    display: {
                      lg: "flex",
                      md: "flex",
                      sm: "block",
                      xs: "block",
                    },
                  }}
                ></Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, padding: "10px" }}>
                    <Table>
                      <ListHead
                        headLabel={TABLE_HEAD}
                        // rowCount={vendorServicedata?.length}
                      />
                      <TableBody>
                        <TableCell align="left"></TableCell>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Services;
