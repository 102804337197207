import React, { useState } from "react";
import { Box, Grid, Button, Stack } from "@mui/material";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";

export default function MilestoneModal() {
  return (
    <>
      <Box>
        <Grid container spacing={2} px={3}>
          <Grid item md={12}>
            <Element eletype={inputType.input} label="Milestone Name" />
          </Grid>
          <Grid item md={6}>
            <Element eletype={inputType.input} label="Percentage Release (%)" />
          </Grid>
          <Grid item md={6}>
            <Element eletype={inputType.input} label="Milestone Amount" />
          </Grid>
          <Grid item md={6}>
            <Element eletype={inputType.input} label=" Released Amount" />
          </Grid>
          <Grid item md={6}>
            <Element eletype={inputType.input} label="Timeline" />
          </Grid>
          <Grid item md={6}>
            <Element eletype={inputType.date} label="Delivery Date" />
          </Grid>
          <Grid item md={6}>
            <Element eletype={inputType.input} label=" Invoice Upload" />
          </Grid>
          <Grid item md={4}>
            <Element eletype={inputType.switch} label="Invoice Approved" />
          </Grid>
          <Grid item md={4}>
            <Element eletype={inputType.switch} label="Milestone Complete" />
          </Grid>
          <Grid item md={4}>
            <Element eletype={inputType.switch} label="Payment Released" />
          </Grid>

          <Grid item md={12} pb={2} sx={{ textAlign: "center" }}>
            <Button size="large" type="submit" variant="contained">
              Save Milestone
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
