import React from "react";
import { Typography, Grid, Stack, Box, Button } from "@mui/material";

// components
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { Formik } from "formik";
import {
  addPortfolioThunk,
  addUploadPortfolioThunk,
  updatePortfolioThunk,
} from "../../store/slices/portfolio/portfolio.slice";
import { useDispatch } from "react-redux";
import "../FormData/upload.css";
import { uploadPortfolioValidation } from "../../utils/validation";
import DropZone from "./../../components/_dashboard/app/DropZone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// -- new

import SearchServiceNew from "../../components/Modal/SearchServiceNew";
import { searchIndustryHandler } from "../../utils/global/vendor.global";
import { searchServiceApi } from "../../utils/apis.utils";
import { serviceFormatter } from "../../utils/global/vendor.global";
import SearchIndustry from "../../components/Modal/SearchIndustry";
import { budgetOptions } from "../../utils/options.utils";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  dropdownIconCss: {
    "& svg": {
      bottom: "14px !important",
    },
  },
  "@media (max-width:600px)": {
    dropdownIconCss: {
      padding: "0px  !important",
    },
  },
});

const INIT_STATE = {
  client_name: "",
  website_link: "",
  app_link: "",
  service: "",
  industry: [],
  client_budget: "",
  pow_files: [],
  description: "",
  tech_stacks: [],
  tags: [],
};

export default function CreatePortfolio({
  hidePortfolioModalCheck,
  businessId,
  props,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSubmit = (values) => {
    if (props) {
      var data = {
        website_link: values.website_link,
        app_link: values.app_link,
        client_budget: values.client_budget,
        client_name: values.client_name,
        description: values.description,
        industries: values.industries,
        pow_files: values.pow_files,
        service: values.service,
        tags: values.tags,
        tech_stacks: values.tech_stacks,
      };
      return dispatch(
        updatePortfolioThunk({
          id: props.id,
          data,
        })
      )
        .unwrap()
        .then(hidePortfolioModalCheck);
    }
    dispatch(addPortfolioThunk(values)).unwrap().then(hidePortfolioModalCheck);
  };

  async function addAttachment({ file }) {
    if (!file || file.length === 0) return;
    const formData = new FormData();
    file.forEach((ele) => {
      formData.append("files", ele);
    });
    dispatch(addUploadPortfolioThunk(formData));
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <Typography gutterBottom variant="h5" component="div" mt={2}>
          Create Portfolio
        </Typography>

        <Formik
          initialValues={
            props
              ? {
                  ...props,
                  service: {
                    ...props.service,
                    service_id: props.service?._id,
                  },
                  industry: props.industries,
                  tags: props.tags,
                }
              : INIT_STATE
          }
          onSubmit={handleSubmit}
          validationSchema={uploadPortfolioValidation}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ margin: "20px 0" }}>
                <Grid container spacing={2}>
                  <Grid item md={12} pb={0}>
                    <Element
                      label="Client Name"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Client Name",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "client_name",
                      }}
                      eletype={inputType.input}
                      value={values.client_name}
                      errorText={touched.client_name && errors.client_name}
                    />
                  </Grid>
                  <Grid item md={12} pb={0}>
                    <Element
                      label="Description"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Description",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "description",
                      }}
                      eletype={inputType.input}
                      value={values.description}
                      errorText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item md={12} pb={2}>
                    <SearchServiceNew
                      url={searchServiceApi}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Search Service"
                      placeholder="Search Service"
                      formatter={serviceFormatter}
                      name="service"
                      value={values.service}
                      initTechStackData={values.tech_stacks}
                      initTags={values.tags}
                      errorText={
                        touched.service && errors.service?.service_name
                      }
                    />
                  </Grid>

                  <Grid item md={12} pb={2}>
                    <SearchIndustry
                      name="industry"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label="Select Industry"
                      placeholder="Select Industry"
                      formatter={serviceFormatter}
                      value={values.industry}
                      errorText={touched.industry && errors.industry}
                      handler={searchIndustryHandler}
                    />
                  </Grid>
                  <Grid item md={12} pb={2}>
                    <Element
                      label="Website Link"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter Website url",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "website_link",
                      }}
                      eletype={inputType.input}
                      value={values.website_link}
                      errorText={touched.website_link && errors.website_link}
                    />
                  </Grid>
                  <Grid item md={12} pb={0}>
                    <Element
                      label="App Link"
                      inputProps={{
                        type: "text",
                        placeholder: "Enter App url",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "app_link",
                      }}
                      eletype={inputType.input}
                      value={values.app_link}
                      errorText={touched.app_link && errors.app_link}
                    />
                  </Grid>
                  <Grid item md={12} className={classes.dropdownIconCss}>
                    <Element
                      eletype={inputType.select}
                      label="Project Budget"
                      value={values.turnover}
                      inputProps={{
                        name: "client_budget",
                        handleChange: handleChange,
                        handleBlur: handleBlur,
                      }}
                      value={values.client_budget}
                      errorText={touched.client_budget && errors.client_budget}
                      options={budgetOptions}
                      icons={
                        <ArrowDropDownIcon
                          sx={{
                            position: "absolute",
                            right: 20,
                            bottom: 14,
                          }}
                        />
                      }
                    />
                  </Grid>

                  <Grid item md={12} pb={2}>
                    <Element
                      label="Proof of Work"
                      inputProps={{
                        type: "file",
                        onChange: handleChange,
                        onBlur: handleBlur,
                        name: "pow_files",
                      }}
                      extraArguments={{
                        upload_type: "gst_file",
                        vendor_profile_id: businessId,
                      }}
                      multiple
                      eletype={inputType.file}
                      value={values.pow_files}
                      errorText={touched.pow_files && errors.pow_files}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  margin: "20px 0",
                }}
              >
                <Stack direction="row" spacing={1}>
                  <Button variant="outlined" type="submit">
                    Save
                  </Button>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
        <hr />
        <Box spacing={2} sx={{ marginTop: 2 }}>
          <Grid sx={{ fontWeight: 600, textAlign: "center" }} spacing={2}>
            OR
          </Grid>
          <Box py={3}>
            <DropZone addAttachment={addAttachment} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
