import {
  fetchAllLeadsApi,
  getLeadsDetailsApi,
  vendorUploadProposalApi,
} from "../../../utils/apis.utils";
import { fetchLeadsDetailsThunk } from "./projectleads.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { createQueryString } from "../../../utils/global/global";
import _ from "lodash";
//important api
export const fetchAllLeadsHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  let query = "";
  if (data.limit) {
    query = createQueryString({
      query_term: data.term,
      status: data.type,
      page: data.page,
      spoc: data.spoc,
      limit: data.limit,
    });
  } else {
    query = createQueryString({
      query_term: data.term,
      status: data.type,
      page: data.page,
      spoc: data.spoc,
    });
  }
  const response = await user.get(fetchAllLeadsApi + query);
  return {
    data: response.data,
    total: response.total,
    // limit: response.limit,
    from: data.from,
    data_state: data,
  };
};
//important api
export const fetchLeadsDetailsHandler = async (id, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.get(getLeadsDetailsApi + "/" + id);
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};
//important api
export const vendorUploadProposalApiHandler = async (data, thunkApi) => {
  try {
    const { formData, deal_id } = data;
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(vendorUploadProposalApi, formData);
    thunkApi.dispatch(fetchLeadsDetailsThunk(deal_id));
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (error) {
    thunkApi.dispatch(
      showFailureToast({ message: error, vertical: "top", horizontal: "right" })
    );
  }
};
