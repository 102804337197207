import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { capitalCase } from "../../../utils/change-case";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Stack,
  Avatar,
  Paper,
  Button,
  TextField,
  IconButton,
  Input,
} from "@mui/material";
import clsx from "clsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import DescriptionIcon from "@mui/icons-material/Description";
import { DateTime } from "luxon";
import { useStyles } from "./Overview.theme";
import MyDropzone from "../../../components/DropZone";
import { useDispatch } from "react-redux";
import {
  changeDescriptionForAProjectThunk,
  updateProjectExpectedAmountThunk,
  updateProjectExpirationDateThunk,
  updateProjectStatusThunk,
  uploadProjectAttachmentThunk,
  fetchLeadsDetailsThunk,
} from "../../../store/slices/leadsProject/projectleads.slice";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReactDatePicker from "react-datepicker";
import { useState } from "react";
import { inputType, projectStatus } from "../../../utils/enum";

function OverView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const { leadsOverviewAndProposal } = useSelector(
    ({ projectleads }) => projectleads
  );

  // console.log("leadsOverviewAndProposal",leadsOverviewAndProposal)
  // function addAttachment(file) {
  //   const formData = new FormData();
  //   file.file.forEach((ele) => {
  //     formData.append("file", ele);
  //   });
  //   dispatch(
  //     uploadProjectAttachmentThunk({
  //       id: params.id,
  //       file: formData,
  //     })
  //   );
  // }

  useEffect(() => {
    dispatch(fetchLeadsDetailsThunk(params.id));
  }, [params.id]);

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card mb={5}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography
              gutterBottom
              variant="h5"
              sx={{ marginBottom: 0, fontSize: { xs: "16px", sm: "16px" } }}
            >
              Project Details
            </Typography>
            <Box
              component="span"
              display="inline"
              className={clsx(classes.badge_blue, classes.badge)}
              sx={{ fontSize: { xs: "16px", sm: "16px" } }}
            >
              {capitalCase(leadsOverviewAndProposal?.over_view?.current_status)}
            </Box>
          </Stack>

          <Typography
            variant="body1"
            sx={{ color: "#a4b0be", fontWeight: "600", paddingBottom: "10px" }}
          >
            Description
          </Typography>

          <Typography
            variant="body1"
            sx={{ color: "#a4b0be", fontWeight: "400", paddingBottom: "10px" }}
          >
            {leadsOverviewAndProposal?.over_view?.description}
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{
                  color: "#a4b0be",
                  fontWeight: "600",
                  paddingBottom: "10px",
                }}
                mt={2}
              >
                Services
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  color: "#a4b0be",
                  fontWeight: "400",
                  paddingBottom: "10px",
                }}
                mt={2}
              >
                {leadsOverviewAndProposal?.over_view?.sku_name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6} md={12}>
              <Box
                component="div"
                mt={3}
                display="flex"
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <Stack direction="row" spacing={2} justifyContent="space-start">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "15px" },
                      color: "#a4b0be",
                      fontWeight: "600",
                    }}
                  >
                    <AccessTimeIcon sx={{ fontSize: "15px" }} /> SPOC Name:
                  </Typography>
                  <Typography
                    variant="body2"
                    ml={1}
                    sx={{
                      paddingRight: "1rem",
                      color: "text.secondary",
                      fontSize: "14px",
                    }}
                  >
                    {leadsOverviewAndProposal?.over_view?.spoc_name}
                  </Typography>
                </Stack>

                <Stack direction="row" spacing={2} justifyContent="space-start">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "16px" },
                      color: "#a4b0be",
                      fontWeight: "600",
                    }}
                  >
                    <AccessTimeIcon sx={{ fontSize: "14px" }} /> Created Date:
                  </Typography>
                  <Typography
                    variant="body2"
                    ml={1}
                    sx={{
                      paddingRight: "1rem",
                      color: "text.secondary",
                      fontSize: { xs: "14px" },
                    }}
                  >
                    {" "}
                    {DateTime.fromISO(
                      leadsOverviewAndProposal?.over_view?.created_at
                    ).toFormat("DD")}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            {leadsOverviewAndProposal?.over_view?.reason == "" ? (
              ""
            ) : (
              <>
                {" "}
                <Typography
                  variant="body1"
                  sx={{
                    color: "#a4b0be",
                    fontWeight: "600",
                    paddingBottom: "10px",
                  }}
                >
                  Remarks{""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#a4b0be",
                    fontWeight: "400",
                    paddingBottom: "5px",
                    marginLeft: "10px",
                  }}
                >
                  {leadsOverviewAndProposal?.over_view?.reason}
                </Typography>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default OverView;
