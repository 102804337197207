import { certification } from "./constant";

export const projectApprovalStatus = {
  accepted: "Accepted",
  rejected: "Rejected",
  pending: null,
};

export const inputType = {
  input: "input",
  selectInput: "selectInput",
  select: "select",
  textarea: "textarea",
  checkbox: "checkbox",
  radio: "radio",
  autocomplete: "autocomplete",
  switch: "switch",
  date: "date",
  file: "file",
  number: "number",
  fileSupport: "fileSupport",
  checkboxgroup: "checkboxgroup",
  chip: "chip",
};

export const projectServices = {
  desiging: "Desiging",
  development: "Development",
  marketing: "Marketing",
  others: "Others",
};

export const projectStatus = {
  all: "all",
  draft: "draft",
  pending: "pending",
  inProgess: "in_progress",
  drop: "drop",
  active: "active",
  closed: "closed",
};

export const otpType = {
  email: "email",
  mobile: "mobile",
};

export const strings = {
  email_verify: "email_verify",
};

export const chatterType = {
  number: "number",
  text: "text",
  dropdown: "dropdown",
  date: "date",
  status: "status",
};

export const listViewType = {
  charter: "charter",
  timeline: "timeline",
};

export const transactionType = {
  all: "all",
  debit: "debit",
  credit: "credit",
};

export const timelineType = {
  table: "table",
  gantt: "gantt",
  kanban: "kanban",
};

export const leadsType = {
  pending: "pending",
  approved: "approved",
  rejected: "rejected",
};

export const serviceType = {
  custom: "custom",
  exmyb: "service",
};

export const requestTypeEnum = {
  new: "new",
  edit: "edit",
  delete: "delete",
};

export const tabsNameEnum = {
  BasicInfo: "Basic Info",
  Address: "Address",
  POC: "POC",
  FounderInfo: "Founder's Information",
  ProductCode: "Product Code",
  PastWork: "Past Work",
  CorporateDeck: "Corporate Deck",
  Testimonials: "Testimonials",
  saasChannel: "Saas Channel Partner",
  certification: "Certifications", 
  expertiseBreakUp: 'Expertise BreakUp',
  platformReview: "Platform Review",
};
