import { Autocomplete, Card } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import Input from "../Form/Input";
import _ from "lodash";
import {
  fetchAllTageByService,
  fetchAllTechStackByService,
  searchServiceHandler,
} from "../../utils/global/vendor.global";
import TreeList from "./TreeListNew.js";
import { Box } from "@mui/system";
import AutocompleteChip from "../Form/AutocompleteChip";

function SearchServiceNew({
  onChange,
  onBlur,
  label,
  placeholder,
  formatter,
  value = {},
  errorText,
  handler = searchServiceHandler,
  name,
  initTechStackData = [],
  initTags = [],
}) {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [teckStacks, setTeckStacks] = useState(initTechStackData);
  const [tags, setTags] = useState(initTags);

  function handleTechStackDelete(val) {
    setTeckStacks(val);
  }

  function handleTagsDelete(val) {
    setTags(val);
  }

  async function getOptions(_, value) {
    const data = await fetchAllTechStackByService(value?.service_id);
    return data.data.map((lan) => ({
      ...lan,
      label: lan.name,
      value: lan.name,
    }));
  }

  async function getTagOptions(_, value) {
    const data = await fetchAllTageByService(value?.service_id);
    return data.data.map((lan) => ({
      ...lan,
      label: lan.tag,
      value: lan.tag,
    }));
  }

  function handleTeckStackChange(val) {
    if (!val) return;
    setTeckStacks((data) => [...data, val]);
  }

  function handleTagChange(val) {
    if (!val) return;
    setTags((data) => [...data, val]);
  }

  useEffect(() => {
    onChange({
      target: {
        name: "tech_stacks",
        value: teckStacks,
      },
    });
  }, [teckStacks]);

  useEffect(() => {
    onChange({
      target: {
        name: "tags",
        value: tags,
      },
    });
  }, [tags]);

  useEffect(() => {
    setSearch(value?.service_name);
  }, [value]);

  const handleTextChange = useMemo(() => {
    const _fn = _.debounce((value) => {
      if (!value) return;
      handler(value).then((response) => {
        if (typeof formatter === "function") {
          if (response.data?.length === 0) {
            return setOpen(false);
          }
          setOptions(formatter(response.data));
          setOpen(true);
        }
      });
    }, 500);

    return function handleChange(e) {
      const value = e.target.value;
      setSearch(value);
      _fn(value);
    };
  }, []);

  return (
    <>
      <Input
        label={label}
        inputProps={{
          placeholder: placeholder,
          onChange: handleTextChange,
          name: "treeTextChange",
        }}
        value={search}
        errorText={errorText}
      />
      {isOpen && (
        <Card>
          <Box p={2}>
            {options.map((d) => (
              <TreeList
                {...d}
                onChange={onChange}
                onBlur={onBlur}
                setOpen={setOpen}
                name={name}
              />
            ))}
          </Box>
        </Card>
      )}
      {value?.service_id && (
        <AutocompleteChip
          label="Search Tech Stack"
          chipsData={teckStacks}
          deleteChip={handleTechStackDelete}
          getOptions={getOptions}
          isChipsVisible
          onChange={handleTeckStackChange}
          extra={value}
          placeholder="Search Tech Stack"
        />
      )}

      {value?.service_id && (
        <AutocompleteChip
          label="Search Tags"
          chipsData={tags.map((ele) => ({ ...ele, name: ele.tag }))}
          deleteChip={handleTagsDelete}
          getOptions={getTagOptions}
          isChipsVisible
          onChange={handleTagChange}
          extra={value}
          placeholder="Search Tags"
        />
      )}
    </>
  );
}

export default SearchServiceNew;
