import { listViewType } from "../../../utils/enum";
import { createQueryString } from "../../../utils/global/global";
import {
  startDashboardLoader,
  stopDashboardLoader,
} from "../dashboard/dashboard.slice";
import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import {
  commentsCharterApi,
  createProjectTimelineApi,
  fetchProjectCharterByProjectIdApi,
  fetchProjectTimelineByProjectIdApi,
  filesCharterApi,
  getProjectAPI,
  updatePorjectCharterApi,
  uploadAttachmentAPI,
  reportedIssueApi,
  fetchAllProjectsApi,
  proposalApi,
  createProjectSupportTicketApi,
  fetchAllSupportCommentApi,
  createSupportCommentApi,
  getAllMilestonesApi,
} from "./../../../utils/apis.utils";

export const getProjectHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(`${getProjectAPI}/${data.id}`);
  const responseData = response.data;
  return responseData;
};

export const uploadProjectAttachmentHandler = async (data, thunkApi) => {
  try {
    const { id, file } = data;
    const { user } = thunkApi.extra.apiService;
    file.append("attachment_type", "vendor_attachment");
    const response = await user.post(uploadAttachmentAPI + "/" + id, file);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const fetchAllCollectionsByProjectHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(getAllMilestonesApi + "?project_id=" + id);
  return response.data;
};

export const fetchProjectCharterByProjectIdHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString({
    type: listViewType.charter,
    project_id: id,
  });
  const response = await user.get(
    fetchProjectCharterByProjectIdApi + queryString
  );
  return response.data;
};

export const fetchAllCommentsForTimelineHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString(data);
  const response = await user.get(commentsCharterApi + queryString);
  return response.data;
};

export const fetchAllFilesForTimelineHandler = async (data, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const queryString = createQueryString(data);
  const response = await user.get(filesCharterApi + queryString);
  return response.data;
};
export const addCommentHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const queryString = createQueryString(data);
    const response = await user.post(commentsCharterApi + queryString);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const addFilesHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(filesCharterApi, data);

    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({
        message: err,
        vertical: "top",
        horizontal: "right",
      })
    );
    return Promise.reject();
  }
};

export const fetchProjectTimelineHandler = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(
    fetchProjectTimelineByProjectIdApi + "/" + id
  );
  return response.data;
};

export const createSupportTicketCommentHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startDashboardLoader());
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createSupportCommentApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopDashboardLoader());
  }
};

export const updateProjectTimelineHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(createProjectTimelineApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};

export const updateProjectCharterHandler = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.put(updatePorjectCharterApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(
      showFailureToast({ message: err, vertical: "top", horizontal: "right" })
    );
    return Promise.reject(err);
  }
};
export const getReportedIssue = async (id, thunkApi) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: id,
  });
  const response = await user.get(reportedIssueApi + query);
  const responseData = response.data;
  return responseData;
};
export const createReportedIssue = async (data, thunkApi) => {
  try {
    const { user } = thunkApi.extra.apiService;
    const response = await user.post(createProjectSupportTicketApi, data);
    thunkApi.dispatch(
      showSuccessToast({
        message: response?.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (error) {
    thunkApi.dispatch(showFailureToast({ message: error }));
  }
};

export const fetchAllProjectsHandler = async (__, thunkApi) => {
   
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(fetchAllProjectsApi);
  return response.data;
};

export const getAllProposalsHandler = async (id, thunkApi) => {
   
  const { user } = thunkApi.extra.apiService;
  const response = await user.get(proposalApi + "/" + id);
  return response.data;
};

export const fetchAllSupportDataByTicketIdHanldler = async (
  { projectId, ticketId },
  thunkApi
) => {
  const { user } = thunkApi.extra.apiService;
  const query = createQueryString({
    project_id: projectId,
  });
  const response = await user.get(
    `${fetchAllSupportCommentApi}/${ticketId}${query}`
  );
  return response.data;
};
