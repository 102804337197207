import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Container,
  Card,
  Box,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import {
  createBusinessProfileThunk,
  fetchVendorProfileDetailsThunk,
  updateBusinessProfileThunk,
} from "../../store/slices/vendor/vendor.slice";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { basicInfoValidations } from "../../utils/validations/vendor.validation";
import {
  augmentationOptions,
  cmmiLevelOptions,
  companyTurnoverOptions,
  noOfClientsOptions,
  numberOfEmployeesOptions,
  typeOfCompanyOptions,
} from "../../utils/options.utils";
import MobileInput from "../../components/MobileInput";
import useBusinessId from "../../hooks/useBusinessId";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BasicInfoLoader from "../../components/Skeletons/BasicInfoLoader";
import { useStyles } from "../../components/_dashboard/profile/PersonalDetails/PersonalDetail.theme";
import Turnover from "./Trunover";
import {
  showFailureToast,
  showWarningToast,
} from "../../store/slices/toast/toast.slice";

export default function BasicInfo() {
  const { profileData } = useSelector(({ vendor }) => vendor);
  const dispatch = useDispatch();
  // console.log("mobile: ", profileData);

  const businessId = useBusinessId();
  const classes = useStyles();
  const [augmentationService, setAugmentationService] = useState(
    profileData?.resource_augmentation_services ? "on" : "off"
  );

  const formik = useFormik({
    initialValues: {
      brand_name: "",
      registered_business_name: "",
      company_type: "",
      year_of_establishment: "",
      turnover: "",
      number_of_employees: "",
      number_of_clients: "",
      website: "",
      logo: {},
      description: "",
      activeProjectNumber: "",
      augmentationModeSelect: [],
      cmmiLevelSelect: "",
      resourceAugmentationService: "off",
      countryCode: "",
      // mobile_number: "",
      mobile: {
        country_code: "",
        mobile_number: "",
      },
    },
    onSubmit: (values) => {
      console.log("data:", values);

      const data = {
        ...values,
        resourceAugmentationService:
          values.resourceAugmentationService === "off" ? false : true,
        year_of_establishment: new Date(
          values.year_of_establishment
        ).getFullYear(),
        augmentationModeSelect:
          values.augmentationModeSelect &&
          typeof values.augmentationModeSelect === "string"
            ? JSON.parse(values?.augmentationModeSelect)
            : values.augmentationModeSelect,
        registered_business_name: values.brand_name,
        mobile_number: values.mobile.mobile_number,
        country_code: values.mobile.country_code,
      };

      if (!businessId) {
        dispatch(createBusinessProfileThunk({ data }));
      } else {
        dispatch(
          updateBusinessProfileThunk({
            id: businessId,
            data,
            past_data: {
              ...profileData,
              resourceAugmentationService:
                profileData?.resource_augmentation_service === ("on" || true)
                  ? true
                  : false,
            },
          })
        ).then((res) => {
          if (res.type === "updateBusinessProfileThunk/fulfilled") {
            dispatch(fetchVendorProfileDetailsThunk());
          }
        });
      }
    },
    validationSchema: basicInfoValidations,
  });

  useEffect(() => {
    if (profileData) {
      formik.setValues({
        brand_name: profileData.brand_name || "",
        registered_business_name: profileData.registered_business_name || "",
        company_type: profileData.company_type || "",
        year_of_establishment: profileData.year_of_establishment
          ? new Date(profileData.year_of_establishment)
          : "",
        turnover: profileData.turnover || "",
        number_of_employees: profileData.number_of_employees || "",
        number_of_clients: profileData.number_of_clients || "",
        website: profileData.website || "",
        logo: profileData.logo || {},
        description: profileData.description || "",
        activeProjectNumber: profileData?.active_project_number || "",
        augmentationModeSelect: profileData?.augmentation_mode_select || "",
        cmmiLevelSelect: profileData?.cmmi_level_select || "",
        resourceAugmentationService: profileData?.resource_augmentation_service
          ? "on"
          : "off",
        countryCode: profileData.country_code || "",
        // mobile_number: profileData.mobile_number || "",
        mobile: {
          country_code: profileData.country_code || "",
          mobile_number: profileData.mobile_number || "",
        },
      });
      setAugmentationService(
        profileData?.resource_augmentation_service ? "on" : "off"
      );
    }
  }, [profileData]);

  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const checkForErrors = () => {
    if (!isObjectEmpty(formik.errors)) {
      dispatch(
        showFailureToast({
          message: "Please complete all required fields before saving.",
          vertical: "top",
          horizontal: "right",
        })
      );
    }
  };


  return (
    <Container>
      <Box>
        <Grid container spacing={2} mt={5}>
          <Grid item md={12}>
            <Card sx={{ minHeight: "420px", py: 2, px: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                m={2}
              >
                <Typography variant="h4" gutterBottom>
                  Business Info
                </Typography>
              </Stack>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} p={3}>
                  {/* <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    pb={3}
                  >
                    <Element
                      label="Registered business name"
                      inputProps={{
                        placeholder: "Enter Registered Business Name",
                        type: "text",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        name: "registered_business_name",
                      }}
                      eletype={inputType.input}
                      value={formik.values.registered_business_name}
                      er
                      errorText={
                        formik.touched.registered_business_name &&
                        formik.errors.registered_business_name
                      }
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid> */}
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    {/* <Element
                      label="Brand Name"
                      inputProps={{
                        placeholder: "Enter Brand Name",
                        type: "text",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        name: "brand_name",
                      }}
                      eletype={inputType.input}
                      value={formik.values.brand_name}
                      errorText={
                        formik.touched.brand_name && formik.errors.brand_name
                      }
                    /> */}
                    <Element
                      label={
                        <>
                          Brand Name
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        placeholder: "Enter Brand Name",
                        type: "text",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        name: "brand_name",
                      }}
                      eletype={inputType.input}
                      value={formik.values.brand_name}
                      errorText={
                        formik.touched.brand_name && formik.errors.brand_name
                      }
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label={
                        <>
                          Type of Company{" "}
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        name: "company_type",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.company_type}
                      options={typeOfCompanyOptions}
                      errorText={
                        formik.touched.company_type &&
                        formik.errors.company_type
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <MobileInput
                      label={
                        <>
                          Mobile Number
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      name="mobile"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile}
                      errorText={formik.touched.mobile && formik.errors.mobile}
                      disabled={
                        profileData.profile_stage ===
                        "sent_for_accounts_approval"
                          ? true
                          : false
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label={
                        <>
                          Year of Establishment
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        name: "year_of_establishment",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        placeholder: "Select Year",
                      }}
                      value={formik.values.year_of_establishment}
                      eletype={inputType.date}
                      showYearPicker
                      dateFormat="yyyy"
                      yearItemNumber={9}
                      errorText={
                        formik.touched.year_of_establishment &&
                        formik.errors.year_of_establishment
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label="Company Turnover (Last Year)"
                      inputProps={{
                        name: "turnover",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.turnover}
                      options={companyTurnoverOptions}
                      errorText={
                        formik.touched.turnover && formik.errors.turnover
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label={
                        <>
                          Number of Employees
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        name: "number_of_employees",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.number_of_employees}
                      options={numberOfEmployeesOptions}
                      errorText={
                        formik.touched.number_of_employees &&
                        formik.errors.number_of_employees
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label={
                        <>
                          Number of Clients
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        name: "number_of_clients",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.number_of_clients}
                      options={noOfClientsOptions}
                      errorText={
                        formik.touched.number_of_clients &&
                        formik.errors.number_of_clients
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label={
                        <>
                          Website of Company
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        placeholder: "Enter Website",
                        type: "text",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        name: "website",
                      }}
                      eletype={inputType.input}
                      value={formik.values.website}
                      errorText={
                        formik.touched.website && formik.errors.website
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      eletype={inputType.file}
                      label="Business Logo (png/jgp/jpeg/avif)"
                      inputProps={{
                        name: "logo",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      url="/vendor/upload_business_logo"
                      extraArguments={{
                        upload_type: "business_logo",
                      }}
                      value={formik.values.logo}
                      errorText={formik.touched.logo && formik.errors.logo}
                    />
                    {/* <Element
                      label="Description"
                      inputProps={{
                        placeholder: "Enter Description",
                        type: "text",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        name: "description",
                      }}
                      eletype={inputType.input}
                      value={formik.values.description}
                      errorText={
                        formik.touched.description && formik.errors.description
                      }
                    /> */}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label={
                        <>
                          Number of Active Projects
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        placeholder: "Enter Active Project Number",
                        type: "number",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                        name: "activeProjectNumber",
                      }}
                      eletype={inputType.input}
                      value={formik.values.activeProjectNumber}
                      errorText={
                        formik.touched.activeProjectNumber &&
                        formik.errors.activeProjectNumber
                      }
                    />
                  </Grid>
                  {/* <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label="Augmentation Mode"
                      inputProps={{
                        name: "augmentationModeSelect",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.augmentationModeSelect}
                      options={augmentationOptions}
                      errorText={
                        formik.touched.augmentationModeSelect &&
                        formik.errors.augmentationModeSelect
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid> */}

                  <Grid
                    item
                    md={4}
                    xs={12}
                    sm={12}
                    className={classes.nonePadding}
                    p={3}
                  >
                    <Element
                      label={
                        <>
                          CMMI Level
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      inputProps={{
                        name: "cmmiLevelSelect",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.cmmiLevelSelect}
                      options={cmmiLevelOptions}
                      errorText={
                        formik.touched.cmmiLevelSelect &&
                        formik.errors.cmmiLevelSelect
                      }
                      eletype={inputType.select}
                      icons={
                        <ArrowDropDownIcon
                          sx={{ position: "absolute", right: 20, bottom: 24 }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item md={12} p={3}>
                    <Element
                      label={
                        <>
                          Description of the Company
                          <span style={{ color: "red" }}>*</span>
                        </>
                      }
                      eletype={inputType.textarea}
                      inputProps={{
                        name: "description",
                        onChange: formik.handleChange,
                        onBlur: formik.handleBlur,
                      }}
                      value={formik.values.description}
                      errorText={
                        formik.touched.description && formik.errors.description
                      }
                      cols="4"
                      rows="6"
                    />
                  </Grid>
                  <Grid item md={12} sx={{ marginTop: "23px" }}>
                    <Element
                      eletype={inputType.switch}
                      label="Are you providing Resource Augmentation services?"
                      inputProps={{
                        name: "resourceAugmentationService",
                        onChange: (e) => {
                          setAugmentationService(e.target.value);
                          formik.handleChange(e);
                        },
                        handleBlur: formik.handleBlur,
                      }}
                      value={formik.values.resourceAugmentationService}
                    />
                  </Grid>
                  {augmentationService === "on" ? (
                    <>
                      <Grid item md={12} p={3}>
                        <Element
                          label="Resource Augmentation Mode"
                          // inputProps={{
                          //   name: "augmentationModeSelect",
                          //   onChange: handleChange,
                          //   onBlur: handleBlur,
                          // }}
                          name="augmentationModeSelect"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.augmentationModeSelect}
                          options={augmentationOptions}
                          eletype={inputType.checkboxgroup}
                          icons={
                            <ArrowDropDownIcon
                              sx={{
                                position: "absolute",
                                right: 20,
                                bottom: 24,
                              }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item md={12} p={3}>
                        <span style={{ fontWeight: "bold" }}>Note: </span>
                        If you are providing both onsite and remote then check
                        both the boxes.
                      </Grid>
                    </>
                  ) : null}
                  <Grid item md={12} p={3}>
                    <Button
                      type="submit"
                      onClick={checkForErrors}
                      variant="contained"
                      sx={{ float: "right" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
            <Turnover />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
