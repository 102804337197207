import { Box, Grid, Typography, Divider, Button } from "@mui/material";
import Profile_image from "./profile_banere.png";
import { ConfigProvider, Progress } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchVendorProfileDetailsThunk } from "../../../store/slices/vendor/vendor.slice";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import LoopIcon from "@mui/icons-material/Loop";
import { changeUserLoginCount } from "../../../store/slices/user/user.slice";

const ProfileCompletition = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { TotalProfileProgressCount, profileLastUpdated } = useSelector(
    ({ vendor }) => vendor
  );
  useEffect(() => {
    dispatch(fetchVendorProfileDetailsThunk());
  }, [dispatch]);

  const [lastUpdated, setLastUpdated] = useState("");
  useEffect(() => {
    if (profileLastUpdated) {
      setLastUpdated(new Date(profileLastUpdated).toLocaleString());
    }
  }, [profileLastUpdated]);

  const handleRefresh = () => {
    dispatch(fetchVendorProfileDetailsThunk());
  };

  const boxSx = {
    border: "1px solid lightgray",
    overflow: "hidden",
    // bgcolor: "rgb(74, 75, 84)",
  };

  return (
    // <>
    //   <Box sx={boxSx} borderRadius={2} width={"100%"} p={1} pb={2} zIndex={45}>
    //     <Grid container>
    //       <Grid item md={12} p={1}>
    //         {/* <img src={Profile_image} alt="" height={100}/> */}
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "space-between",
    //             alignItems: "center",
    //             color: "white",
    //             marginBottom: "8px",
    //           }}
    //         >
    //           {/* <Typography variant="h4" p={0} m={0}>
    //             {percentage}%
    //           </Typography> */}
    //           <ConfigProvider
    //             theme={{
    //               components: {
    //                 Progress: {
    //                   /* here is your component tokens */
    //                   circleTextColor: "white",
    //                   defaultColor: "rgb(0, 171, 85)",
    //                 },
    //               },
    //             }}
    //           >
    //             <Progress
    //               type="dashboard"
    //               percent={Number(TotalProfileProgressCount)}
    //               gapDegree={30}
    //               size={"small"}
    //               circleTextColor={"white"}
    //             />
    //           </ConfigProvider>
    //           <Typography variant="h6" p={0} m={0} color={"white"}>
    //             Profile Completion
    //           </Typography>
    //         </div>
    //       </Grid>
    //       <Grid item md={12}>
    //         {/* <Divider style={{color:"gray"}} /> */}
    //         <div
    //           style={{
    //             color: "rgb(158 157 157)",
    //             borderTop: "1px solid gray",
    //             padding: "8px 0",
    //           }}
    //         >
    //           <Typography
    //             variant="body1"
    //             p={0}
    //             m={0}
    //             style={{ cursor: "pointer" }}
    //           >
    //             Profile Update tips
    //           </Typography>
    //           <ul
    //             style={{
    //               color: "#d3d0d0",
    //               paddingLeft: "12px",
    //               fontSize: "14px",
    //             }}
    //           >
    //             <li>Your profile is {TotalProfileProgressCount}% strong.</li>
    //             <li>Stronger profile have higher chances to get leads.</li>
    //           </ul>
    //         </div>
    //       </Grid>
    //       <Grid
    //         item
    //         md={12}
    //         style={{ display: "flex", justifyContent: "center" }}
    //       >
    //         <Button
    //           variant="contained"
    //           style={{ marginTop: "12px" }}
    //           onClick={() => navigate("/dashboard/myProfile")}
    //         >
    //           complete you profile
    //         </Button>
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </>
    <>
      <Box
        sx={boxSx}
        borderRadius={1}
        width={"100%"}
        p={1}
        px={2}
        boxShadow={10}
        data-tut="reactour__profile-progress"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            borderBottom: "1px solid lightgray",
            padding: "0.5rem 0",
          }}
        >
          <PermContactCalendarIcon style={{ color: "rgb(0, 171, 85)" }} />
          <Typography variant="h5">Profile Score</Typography>
        </div>
        {/* <hr /> */}
        <div style={{ padding: "0.5rem 0" }}>
          <Grid container spacing={1}>
            <Grid item md={8}>
              <Typography variant="h3">
                {Math.ceil(Number(TotalProfileProgressCount))}%
              </Typography>
              <Typography variant="body2" color={"gray"}>
                <strong>
                  Your profile is {Math.ceil(Number(TotalProfileProgressCount))}
                  % completed.
                </strong>{" "}
                <br />
                Complete your profile and embark on your business journey with
                us.
              </Typography>
              <Box color={"#999898"} fontWeight={600} lineHeight={1} mt={1.5}>
                <Typography variant="caption">Last Updated:</Typography>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Typography variant="caption">{lastUpdated}</Typography>
                  <LoopIcon
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={handleRefresh}
                  />
                </div>
                {/* <button> */}
                {/* </button> */}
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              direction={"row"}
              justifyContent={"center"}
              mt={1}
            >
              <ConfigProvider
                theme={{
                  components: {
                    Progress: {
                      /* here is your component tokens */
                      circleTextColor: "white",
                      defaultColor: "rgb(0, 171, 85)",
                    },
                  },
                }}
              >
                <Progress
                  type="dashboard"
                  percent={Math.round(Number(TotalProfileProgressCount))}
                  gapDegree={30}
                  size={75}
                  circleTextColor={"white"}
                  strokeWidth={18}
                />
              </ConfigProvider>
            </Grid>
            <Grid
              item
              md={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                style={{ marginTop: "12px" }}
                onClick={() => {
                  dispatch(changeUserLoginCount());
                  navigate("/dashboard/myProfile");
                }}
              >
                Complete Your Profile
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};
export default ProfileCompletition;
