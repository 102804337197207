import React, { useState } from "react";
import UserListToolbar from "./../user/UserListToolbar";
import { Stack, Box, Typography } from "@mui/material";
import ToggleTabs from "./../../common/ToggleTabs";
import { useDispatch, useSelector } from "react-redux";
import { tabChange } from "../../../store/slices/lead/lead.slice";
import FormControl from "@mui/material/FormControl";
import Dropdown from "../../Form/Dropdown";
import { leadsType, projectStatus } from "../../../utils/enum";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GridViewIcon from "@mui/icons-material/GridView";
import "./lead.theme.css";

const LeadsMenu = ({
  type,
  setType,
  handleSearchChange,
  setStatus,
  status,
}) => {
  const [alignment, setAlignment] = useState("pending");
  const { tabs } = useSelector(({ leads }) => leads);
  const handleType = (event, newType) => {
    setType(newType);
  };
  const dispatch = useDispatch();

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    }
  };

  function handleChange(i) {
    dispatch(tabChange(i));
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h4" mb={2}>
            Leads
          </Typography>
          <Breadcrumb />
        </Box>
        <Stack direction="row" spacing={1} alignItems="center">
          {/* <Box className="projectsearchDesktop">
            <UserListToolbar
              placeholder="Search projects"
              handleSearchChange={handleSearchChange}
            />
          </Box> */}
          {/* <Box>
            <FormControl fullWidth>
              <Dropdown
                styles={{
                  position: "relative",
                  height: "40px",
                  lineHeight: "1.4375em",
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: "#212B36,",
                  borderRadius: "8px",
                  backgroundColor: "#f3f6f8",
                  maxWidth: "120px",
                  margin: "1rem 1rem 0 0",
                  cursor: "pointer",
                }}
                options={[
                  { label: "Pending", value: leadsType.pending },
                  { label: "Approved", value: leadsType.approved },
                  { label: "Rejected", value: leadsType.rejected },
                ]}
                icons={true}
                inputProps={{
                  handleChange: (e) => {
                    setStatus(e.target.value);
                  },
                }}
                value={status}
              />
            </FormControl>
          </Box> */}
          {/*<Box>
          <ToggleButtonGroup
            value={type}
            size="small"
            exclusive
            onChange={handleType}
            aria-label="view projects"
          >
            <ToggleButton value="table" aria-label="table">
              <FormatListNumberedIcon />
            </ToggleButton>
            <ToggleButton value="grid" aria-label="grid">
              <GridViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>*/}
        </Stack>
      </Stack>
      <Stack className="projectsearchMobile">
        <Box sx={{ marginTop: "-20px", paddingLeft: "0px!important" }}>
          <UserListToolbar
            placeholder="Search projects"
            handleSearchChange={handleSearchChange}
          />
        </Box>
      </Stack>
    </>
  );
};

export default LeadsMenu;
